import React, { useState, useEffect } from "react";
import Header from "../../ui/Header.js";
import { Formik } from "formik";
import { useHistory, useParams } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import {
  Form,
  FormGroup,
  UncontrolledAlert,
  Input,
  Card,
  CardHeader,
  CardFooter,
  Container,
  Button,
  Col,
  CardBody,
  Row,
  Spinner,
} from "reactstrap";
import { XSquare } from "react-bootstrap-icons";
import { v4 as uuidv4 } from "uuid";
import { ApiUrls } from "../../../common/apiconstant";
import axios from "axios";
import { ApiEndpoints } from "../../constants/constant.js";
import getToken from "../../../onboarding/getToken.js";
import FullPageLoader from "../../../components/FullPageLoader/index.js";
import { toast } from "react-toastify";
import { MultiSelect } from "react-multi-select-component";
import Loader from "../../../onboarding/components/Loader.js";
import { logUserMetrics } from "../../../utils/file";
import styles from "../../styles/employeeUserDetailEdit.module.css";

const profileImgUUid = uuidv4();
const baseAWSAPIURL = require("../../config").baseAWSAPIURL;

let locationOptions = [];

let options = [];

let roleOptions = [];

function ShowProfile({ setImageUpdate }) {
  const history = useHistory();
  const [modifiedNonFormData, setModifiedNonFormData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [locations, setLocations] = useState([]);
  const [timeZones, setTimeZones] = useState([]);
  const [image, setImage] = useState({ preview: "", raw: "" });
  const [roles, setRoles] = useState([]);
  const [avatarKey, setAvatarKey] = useState("2023-04-16T13:48:04.772Z");
  const [userData, setUserData] = useState();
  const { emailId } = useParams();
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [locationD, setlocationD] = useState();
  const [rolesD, setRolesD] = useState([]);
  const [userVerification, setUserVerification] = useState([]);
  const [imageUploading, setImageUploading] = useState(false);
  //console.log("PAR<", params);

  const handleSelectRole = () => {};

  useEffect(() => {
    if (emailId) {
      fetch(`${ApiUrls.getLoginUser}?email=${emailId}`)
        .then((response) => response.json())
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        })
        .then((res) => {
          setIsLoading(true);
          console.log("resdata", res);
          // if (res?.data?.user?.is_verified) {
          //   res.data.user.is_verified = "yes";
          // } else {
          //   res.data.user.is_verified = "no";
          // }
          // let locs = res?.data?.user?.locations;
          // locs.map((loc) => {
          //   locationOptions.push({ value: loc });
          // });

          const verified = res?.data?.user?.is_verified;
          setUserVerification(verified);

          const locations = res?.data?.user?.locations;
          console.log();
          // locations.map((element) => {
          //   options.push(element);
          // });

          let response = res;

          if (response?.data?.user?.avatar === "") {
            response.data.user.avatar =
              "https://user-avatar-upload.s3.amazonaws.com/file/" + avatarKey;
          }
          setlocationD(locations);
          setRolesD(response?.data?.user?.roles);
          setUserData(response?.data?.user);
          setIsLoading(false);
          // setIsLoading(false);
        });
    }
  }, [emailId]);

  console.log("USER", userData);
  console.log("USER D", locationD);

  // useEffect(() => {
  //   if (userData) {
  //     if (userData?.is_verified) {
  //       userData.is_verified = "yes";
  //     } else {
  //       userData["is_verified"] = "no";
  //     }
  //   }
  // }, [userData]);

  const handleChangeImage = async (e, fieldName) => {
    setButtonDisabled(true);
    let toastId = toast.info("Avatar uploading is in processing", {
      autoClose: false,
    });

    var newData = { ...modifiedNonFormData };
    setButtonDisabled(true);
    if (e.target.files.length) {
      let selectedFile = e.target.files[0];
      let fileType = e.target.files[0].type;
      setImage({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0],
      });

      let preSingnedUrl = ApiUrls.avatarUploadPresigned;

      try {
        let preSignedRes = await axios.get(
          `${preSingnedUrl}?contentType=${fileType}`
        );
        console.log("D", preSignedRes);
        if (preSignedRes.status === 200) {
          let url = preSignedRes?.data?.data?.url;
          let ukey = preSignedRes?.data?.data?.key;
          let res = await uploadFile(url, selectedFile);
          console.log("RES1", res);
          console.log("ULR", url, ukey);
          if (res.status === 200) {
            setButtonDisabled(false);
            setAvatarKey(ukey);
            newData[fieldName] = ukey;
            setModifiedNonFormData(newData);
            console.log("MODI", modifiedNonFormData);
            console.log("DATa", newData);
            let d = JSON.parse(JSON.stringify(userData));

            let arr = d?.avatar.split("/");
            arr[arr.length - 1] = ukey;
            let newUrl = arr.join("/");
            d.avatar = newUrl;
            setUserData(d);
            setImage({ preview: "", raw: "" });
            setImageUploading(false);
            setButtonDisabled(false);
            toast.dismiss(toastId);
            toast.success("Image uploaded successfully");
          }
        }
      } catch (error) {
        setButtonDisabled(false);
        console.error(error);
      }
    } else {
      setButtonDisabled(false);
      newData[fieldName] = false;
      setModifiedNonFormData(newData);
    }
  };

  const uploadFile = (url, data) => {
    console.log("UPLOAD");
    let promise = new Promise((resolve, reject) => {
      "in xhr";
      const xhr = new XMLHttpRequest();
      xhr.open("PUT", url);
      //xhr.responseType = "json";
      //xhr.setRequestHeader("X-Requested-With", "XMLHttpRequest");
      //xhr.setRequestHeader("Access-Control-Allow-Origin", "*");
      xhr.onload = () => {
        if (xhr.status >= 400) {
          console.log("REJECTED");
          reject({ status: 400, data: xhr.response });
        } else {
          console.log("RESOLVED");
          resolve({ status: 200, data: xhr.response });
        }
      };

      xhr.onerror = (e) => {
        console.log("RJECTED");
        reject({
          status: 400,
          data: xhr.response,
          msg: "Something went wrong",
        });
      };

      xhr.send(data);
    });

    return promise;
  };

  useEffect(() => {
    fetch(`${ApiUrls.allLocationList}`, {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
    })
      .then((response) => response.json())
      .then((response) => {
        console.log("res", response);
        setLocations(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error:", error);
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    fetch(`${ApiUrls.timeZones}`, {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response.data);
        setTimeZones(response.data);
        // setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error:", error);
        setIsLoading(false);
      });
  }, []);

  const handleView = () => {
    const viewPath = "/admin/users";
    history.push(viewPath);
  };

  useEffect(() => {
    if (locationD?.length > 0) {
      locationOptions = [];
      options = [];
      if (typeof locations !== "undefined") {
        console.log("152963", locations);
        let a = locations?.map((element) => {
          options.push({
            label: element.title,
            value: element.location_id,
            disabled: true,
          });
          const itemSingle = locationD?.find((el) => {
            if (el == element.location_id) {
              locationOptions.push({
                label: element.title,
                value: element.location_id,
              });
            }
          });
        });
        setSelectedLocations(locationOptions);
      }
    }
  }, [locations, locationD]);

  let displayedLocations = selectedLocations.map((loc) => loc.label).join(", ");

  const editProfile = async (data, setSubmitting) => {
    console.log("DATASUBMIT", data);
    setIsLoading(true);

    data.type = "LS-Internal";
    //data.signUpMode = "Email";

    if (data["is_verified"] == "yes") {
      data.is_verified = true;
    } else if (data["is_verified"] == "no") {
      data.is_verified = false;
    } else {
      data.is_verified = true;
    }
    if (!data.avatar) {
      data.avatar = avatarKey;
    } else {
      let arr = userData.avatar.split("/");
      let uKey = arr[arr.length - 1];
      data.avatar = uKey;
    }

    if (selectedLocations) {
      data.locations = selectedLocations.map((loc) => loc.value);
    }

    if (selectedRoles) {
      data.roles = selectedRoles.map((role) => role.value);
    }

    //data.default_location = "wh#aa2d8ad1-4ba2-427b-b78f-7da626a379df";

    console.log("BODY", modifiedNonFormData, "asdf", data);

    let userDetail = JSON.parse(localStorage.getItem("user_detail"));

    try {
      let res = await axios.put(`${ApiUrls.editUser}`, {
        ...data,
        ...modifiedNonFormData,
      });
      console.log("RESPO", res);

      if (res.status === 200) {
        console.log("SDFSDF");
        toast.success("Profile Edited Successfully");
        setErrorMsg("");
        setImage({ preview: "", raw: "" });
        setSubmitting(false);
        const updatedUserDetail = {
          ...userDetail,
          avatar:
            "https://user-avatar-upload.s3.amazonaws.com/file/" + avatarKey,
        };

        localStorage.setItem("user_detail", JSON.stringify(updatedUserDetail));
        setImageUpdate(updatedUserDetail);
        setIsLoading(false);
        let action = "profile-update";
        let type = "Info";
        let message = "Profile successfully updated.";
        const apiResponse = await logUserMetrics(action, type, message);
        console.log("apiirresponse", apiResponse);
        // setSuccessMsg("Profile Created successfully");
      } else {
        console.log("ELSE", res);
        //setErrMsg(res.data.error.message);
        setSubmitting(false);
        setIsLoading(false);
        //res?.response?.data?.message?.map((item) => toast.error(item.error));
      }
    } catch (error) {
      console.error("CATCH", error.response);
      toast.error(error?.response?.data?.message[0].error);
      setSubmitting(false);
      setIsLoading(false);
      let action = "profile-update";
      let type = "Error";
      let message = "Error in Profile update.";
      const apiResponse = await logUserMetrics(action, type, message);
      console.log("apiirresponse", apiResponse);
    }
  };

  const handleImageUpload = (event, fieldName) => {
    let selectedfiles = "";
    var newData = { ...modifiedNonFormData };
    const imageFile = event.target.files;
    setButtonDisabled(true);
    if (imageFile.length > 0) {
      selectedfiles = imageFile[0];

      const imagesKays = '[{ "key": "' + fieldName + profileImgUUid + '"}]';

      fetch(`${ApiUrls.imageUpload}`, {
        method: "POST",
        body: imagesKays,
      })
        .then((response) => response.json())
        .then((response) => {
          //console.log(response);
          const presignedUrl = response[0].presignedUrl;
          console.log(presignedUrl);

          fetch(presignedUrl, {
            method: "PUT", // *GET, POST, PUT, DELETE, etc.
            body: selectedfiles,
          })
            .then((res) => {
              setButtonDisabled(false);
              newData[fieldName] = fieldName + profileImgUUid;
              setModifiedNonFormData(newData);
            })
            .catch((error) => {
              console.log(error);
            });
        })
        .catch((error) => {
          setButtonDisabled(false);
          console.error(error);
        });
    } else {
      setButtonDisabled(false);
      newData[fieldName] = false;
      setModifiedNonFormData(newData);
    }
  };

  const handleRemoveImage = () => {
    let d = JSON.parse(JSON.stringify(userData));

    let arr = d?.avatar.split("/");
    console.log("arrshjvfhsd", arr);
    if (arr[arr.length - 1] == "2023-04-16T13:48:04.772Z") {
      // toast.info("Please Upload Image")
      return;
    }
    arr[arr.length - 1] = "2023-04-16T13:48:04.772Z";
    let newUrl = arr.join("/");
    d.avatar = newUrl;
    setUserData(d);
    setImage({ preview: "", raw: "" });
  };

  const getShowRole = () => {
    const token = getToken();
    const config = {
      headers: { Authorization: token },
    };

    setIsLoading(true);
    let getPermissionApi = `${ApiEndpoints.roleGetURL}?limit=500&offset=0`;

    fetch(getPermissionApi, config)
      .then((response) => response.json())
      .then((res) => {
        setIsLoading(false);
        const permissionGet = res?.data;
        console.log("ReSpinse 59999999", res);
        console.log("permissionGetpermissionGet,==>", permissionGet);
        roleOptions = [];
        permissionGet.map((item) =>
          roleOptions.push({ label: item.role, value: item.id, disabled: true })
        );

        setRoles(permissionGet);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log("61616166116", err);
      });
  };

  useEffect(() => {
    getShowRole();
  }, []);

  useEffect(() => {
    let selectedRole = [];
    if (roles && rolesD) {
      let a = roles?.map((element) =>
        rolesD?.find((item) => {
          if (element.id === item) {
            selectedRole.push({ label: element.role, value: element.id });
          }
        })
      );
      setSelectedRoles(selectedRole);
    }
  }, [roles, rolesD]);

  let displayedRoles = selectedRoles.map((loc) => loc.label).join(", ");

  console.log("SDF", image);

  function getSelectedValues(event) {
    console.log(event);
    let opts = [],
      opt;
    for (let i = 0, len = event.target.options.length; i < len; i++) {
      opt = event.target.options[i];

      if (opt.selected) {
        opts.push(opt);
      }
    }
    console.log("opts: ", opts);
    return opts;
  }

  console.log("USER AVATAR", userData?.avatar);

  // if (isLoading) {
  //   return (
  //     <div className="sweet-loading card">
  //       <div className="spinner-container">
  //         <ClipLoader
  //           className="spinner"
  //           sizeUnit={"px"}
  //           size={50}
  //           color={"#123abc"}
  //           loading={isLoading}
  //         />
  //       </div>
  //     </div>
  //   );
  // }

  return (
    <>
      {/* {imageUploading ? (
          <div
            style={{
              width: "100vw",
              height: "100vh",
              background: "rgba(0,0,0,0.8)",
              position: "relative",
            }}
          >
            <Spinner
              className="m-5"
              color="primary"
              style={{ position: "absolute", left: "50%", top: "50%" }}
            >
              Loading...
            </Spinner>
          </div>
        ) : null} */}
      <Container fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader>
                <Row className="align-items-center">
                  <Col lg="6" xs="7">
                    <span
                      className="back-btn"
                      style={{ cursor: "pointer" }}
                      onClick={() => history.goBack()}
                    >
                      <i class="fa fa-arrow-left " aria-hidden="true"></i>
                    </span>
                    <h6
                      className="fullcalendar-title h2 d-inline-block mb-0"
                      style={{ marginLeft: "10px" }}
                    >
                      {"Profile"}
                    </h6>
                  </Col>
                  {/* <Col className="mt-3 mt-md-0 text-md-right" lg="6" xs="5">
                      <Button
                        color="primary"
                        onClick={() => handleView()}
                        size="sm"
                      >
                        Back To All Users
                      </Button>
                    </Col> */}
                </Row>
              </CardHeader>
              <CardBody>
                {successMsg != "" && (
                  <UncontrolledAlert color="success">
                    <span className="alert-text ml-1">{successMsg}</span>
                  </UncontrolledAlert>
                )}
                {errMsg != "" && (
                  <UncontrolledAlert color="danger">
                    <span className="alert-text ml-1">{errMsg}</span>
                  </UncontrolledAlert>
                )}
                {errorMsg != "" && (
                  <UncontrolledAlert color="danger">
                    <span className="alert-text ml-1">
                      {errorMsg.map((itemEr) => (
                        <p>{itemEr.error}</p>
                      ))}
                    </span>
                  </UncontrolledAlert>
                )}

                {userData && (
                  <Formik
                    initialValues={userData}
                    validate={(values) => {
                      values = { ...values, ...modifiedNonFormData };
                      const errors = {};
                      console.log("EERR", errors);
                      var pattern = new RegExp(/^[0-9\b]+$/);
                      if (!values.email) {
                        errors.email = "Required";
                      } else if (
                        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                          values.email
                        )
                      ) {
                        errors.email = "Invalid email address";
                      }

                      if (!values.firstname) {
                        errors.firstname = "Required!";
                      }
                      if (!values.lastname) {
                        errors.lastname = "Required!";
                      }

                      // if (!values.phone) {
                      //   errors.phone = "Required";
                      // } else if (!pattern.test(values.phone)) {
                      //   errors.phone = "Please enter only number.";
                      // } else if (values.phone.length != 10) {
                      //   errors.phone = "Please enter valid phone number.";
                      // }

                      // if (!values.default_location) {
                      //   errors.default_location = "Required!";
                      // }
                      if (selectedLocations.length == 0) {
                        errors.locations = "Required!";
                      }
                      if (!values.timezone) {
                        errors.timezone = "Required!";
                      }
                      //   if (!values.avatar) {
                      //     errors.avatar = "Required";
                      //   }
                      if (!values.is_verified) {
                        errors.is_verified = "Required";
                      }
                      if (selectedRoles == 0) {
                        errors.roles = "Required";
                      }
                      return errors;
                    }}
                    onSubmit={(values, { setSubmitting }) => {
                      editProfile(values, setSubmitting);
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      /* and other goodies */
                    }) => (
                      <Form onSubmit={handleSubmit}>
                        <Row className="align-items-center">
                          <Col lg="4" className="text-center">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="upload-button"
                              >
                                {image.preview ? (
                                  <img
                                    src={image.preview}
                                    alt="dummy"
                                    width="150"
                                    height="150"
                                    style={{ objectFit: "cover" }}
                                  />
                                ) : (
                                  <>
                                    {
                                      <img
                                        src={userData?.avatar}
                                        width="150"
                                        height="150"
                                        style={{ objectFit: "cover" }}
                                      />
                                    }
                                    <h5>Upload your photo</h5>
                                  </>
                                )}
                              </label>
                              {userData?.avatar?.includes(
                                "2023-04-16T13:48:04.772Z"
                              ) ? null : (
                                <button
                                  onClick={handleRemoveImage}
                                  style={{
                                    position: "relative",
                                    bottom: "146px",
                                    left: "15px",
                                    background: "transparent",
                                    border: "none",
                                    cursor: "pointer",
                                    fontSize: "22px",
                                  }}
                                >
                                  <XSquare />
                                </button>
                              )}
                              <br />
                              <input
                                lang="en"
                                type="file"
                                name="avatar"
                                onChange={(event) => {
                                  //handleImageUpload(event, "profileImg");
                                  handleChangeImage(event, "avatar");
                                }}
                                //value={image.raw}
                                id="upload-button"
                                style={{ display: "none" }}
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="8" className={styles.container}>
                            <Col lg="12">
                              <FormGroup>
                                <label className="form-control-label">
                                  {" "}
                                  First Name{" "}
                                </label>
                                <Input
                                  placeholder="Enter First Name"
                                  type="text"
                                  name="firstname"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.firstname}
                                />
                                <p className="ErrorMessage">
                                  {errors.firstname &&
                                    touched.firstname &&
                                    errors.firstname}
                                </p>
                              </FormGroup>
                            </Col>

                            <Col lg="12">
                              <FormGroup>
                                <label className="form-control-label">
                                  {" "}
                                  Last Name
                                </label>
                                <Input
                                  placeholder="Enter Last Name"
                                  type="text"
                                  name="lastname"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.lastname}
                                />
                                <p className="ErrorMessage">
                                  {errors.lastname &&
                                    touched.lastname &&
                                    errors.lastname}
                                </p>
                              </FormGroup>
                            </Col>
                          </Col>
                          <Col lg="6">
                            <FormGroup>
                              <label className="form-control-label">
                                {" "}
                                Email{" "}
                              </label>
                              <Input
                                placeholder="Enter Email"
                                type="email"
                                name="email"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.email}
                                readOnly
                              />
                              <p className="ErrorMessage">
                                {errors.email && touched.email && errors.email}
                              </p>
                            </FormGroup>
                          </Col>

                          <Col lg="6">
                            <FormGroup>
                              <label className="form-control-label">
                                Phone Number{" "}
                              </label>
                              <Input
                                type="tel"
                                placeholder="Enter Phone Number"
                                name="phone"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.phone}
                              />
                              {/* <p className="ErrorMessage">
                                {errors.phone && touched.phone && errors.phone}
                              </p> */}
                            </FormGroup>
                          </Col>

                          {/* <Col lg="6">
                            <FormGroup>
                              <label className="form-control-label">
                                Working Location
                              </label>
                              <Input
                                type="select"
                                name="default_location"
                                onChange={handleChange}
                                onBlur={handleBlur}
                              >
                                <option>Select Working Location</option>
                                {locations.map((location) => (
                                  <option
                                    value={location.pk}
                                    selected={
                                      values.default_location == location.pk
                                        ? "selected"
                                        : ""
                                    }
                                  >
                                    {location.title}
                                  </option>
                                ))}
                              </Input>
                              <p className="ErrorMessage">
                                {errors.default_location &&
                                  touched.default_location &&
                                  errors.default_location}
                              </p>
                            </FormGroup>
                          </Col> */}
                          <Col lg="6">
                            <FormGroup>
                              <label className="form-control-label">
                                Select Timezone
                              </label>
                              <Input
                                type="select"
                                name="timezone"
                                onChange={handleChange}
                                onBlur={handleBlur}
                              >
                                <option value="">Select Timezone</option>
                                {timeZones.map((item, index) => (
                                  <option
                                    key={index}
                                    value={item?.abbreviation}
                                    selected={
                                      values.timezone == item?.abbreviation
                                        ? "selected"
                                        : ""
                                    }
                                  >
                                    {item?.name}
                                  </option>
                                ))}
                              </Input>
                              <p className="ErrorMessage">
                                {errors.timezone &&
                                  touched.timezone &&
                                  errors.timezone}
                              </p>
                            </FormGroup>
                          </Col>
                          <Col lg="6">
                            <FormGroup>
                              <label className="form-control-label">
                                Is Verified
                              </label>
                              <Input
                                readOnly
                                type="text"
                                name="is_verified"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={userVerification == true ? "Yes" : "No"}
                              >
                                {/* <option value="">Select Is Verified</option>
                                <option
                                  selected={values.is_verified == true}
                                  value="yes"
                                >
                                  Yes
                                </option>
                                <option
                                  selected={values.is_verified == false}
                                  value="no"
                                >
                                  No
                                </option> */}
                              </Input>
                            </FormGroup>
                          </Col>
                          {/* <Col lg="6"></Col> */}
                          <Col lg="6">
                            <FormGroup>
                              <label className="form-control-label">
                                Location
                              </label>
                              {/* <Input
                                  type="select"
                                  name="locations"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  multiple
                                >
                                  <option>Select Location</option>
                                  {locations.map((location) => (
                                    <option
                                      value={location.pk}
                                      selected={
                                        values.locations.includes(location.pk)
                                          ? "selected"
                                          : ""
                                      }
                                    >
                                      {location.title}
                                    </option>
                                  ))}
                                </Input> */}
                              {/* <MultiSelect
                                disabled={true}
                                hasSelectAll={false}
                                ClearSelectedIcon={null}
                                onChange={setSelectedLocations}
                                value={selectedLocations}
                                options={options}
                                labelledBy="Select"
                              /> */}
                              <Input
                                type="text"
                                value={displayedLocations}
                                readOnly
                              />
                              <p className="ErrorMessage">
                                {errors.locations &&
                                  touched.locations &&
                                  errors.locations}
                              </p>
                            </FormGroup>
                          </Col>
                          <Col lg="6">
                            <FormGroup>
                              <label className="form-control-label">Role</label>
                              {/* <select
                                onChange={handleSelectRole}
                                style={{
                                  width: "100%",
                                  height: 36,
                                  padding: "10px 12px",
                                  display: "block",
                                }}
                                multiple
                              >
                                <option value="" disabled>
                                  Select Role
                                </option>
                                {roles?.map((item) => (
                                  <option
                                    value={item.id}
                                    style={{ textTransform: "capitalize" }}
                                  >
                                    {item.role}
                                  </option>
                                ))}
                              </select> */}
                              {/* <Input
                                  type="select"
                                  name="roles"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  multiple
                                >
                                  <option value="">Select Role</option>
                                  {roles?.map((item) => (
                                    <option
                                      value={item.id}
                                      style={{ textTransform: "capitalize" }}
                                      selected={
                                        values?.roles?.includes(item.id)
                                          ? "selected"
                                          : ""
                                      }
                                    >
                                      {item.role}
                                    </option>
                                  ))}
                                </Input> */}
                              {/* <MultiSelect
                                disabled={true}
                                hasSelectAll={false}
                                ClearSelectedIcon={null}
                                options={roleOptions}
                                value={selectedRoles}
                                onChange={setSelectedRoles}
                              /> */}
                              <Input
                                type="text"
                                value={displayedRoles}
                                readOnly
                              />
                              {/* <option value="Admin">Admin</option>
                                <option value="Location admin">
                                  Location Admin
                                </option>
                                <option value="Curator">Curator</option>
                                <option value="Dispatcher">Dispatcher</option> */}
                              <p className="ErrorMessage">
                                {errors.role && touched.role && errors.role}
                              </p>
                            </FormGroup>
                          </Col>
                          {/* <Col lg="6">
                            <FormGroup>
                              <label className="form-control-label">
                                Profile Image
                              </label>
                              <br />
                              <input
                                lang="en"
                                type="file"
                                name="profileImg"
                                onChange={(event) => {
                                  handleImageUpload(event, "profileImg");
                                }}
                              />
                            </FormGroup>
                          </Col> */}
                          {/* <Col lg="6"></Col> */}
                          <Col>
                            <FormGroup>
                              <Button
                                color="primary"
                                type="submit"
                                disabled={isSubmitting || buttonDisabled}
                                className="d-block"
                              >
                                Update Profile
                              </Button>
                            </FormGroup>
                          </Col>
                        </Row>
                      </Form>
                    )}
                  </Formik>
                )}
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
      <Loader isLoading={isLoading} />
    </>
  );
}

export default ShowProfile;
