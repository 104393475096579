import React, { useEffect, useRef, useState } from "react";
import { Formik, Form, FieldArray, Field } from "formik";

import {
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  UncontrolledAlert,
  Input,
  Container,
  Row,
  Col,
  Button,
  ButtonGroup,
  TabContent,
  TabPane,
  Table,
  Label,
} from "reactstrap";
// core components
import { useDispatch, useSelector } from "react-redux";
import {
  useHistory,
  withRouter,
  useLocation,
  useParams,
} from "react-router-dom";
import { getToken } from "../../../onboarding";
import { ApiEndpoints } from "../../constants/constant";
import FullPageLoader from "../../../components/FullPageLoader";
import moment from "moment";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import DatePicker from "react-datepicker";
import { toast } from "react-toastify";
import { useMediaQuery } from "@material-ui/core";
import axios from "axios";
import { TagsInput } from "react-tag-input-component";
import { logUserMetrics } from "../../../utils/file";
import styles from "../../styles/locationsUpdate.module.css";
import * as Yup from "yup";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));
function Elements(props) {
  const [formData, setFormData] = React.useState({
    zip_codes: [
      {
        code: "",
        priority: "",
      },
    ],
    delivery_slots: [],
  });
  console.log("589652", formData);
  const [isLoading, setIsLoading] = useState(true);
  const [dam, setDam] = React.useState("Manual");
  const [modifiedNonFormData, setModifiedNonFormData] = useState({});
  const history = useHistory();
  const location = useLocation();
  console.log("hjsgsdjk", location);
  // const { activeKey } = location?.state;
  const [locationId, setLocationData] = useState([]);
  const [timezones, setTimezones] = React.useState([]);
  const [tabContentKey, setTabContentKey] = useState("");
  const [parentTabContentKey, setParentTabContentKey] = useState("");
  const [isFormData, setIsFormData] = useState(false);
  const [deliverySlots, setDeliverySlots] = useState([]);
  const [holidayDeliverySlots, setHolidayDeliverySlots] = useState([]);
  const [holidayLists, setHolidayLists] = useState([]);
  const [successMsg, setSuccessMsg] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [setendTime, SetEndTime] = useState();
  const [roleActions, setRoleActions] = useState([]);
  const [userRoles, setUserRoles] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [title, setTitle] = useState("");
  const [showForm, setShowForm] = useState(false);
  const [titleError, setTitleError] = useState(false);
  const { id } = useParams();
  const [options, setOptions] = useState([]);
  const [deliveryOptions, setDeliveryOptions] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedDeliveryOptions, setSelectedDeliveryOptions] = useState([]);
  const [vendorIdOptions, setVendorIdOptions] = useState("");
  const [activeUPC, setActiveUPC] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const isMobile = useMediaQuery("(max-width: 500px)");
  const weeksArray = [
    "sunday",
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
  ];

  const [genericSettingsData, setGenericSettingsData] = useState({});
  const [aliasBody, setAliasBody] = useState({});

  const handleAliasChange = (e, key) => {
    const { value } = e.target;
    console.log("Newvalue", value);
    // Assuming you have a state to store the request body
    setGenericSettingsData((prevBody) => ({
      ...prevBody,
      [key]: value,
    }));
  };

  const handleChangeGenericSettings = (e) => {
    const { name, value, type, checked } = e.target;
    setGenericSettingsData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSalesTaxCheckboxChange = (event, key) => {
    const { checked } = event.target;
    // if (checked) {
    //   setDummyDataOptions([...dummyDataOptions, id]);
    // } else {
    //   setDummyDataOptions(
    //     dummyDataOptions.filter((optionId) => optionId !== id)
    //   );
    // }
    setGenericSettingsData((p) => ({ ...p, [key]: checked }));
  };

  const classes = useStyles();

  useEffect(() => {
    let roles_actions = localStorage.getItem("role_actions");
    let user_detail = localStorage.getItem("user_detail");
    if (roles_actions) {
      roles_actions = JSON.parse(roles_actions);
      setRoleActions(roles_actions);
    }

    user_detail = JSON.parse(user_detail);
    setUserRoles(user_detail?.roles);
  }, []);

  let x = {
    slotInterval: 15,
    openTime: "00:00",
    closeTime: "23:59",
  };
  let startTime = moment(x.openTime, "hh:mm");
  let endTime = moment(x.closeTime, "hh:mm");

  let tempTime = moment(x.openTime, "hh:mm");

  //Times
  let allTimes = [];
  //Loop over the times - only pushes time with 30 minutes interval
  while (startTime < endTime) {
    //Push times
    allTimes.push(startTime.format("hh:mm A"));

    // console.log("ALL TIME", startTime);
    //Add interval of 30 minutes
    startTime.add(x.slotInterval, "minutes");
  }
  allTimes.push(tempTime.format("hh:mm A"));

  const tabToggle = (key) => {
    var getDay = weeksArray.indexOf(key);
    console.log("day2", getDay);
    deliverySlotsFun(getDay);
    setTabContentKey(key);
  };

  useEffect(() => {
    if (isFormData) {
      tabToggle("sunday");
    }
  }, [isFormData]);

  const parentTabToggle = (key) => {
    setParentTabContentKey(key);
  };

  const hasLocationAccess =
    userRoles.includes("1") ||
    roleActions.includes("location_edit") ||
    roleActions.includes("location_update");
  const hasDeliverySlotsAccess =
    userRoles.includes("1") ||
    roleActions.includes("location_edit") ||
    roleActions.includes("location_update") ||
    roleActions.includes("location_update_delivery_slots");

  useEffect(() => {
    if (location?.state?.activeKey) {
      setParentTabContentKey("active_upc");
      return;
    }

    if (hasLocationAccess) {
      setParentTabContentKey("location");
    } else if (hasDeliverySlotsAccess) {
      setParentTabContentKey("delivery_slots");
    }
  }, [hasLocationAccess, hasDeliverySlotsAccess, location?.state?.activeKey]);

  const thirdPartyDeliveryOptions = () => {
    fetch(`${ApiEndpoints.getVendors}`)
      .then((response) => response.json())
      .then((data) => {
        setIsLoading(true);
        const thirdPartyDelivery = data?.data;
        console.log("thirdPartyDelivery", thirdPartyDelivery);
        const vendorIdOptions = thirdPartyDelivery.map((option) => ({
          vendor_id: option.id,
          vendor_name: option.name,
        }));
        setDeliveryOptions(thirdPartyDelivery);
        setSelectedDeliveryOptions([]);
        setVendorIdOptions(vendorIdOptions);
        setIsLoading(false);
        // let selectedDeliveryOption = [];
        // for (let i in thirdPartyDelivery) {
        //   selectedDeliveryOption.push(thirdPartyDelivery[i].id);
        // }
        // setSelectedDeliveryOptions(selectedDeliveryOption);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    thirdPartyDeliveryOptions();
  }, []);

  let locsId = formData?.location_id?.split("#")[1];
  console.log("locsId", locsId, formData);

  const thirdPartyVendor = () => {
    fetch(`${ApiEndpoints.getVendorsByLocationId}/${locsId}`)
      .then((response) => response.json())
      .then((data) => {
        const thirdPartyDelivery = data?.data;
        console.log("bjasbdsjabdcbsjdbcdkdsb", thirdPartyDelivery);
        const vendorIdOptions = thirdPartyDelivery.map(
          (option) => option.vendor_id
        );
        setSelectedDeliveryOptions(vendorIdOptions);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (formData?.location_id) {
      thirdPartyVendor();
    }
  }, [formData]);

  const handleActiveUPC = async () => {
    let activeUPC = `${ApiEndpoints.activeUPC}?location_id=${locsId}`;

    try {
      // setIsLoading(true);
      let res = await axios.get(activeUPC);
      console.log("RESPONSEUPCDATA", res);
      if (res.status === 200) {
        const data = res?.data?.data;
        console.log("activeUPCDATA", data);
        // const totalUPCValue = parseInt(data?.total_upc) || 0;
        // const activeUPCValue = parseInt(data?.active_upc) || 0;
        // const inactiveUPCValue = totalUPCValue - activeUPCValue;

        // const chartUPCData = [
        //   ["Status", "Count"],
        //   ["Active UPC", activeUPCValue],
        //   ["Inactive UPC", inactiveUPCValue],
        // ];

        // console.log("chartUPCData", chartUPCData);

        setActiveUPC(data);
        // setIsLoading(false);
      } else {
        // setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  console.log("upcactive", activeUPC);

  const handleCategory = async () => {
    let categoryData = `${ApiEndpoints.activeUPCCategory}?location_id=${locsId}`;

    try {
      // setIsLoading(true);
      let res = await axios.get(categoryData);
      console.log("RESPONSE", res);
      if (res.status === 200) {
        const data = res?.data?.data;
        console.log("activeCategoryDATA", data);
        setCategoryData(data);
        // setIsLoading(false);
      } else {
        // setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (formData?.location_id) {
      handleCategory();
      handleActiveUPC();
    }
  }, [formData]);

  const handleSelectAll = () => {
    setSelectedDeliveryOptions((prevSelected) => {
      const allOptionsChecked = prevSelected.length !== deliveryOptions.length;
      return allOptionsChecked
        ? deliveryOptions.map((option) => option.id)
        : [];
    });
  };

  useEffect(() => {
    const allOptionsChecked =
      selectedDeliveryOptions.length === deliveryOptions.length;
    setSelectAll(allOptionsChecked);
  }, [selectedDeliveryOptions, deliveryOptions]);

  const handleCheckboxChange = (event, optionId) => {
    const { checked } = event.target;

    if (checked) {
      setSelectedDeliveryOptions((prevSelected) => [...prevSelected, optionId]);
      console.log(
        "selectedDeliveryOptions",
        optionId,
        selectedDeliveryOptions.length
      );
    } else {
      setSelectedDeliveryOptions((prevSelected) =>
        prevSelected.filter((id) => id !== optionId)
      );
      //   if(selectedDeliveryOptions.includes(optionId)){
      //   setSelectedDeliveryOptions(selectedDeliveryOptions.filter(id => id !== optionId));
      // }
    }
    const allOptionsChecked =
      selectedDeliveryOptions.length == deliveryOptions.length;
    setSelectAll(allOptionsChecked);
  };

  console.log("selectedDeliveryOptions", selectedDeliveryOptions);

  const locId = formData?.location_id?.split("#")[1];

  const selectedDeliveryPartner = async () => {
    // if (selectedDeliveryOptions.length === 0) {
    //   toast.error("Please select at least one option.");
    //   setIsLoading(false);
    //   return;
    // }
    const selectedVendorNames = selectedDeliveryOptions.map((vendorId) => {
      const selectedVendor = vendorIdOptions.find(
        (option) => option.vendor_id === vendorId
      );
      return selectedVendor.vendor_name;
    });

    const requestBody = {
      vendor_id: selectedDeliveryOptions,
      vendor_name: selectedVendorNames,
      updated_by: localStorage.getItem("fullname"),
    };
    if (selectedDeliveryOptions.length === 0) {
      toast.success("App partner updated successfully");
    } else {
      toast.success("App partner added successfully");
    }

    console.log("requestBody", requestBody);
    try {
      setIsLoading(true);
      let res = await axios.post(
        `${ApiEndpoints.createVendorsByLocationId}/${locId}`,
        requestBody
      );
      console.log("REsponse", res);
      if (res.status == 200) {
        history.push("/admin/locations");
        setIsLoading(false);
        let action = "third_party_app_partners";
        let type = "Info";
        let message = "App Partner successfully added.";
        const apiResponse = await logUserMetrics(action, type, message);
        console.log("apiirresponse", apiResponse);
      } else {
        console.log("resres", res);
      }
    } catch (error) {
      console.error("error", error.response);
      toast.error("Error in adding App Partner");
      setIsLoading(false);
      let action = "third_party_app_partners";
      let type = "Error";
      let message = "Error in adding app partner.";
      const apiResponse = await logUserMetrics(action, type, message);
      console.log("apiirresponse", apiResponse);
    }
  };

  const updateLocation = async (data, setSubmitting) => {
    let reqBody = {
      id: id,
      customer_id: data.customer_id,
      location_id: data.location_id,
      address: {
        add1: data?.address?.add1.trim(),
        add2: data?.address?.add2.trim(),
        building: data?.address?.building.trim(),
        city: data?.address?.city.trim(),
        state: data?.address?.state.trim(),
        zip: data?.address?.zip.toString(),
        email: data?.address?.email,
        customer_contact: data?.address?.customer_contact,
        phone: data?.address?.phone,
      },
      code: data.code.trim(),
      delivery_enabled: true,
      description: data.description.trim(),
      fun_friend: data.fun_friend,
      // is_active: data.is_active,
      life_of_the_party: data.life_of_the_party,
      location: {
        lat: data?.location?.lat,
        lng: data?.location?.lng,
      },

      min_order_value: data.min_order_value.trim(),
      delivery_charge: data.delivery_charge.trim(),
      //  onfleet_api_key: "111698b1935026fc8d",
      operation_email: data.operation_email.trim(),
      operation_hours: {
        st: data?.operation_hours?.st,
        et: data?.operation_hours?.et,
      },
      // pickup_enabled: true,
      pick_up_time: data.pick_up_time.trim(),
      // savvy_socialite: data.savvy_socialite,
      slack_channel: data.slack_channel.trim(),

      // shopify_location_id: "65581973702",

      dba: data.dba.trim(),
      support_email: data.support_email,
      support_phone: data.support_phone,

      timezone: data.timezone,

      title: data.title.trim(),
      // voucherify_app_id: "0e66a34-9a38-4e91-bbd6-0d4d4e00c1c9",
      // voucherify_app_token: "e2745d31-d932-4e85-8591-282a1f28c8a7",
      zip_code: options,
      updated_by: localStorage.getItem("fullname"),
    };
    if (data.is_active == true || data.is_active === "yes") {
      reqBody.is_active = true;
    } else {
      reqBody.is_active = false;
    }

    if (data.pickup_enabled == true || data.pickup_enabled === "yes") {
      reqBody.pickup_enabled = true;
    } else {
      reqBody.pickup_enabled = false;
    }

    console.log("REQ BODY", reqBody);
    const response = await fetch(ApiEndpoints.locationUpdate, {
      method: "PUT", // *GET, POST, PUT, DELETE, etc.
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ ...reqBody }), // body data type must match "Content-Type" header
    });
    console.log("12345", response);
    try {
      if (response.status === 200) {
        setSubmitting(false);
        setIsLoading(false);
        toast.success("Location updated successfully");
        let action = "location-update";
        let type = "Info";
        let message = "Location successfully updated.";
        const apiResponse = await logUserMetrics(action, type, message);
        console.log("apiirresponse", apiResponse);
        history.push("/admin/locations");
      } else {
        setSubmitting(true);
        toast.error("Something went wrong");
      }
    } catch (error) {
      // console.error("12345",error);
      setSubmitting(true);
      setIsLoading(false);
      toast.error("Something went wrong");
      let action = "location-update";
      let type = "Error";
      let message = "Error in location update.";
      const apiResponse = await logUserMetrics(action, type, message);
      console.log("apiirresponse", apiResponse);
    }
    // if (response.status === 200) {
    //   toast.success("Location updated successfully");
    //   history.push("/admin/locations");
    // }

    // dispatch(loginUser(data, history, dispatch));
  };

  const addZipCodes = async (values, setValues) => {
    let tempData = [...values.zip_codes];
    tempData.push({ code: "", priority: "" });
    let tempZipCode = formData.zip_codes;
    tempZipCode.push({ code: "", priority: "" });
    setFormData({ ...formData, zip_codes: tempZipCode });
    await setValues({ ...values, zip_codes: [...tempData] });
  };

  const removeZipCodes = async (index) => {
    let tempZipCode = formData.zip_codes;
    if (index > -1) {
      tempZipCode.splice(index, 1);
    }
    console.log(tempZipCode);
    setFormData({ ...formData, zip_codes: tempZipCode });
  };

  const deliverySlotsFun = (getDay) => {
    // Delivery Slots
    // debugger;
    console.log("getDay", getDay);
    setIsLoading(true);
    const token = getToken();
    const config = {
      headers: { Authorization: token },
    };
    console.log("formData", formData);
    fetch(
      `${ApiEndpoints.deliverySlotsUrl}${formData?.location_id?.split("#")[1]}`,
      config
    )
      .then((response) => response.json())
      .then((data) => {
        let slots = data?.data?.Items.filter((day) => day.day == getDay);
        setDeliverySlots(slots);
        setTimeout(() => {
          setIsLoading(false);
        }, 2000);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  console.log("location", location?.state, "ter", locationId);
  const getActiveDayTab = () => {
    return weeksArray.indexOf(tabContentKey);
  };
  const UpdateDeliverySlot = (val, Alldata) => {
    console.log(Alldata);
    setIsLoading(true);
    let apiUrl = `${ApiEndpoints.addDeliverySlots}`;
    fetch(apiUrl, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: Alldata }),
    })
      .then((response) => response.json())
      .then((res) => {
        setIsLoading(false);
        var activeDayTab = getActiveDayTab();
        deliverySlotsFun(activeDayTab);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };
  const addDeliverySlots = (deliverySlotsValues) => {
    console.log("slots", deliverySlotsValues);
    let tempData = [];
    var activeDayTab = getActiveDayTab();
    tempData.push({
      startTime: "",
      sk: "",
      day: activeDayTab,
      endTime: "",
      pk: formData?.location_id,
      locationId: formData?.location_id?.split("#")[1],
    });
    setDeliverySlots([...deliverySlots, ...tempData]);
    console.log(allTimes);
    scrollToBottom();
  };

  const removeDeliverySlots = (index, val) => {
    // debugger;
    console.log(index, val);
    let tempSlotData = deliverySlots;
    if (index > -1) {
      tempSlotData.splice(index, 1);
    }
    console.log("deliverySlots", deliverySlots);
    console.log("tempSlotData", tempSlotData);
    deleteDeliverySlot(val?.pk.split("#")[1], val?.sk);
    // setDeliverySlots([...deliverySlots, ...tempSlotData]);
  };
  const somsection = useRef(null);
  const scrollToBottom = () => {
    window.scrollTo({ top: somsection.current.offsetTop, behavior: "smooth" });
  };
  useEffect(() => {
    const token = getToken();
    const config = {
      headers: { Authorization: token },
    };
    var getDay = weeksArray.indexOf(tabContentKey);
    console.log("days", getDay);
    // debugger;
    deliverySlotsFun(getDay);

    setLocationData(locsId);
    const extraQueryParams = null;
    setIsLoading(true);
    console.log("12365458", id, location);
    fetch(`${ApiEndpoints.locationGetById}/${id}`, config)
      .then((response) => response.json())
      .then((data) => {
        console.log("DATAL", data);
        const locations = data.data;
        // debugger;
        setFormData({ ...formData, ...locations });
        setIsFormData(true);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });

    // fetch timeZones
    fetch(`${ApiEndpoints.timezoneList}`, { methd: "GET" })
      .then((response) => response.json())
      .then((data) => {
        const timezoneData = data.data;
        console.log("timezoneData", timezoneData);
        setTimezones(timezoneData);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
    // getHolidayList();
  }, []);

  const handleRadioChange = (e) => {
    setDam(e.target.value);
  };

  const deleteDeliverySlot = (locationId, sk) => {
    // debugger;
    if (window.confirm("Are you sure you want to Delete this Slot ?")) {
      const apiUrl = `${ApiEndpoints.deleteDeliverySlots}?locationId=${locationId}&sk=${sk}`;
      setIsLoading(true);
      fetch(apiUrl, {
        method: "DELETE",
      })
        .then((response) => response.json())
        .then((res) => {
          // getNotificationList();
          console.log(res);
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    }
  };
  const handleChangeTime = (e) => {
    console.log(e.target.value);
    SetEndTime(e.target.value);
  };
  const handleData = (i, e) => {
    let newFormValues = [...deliverySlots];
    console.log("SELECT", e.target.value);
    let hour = e.target.value.split(":")[0];

    if (e.target.name == "endTime") {
      if (e.target.value > newFormValues[i]["startTime"]) {
        newFormValues[i][e.target.name] = e.target.value;
      }

      if (hour == "00") {
        console.log("in else");
        newFormValues[i][e.target.name] = e.target.value;
      }
    } else {
      if (newFormValues[i]["endTime"] == "") {
        newFormValues[i][e.target.name] = e.target.value;
      } else if (e.target.value < newFormValues[i]["endTime"]) {
        newFormValues[i][e.target.name] = e.target.value;
      }
    }
    console.log("FORM", newFormValues);
    setDeliverySlots(newFormValues);
  };

  // const deleteHoliday = (val, index) => {
  //   console.log("holidaysss", val, index);
  //   if (window.confirm("Do you really want to delete this Holiday?")) {
  //     setIsLoading(true);
  //     fetch(
  //       `${ApiEndpoints.deleteHolidays}?location=${
  //         location?.state?.location_id.split("wh#")[1]
  //       }&sk=${index.sk}`,
  //       { method: "DELETE" }
  //     )
  //       .then((response) => response.json())
  //       .then((data) => {
  //         // setHolidayDeliverySlots(data?.data?.Items)
  //         console.log("holidays", data);
  //         setIsLoading(false);
  //         getHolidayList();
  //       })
  //       .catch((err) => {
  //         setIsLoading(false);
  //         console.log(err);
  //       });
  //   }
  // };
  // const getHolidayList = () => {
  //   // console.log(date)
  //   setIsLoading(true);
  //   fetch(
  //     `${ApiEndpoints.getHolidays}?location=${
  //       location?.state?.location_id.split("wh#")[1]
  //     }`
  //   )
  //     .then((response) => response.json())
  //     .then((data) => {
  //       setHolidayDeliverySlots(data?.data?.Items);
  //       console.log("all SLots", data);
  //       setIsLoading(false);
  //     })
  //     .catch((err) => {
  //       // setIsLoading(false);
  //       console.log(err);
  //       setIsLoading(false);
  //     });
  // };

  const handleButtonClick = () => {
    if (!title) {
      setTitleError(true);
      return;
    }
    setTitleError(false);
    // updateHolidays();
    setShowForm(false);
    setTitle("");
    setStartDate(new Date());
    setEndDate(new Date());
  };

  // const updateHolidays = () => {
  //   console.log(holidayDeliverySlots);
  //   setIsLoading(true);
  //   let apiUrl = `${ApiEndpoints.addHolidays}?location=${
  //     location?.state?.location_id.split("wh#")[1]
  //   }`;
  //   let body = { startDate: startDate, endDate: endDate, title: title };
  //   console.log("bodybody", body);
  //   fetch(apiUrl, {
  //     method: "POST",
  //     headers: {
  //       Accept: "application/json",
  //       "Content-Type": "application/json",
  //     },
  //     body: JSON.stringify(body),
  //   })
  //     .then((response) => response.json())
  //     .then((res) => {
  //       console.log(res);
  //       getHolidayList();
  //       setIsLoading(false);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       setIsLoading(false);
  //     });
  // };

  const validationSchema = Yup.object({
    code: Yup.string().required("Required"),
    address: Yup.object().shape({
      add1: Yup.string().required("Required"),
      building: Yup.string().required("Required"),
      city: Yup.string().required("Required"),
      state: Yup.string().required("Required"),
      zip: Yup.string().required("Required"),
      email: Yup.string().required("Required").email("Invalid E-mail"),
      customer_contact: Yup.string()
        .required("Required")
        .matches(/^\d+$/, "Invalid phone number")
        .min(10, "Phone number must be at least 10 digits")
        .max(10, "Phone number must not exceed 10 digits"),
      phone: Yup.string()
        .required("Required")
        .matches(/^\d+$/, "Invalid phone number")
        .min(10, "Phone number must be at least 10 digits")
        .max(10, "Phone number must not exceed 10 digits"),
    }),
    // customer_id: Yup.string().required("Required"),
    delivery_enabled: Yup.boolean()
      .required("Required")
      .oneOf([true], "Required"),
    // is_verified: Yup.string().required("Required"),
    delivery_charge: Yup.string().required("Required"),
    // fun_friend: Yup.string().required("Required"),
    is_active: Yup.string().required("Required"),
    description: Yup.string().required("Required"),
    dba: Yup.string().required("Required"),
    // firstname: Yup.string().required("Required"),
    // lastname: Yup.string().required("Required"),
    // admin_email: Yup.string().required("Required").email("Invalid E-mail"),
    // admin_phone: Yup.string()
    //   .required("Required")
    //   .matches(/^\d+$/, "Invalid phone number")
    //   .min(10, "Phone number must be at least 10 digits")
    //   .max(10, "Phone number must not exceed 10 digits"),
    // life_of_the_party: Yup.string().required("Required"),
    location: Yup.object().shape({
      lat: Yup.string().required("Required"),
      lng: Yup.string().required("Required"),
    }),
    min_order_value: Yup.string().required("Required"),
    operation_email: Yup.string().required("Required").email("Invalid E-mail"),
    // tenant: Yup.string()
    //   .required("Required")
    //   .transform((value) => value.value),
    operation_hours: Yup.object().shape({
      st: Yup.string().required("Required"),
      et: Yup.string().required("Required"),
    }),
    pickup_enabled: Yup.string().required("Required"),
    pick_up_time: Yup.string().required("Required"),
    // savvy_socialite: Yup.string().required("Required"),
    support_email: Yup.string().required("Required").email("Invalid E-mail"),
    support_phone: Yup.string()
      .required("Required")
      .matches(/^\d+$/, "Invalid phone number")
      .min(10, "Phone number must be at least 10 digits")
      .max(10, "Phone number must not exceed 10 digits"),
    slack_channel: Yup.string().trim().required("Required"),
    timezone: Yup.string().required("Required"),
    title: Yup.string().required("Required"),
    zip_code: Yup.array().required("Required").min(1, "Required"),
  });

  // const editShopifylocationId = async (data) => {
  //   setIsLoading(true);

  //   let reqBody = {
  //     id: data.id,
  //     shopify_location_id: data.shopify_location_id,
  //   };
  //   console.log("REQ", reqBody);

  //   try {
  //     let res = await axios.put(ApiEndpoints.shopifyLocationId, reqBody);
  //     console.log("response",res);
  //     if (res.status === 200) {
  //       setIsLoading(false);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //     setIsLoading(false);
  //   }
  // };

  const timeConvert = (data) => {
    let date = new Date(data);
    console.log(date, "daaaaaaa");
    const currentDayOfMonth = date.getDate();
    const currentMonth = date.getMonth(); // Be careful! January is 0, not 1
    const currentYear = date.getFullYear();
    const currentMinute = date.getMinutes();
    const currentHour = date.getHours();

    // const dateString = currentDayOfMonth + "-" + (currentMonth + 1) + "-" + currentYear + " "+ currentHour+":"+currentMinute;
    return date;
  };
  const handleDateChange = (date) => {
    setStartDate(date);
    console.log(date);
  };
  const handleEndDate = (date) => {
    console.log(date);
    setEndDate(date);
  };

  // if (isLoading) {
  //   return (
  //     <>
  //       <FullPageLoader />
  //     </>
  //   )
  // }
  //if (!formData) return null;

  const formatKeyToLabel = (key) => {
    // Split the key by underscores and capitalize each word
    const words = key
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1));
    // Join the words with a space
    return words.join(" ");
  };

  // getDeatils of generic settings

  const locsName = formData?.title?.toLowerCase(); // Convert to lowercase
  const encodedLocsName = encodeURIComponent(locsName); // Encode the lowercase string
  console.log("locsname", encodedLocsName, formData);

  const getgenericDetails = () => {
    const config = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": "N8yXBdNkYC17gVxWDEGjU9ZQs7FAHY0Lw58YLVwi",
      },
    };
    let url = `https://hrjk9bspta.execute-api.us-east-1.amazonaws.com/dev/report/getStoreMasterData?locationName=${encodedLocsName}`;
    fetch(url, config)
      .then((response) => response.json())
      .then((res) => {
        console.log("genericdata", res);
        const responseData = res?.body;
        console.log("responsedata123", responseData);
        setGenericSettingsData(responseData);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  useEffect(() => {
    getgenericDetails();
  }, [formData]);

  console.log("genericsettingdata", genericSettingsData);

  // update details of generic settings

  const updateGenericSettings = async (data) => {
    console.log("genericdata", data);
    let reqBody = {
      store_location_name: data?.store_name?.trim(),
      onboarding_date: data?.onboarding_date,
      store_id: data?.store_id,
      store_name: data?.store_name?.trim(),
      owner_first_name: data?.owner_first_name?.trim(),
      owner_last_name: data?.owner_last_name?.trim(),
      owner_email_name: data?.owner_email_name?.trim(),
      stores_owned_by_the_same_owner:
        data?.stores_owned_by_the_same_owner?.trim(),
      manager_first_name: data?.manager_first_name?.trim(),
      manager_last_name: data?.manager_last_name?.trim(),
      manager_email_name: data?.manager_email_name?.trim(),
      phone_number: data?.phone_number,
      operation_communication_email_id: data?.operation_communication_email_id,
      fin_ops_communication_email_id: data?.fin_ops_communication_email_id,
      sales_tax_remit_doordash: data?.sales_tax_remit_doordash,
      sales_tax_remit_ubereats: data?.sales_tax_remit_ubereats,
      sales_tax_remit_grubhub: data?.sales_tax_remit_grubhub,
      sales_tax_remit_shopify: data?.sales_tax_remit_shopify,
      sales_tax_remit_instacart: data?.sales_tax_remit_instacart,
      sales_tax_remit_drizly: data?.sales_tax_remit_drizly,
      doordash_alias: data?.doordash_alias,
      ubereats_alias: data?.ubereats_alias,
      drizly_alias: data?.drizly_alias,
      grubhub_alias: data?.grubhub_alias,
      instacart_alias: data?.instacart_alias,
      shopify_alias: data?.shopify_alias,
      doordash_store_id: data?.doordash_store_id,
    };

    console.log("reqbody11", reqBody);
    setIsLoading(true);
    try {
      const response = await fetch(
        "https://hrjk9bspta.execute-api.us-east-1.amazonaws.com/dev/report/updateStoreMasterData",
        {
          method: "PATCH",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-api-key": "N8yXBdNkYC17gVxWDEGjU9ZQs7FAHY0Lw58YLVwi",
          },
          body: JSON.stringify(reqBody),
        }
      );

      if (response.status === 200) {
        setIsLoading(false);
        toast.success("Generic Settings Successfully updated");
        let action = "generic_ettings-update";
        let type = "Info";
        let message = "Generic Settings successfully updated.";
        const apiResponse = await logUserMetrics(action, type, message);
        console.log("apiirresponse", apiResponse);
        history.push("/admin/locations");
      } else {
        setIsLoading(false);
        let action = "generic_ettings-update";
        let type = "Error";
        let message = "Error in generic settings update.";
        const apiResponse = await logUserMetrics(action, type, message);
        console.log("apiirresponse", apiResponse);
      }
    } catch (error) {
      console.error("Error updating location:", error);
      setIsLoading(false);
      let action = "generic_ettings-update";
      let type = "Error";
      let message = "Error in generic settings update.";
      const apiResponse = await logUserMetrics(action, type, message);
      console.log("apiirresponse", apiResponse);
    }
  };

  const validationSchemaGenericSettings = Yup.object({
    phone_number: Yup.string()
      .matches(/^\d+$/, "Invalid phone number")
      .min(10, "Phone number must be at least 10 digits")
      .max(10, "Phone number must not exceed 10 digits")
      .nullable(),
  });

  return (
    <>
      <Container fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader>
                <Row className="align-items-center">
                  <Col lg="6" xs="8">
                    <span
                      className="back-btn"
                      style={{ cursor: "pointer" }}
                      onClick={() => history.goBack()}>
                      <i class="fa fa-arrow-left " aria-hidden="true"></i>
                    </span>
                    <h6
                      className="fullcalendar-title h2 d-inline-block mb-0"
                      style={{ marginLeft: "5px" }}>
                      {"Update Location"}
                    </h6>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                {successMsg != "" && (
                  <UncontrolledAlert color="success">
                    <span className="alert-text ml-1">{successMsg}</span>
                  </UncontrolledAlert>
                )}
                {errMsg != "" && (
                  <UncontrolledAlert color="danger">
                    <span className="alert-text ml-1">{errMsg}</span>
                  </UncontrolledAlert>
                )}
                {errorMsg != "" && (
                  <UncontrolledAlert color="danger">
                    <span className="alert-text ml-1">
                      {errorMsg.map((itemEr) => (
                        <p>{itemEr.error}</p>
                      ))}
                    </span>
                  </UncontrolledAlert>
                )}

                <ButtonGroup
                  aria-label="Basic examples"
                  role="group"
                  className={styles.buttonGroup}>
                  {(userRoles.includes("1") ||
                    roleActions.includes("location_edit") ||
                    roleActions.includes("location_update")) && (
                    <Button
                      color="secondary"
                      className={`${
                        parentTabContentKey === "location" ? "active" : ""
                      } ${styles.button}`}
                      onClick={() => parentTabToggle("location")}
                      type="button">
                      Location
                    </Button>
                  )}
                  {(userRoles.includes("1") ||
                    roleActions.includes("location_edit") ||
                    roleActions.includes("location_update") ||
                    roleActions.includes("location_update_delivery_slots")) && (
                    <Button
                      color="secondary"
                      className={`${
                        parentTabContentKey === "delivery_slots" ? "active" : ""
                      } ${styles.button}`}
                      onClick={() => parentTabToggle("delivery_slots")}
                      type="button">
                      Delivery Slots
                    </Button>
                  )}
                  {(userRoles.includes("1") ||
                    roleActions.includes("delivery_partner_update")) && (
                    <Button
                      color="secondary"
                      className={`${
                        parentTabContentKey === "third_party_app_partners"
                          ? "active"
                          : ""
                      } ${styles.button}`}
                      onClick={() =>
                        parentTabToggle("third_party_app_partners")
                      }
                      type="button">
                      Third Party App Partners
                    </Button>
                  )}
                  {(userRoles.includes("1") ||
                    roleActions.includes("active_upc")) && (
                    <Button
                      color="secondary"
                      className={`${
                        parentTabContentKey === "active_upc" ? "active" : ""
                      } ${styles.button}`}
                      onClick={() => parentTabToggle("active_upc")}
                      type="button">
                      UPCs
                    </Button>
                  )}
                  {/* {(userRoles.includes("1") ||
                    roleActions.includes("generic_settings")) && (
                    <Button
                      color="secondary"
                      className={`${
                        parentTabContentKey === "generic_settings"
                          ? "active"
                          : ""
                      } ${styles.button}`}
                      onClick={() => parentTabToggle("generic_settings")}
                      type="button">
                      Generic Settings
                    </Button>
                  )} */}
                </ButtonGroup>
                <TabContent activeTab={parentTabContentKey}>
                  <TabPane tabId="location">
                    <Formik
                      initialValues={{ ...formData }}
                      validationSchema={validationSchema}
                      // validate={(values) => {
                      //   console.log(values);
                      //   const errors = {};

                      //   console.log("errors", errors);

                      //   var pattern = new RegExp(/^[0-9\b]+$/);

                      //   if (!values.code) {
                      //     errors.code = "Field Required!";
                      //   }

                      //   if (!values.title) {
                      //     errors.title = "Field Required!";
                      //   }

                      //   if (!values.timezone) {
                      //     errors.timezone = "Field Required!";
                      //   }

                      //   if (!values.description) {
                      //     errors.description = "Field Required!";
                      //   }

                      //   // if (!values.is_active) {
                      //   //   errors.is_active = "Field Required!";
                      //   // }

                      //   if (!values?.address?.add1) {
                      //     errors.address = {
                      //       add1: "Field Required!",
                      //     };
                      //   }

                      //   // if (!values?.address?.add2) {
                      //   //   errors.address = {
                      //   //     add2: "Field Required!",
                      //   //   };
                      //   // }

                      //   if (!values?.address?.building) {
                      //     errors.address = {
                      //       building: "Field Required!",
                      //     };
                      //   }

                      //   if (!values?.address?.city) {
                      //     errors.address = {
                      //       city: "Field Required!",
                      //     };
                      //   }

                      //   if (!values?.address?.state) {
                      //     errors.address = {
                      //       state: "Field Required!",
                      //     };
                      //   }

                      //   if (!values?.address?.zip) {
                      //     errors.address = {
                      //       zip: "Field Required!",
                      //     };
                      //   }

                      //   if (!values?.address?.phone) {
                      //     errors.address = {
                      //       phone: "Field Required",
                      //     };
                      //   } else if (!pattern.test(values?.address?.phone)) {
                      //     errors.address = {
                      //       phone: "Please enter only number.",
                      //     };
                      //   } else if (values?.address?.phone.length != 10) {
                      //     errors.address = {
                      //       phone: "Please enter valid phone number.",
                      //     };
                      //   }

                      //   if (!values?.address?.email) {
                      //     errors.address = {
                      //       email: "Field Required",
                      //     };
                      //   } else if (
                      //     !/^[A-Z0-9._%+'-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                      //       values?.address?.email
                      //     )
                      //   ) {
                      //     errors.address = {
                      //       email: "Invalid email address",
                      //     };
                      //   }

                      //   if (!values?.address?.customer_contact) {
                      //     errors.address = {
                      //       customer_contact: "Field Required",
                      //     };
                      //   } else if (
                      //     !pattern.test(values?.address?.customer_contact)
                      //   ) {
                      //     errors.address = {
                      //       customer_contact: "Please enter only number.",
                      //     };
                      //   } else if (
                      //     values?.address?.customer_contact.length != 10
                      //   ) {
                      //     errors.address = {
                      //       customer_contact:
                      //         "Please enter valid phone number.",
                      //     };
                      //   }

                      //   if (!values.support_email) {
                      //     errors.support_email = "Field Required";
                      //   } else if (
                      //     !/^[A-Z0-9._%+'-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                      //       values.support_email
                      //     )
                      //   ) {
                      //     errors.support_email = "Invalid email address";
                      //   }

                      //   if (!values.support_phone) {
                      //     errors.support_phone = "Field Required";
                      //   } else if (!pattern.test(values.support_phone)) {
                      //     errors.support_phone = "Please enter only number.";
                      //   } else if (values.support_phone.length != 10) {
                      //     errors.support_phone =
                      //       "Please enter valid phone number.";
                      //   }

                      //   // if (!values.slack_channel) {
                      //   //   errors.slack_channel = "Field Required!";
                      //   // }
                      //   if (!values.slack_channel.trim()) {
                      //     errors.slack_channel = "Field Required!";
                      //   }

                      //   if (!values.min_order_value) {
                      //     errors.min_order_value = "Field Required!";
                      //   }

                      //   if (!values.delivery_charge) {
                      //     errors.delivery_charge = "Field Required!";
                      //   }

                      //   // if (!values.pickup_enabled) {
                      //   //   errors.pickup_enabled = "Field Required!";
                      //   // }

                      //   if (!values.pick_up_time) {
                      //     errors.pick_up_time = "Field Required!";
                      //   }

                      //   if (!values?.location?.lat) {
                      //     errors.location = {
                      //       lat: "Field Required!",
                      //     };
                      //   }

                      //   if (!values?.location?.lng) {
                      //     errors.location = {
                      //       lng: "Field Required!",
                      //     };
                      //   }

                      //   if (!values?.operation_hours?.st) {
                      //     errors.operation_hours = {
                      //       st: "Field Required!",
                      //     };
                      //   }

                      //   if (!values?.operation_hours?.et) {
                      //     errors.operation_hours = {
                      //       et: "Field Required!",
                      //     };
                      //   }

                      //   if (!values.operation_email) {
                      //     errors.operation_email = "Field Required";
                      //   } else if (
                      //     !/^[A-Z0-9._%+'-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                      //       values.operation_email
                      //     )
                      //   ) {
                      //     errors.operation_email = "Invalid email address";
                      //   }

                      //   if (values.zip_code.length < 1) {
                      //     errors.zip_code = "Field Required!";
                      //   }

                      //   // if (!values.delivery_enabled) {
                      //   //   errors.delivery_enabled = "Field Required!";
                      //   // }

                      //   return errors;
                      // }}
                      onSubmit={(values, { setSubmitting, setFieldValue }) => {
                        updateLocation(values, setSubmitting, setFieldValue);
                      }}
                      enableReinitialize>
                      {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        setFieldValue,
                        setValues,
                        /* and other goodies */
                      }) => (
                        <Form>
                          {/* {console.log("values", values, errors)} */}
                          <Row>
                            <Col md="4" sm="6">
                              <label
                                className="form-control-label"
                                htmlFor="example3cols1Input">
                                ID
                              </label>
                              <Input
                                readOnly
                                id="id"
                                placeholder="Enter-ID"
                                type="id"
                                value={`LS-${formData?.id}`}
                                name="id"
                                // onChange={handleChange}
                                // onBlur={handleBlur}
                              />
                            </Col>
                            <Col md="4" sm="6">
                              <label
                                className="form-control-label"
                                htmlFor="example3cols1Input">
                                Store ID
                              </label>
                              <Input
                                readOnly
                                id="store_id"
                                placeholder="Enter Store ID"
                                type="store_id"
                                value={formData?.store_id}
                                name="store_id"
                                // onChange={handleChange}
                                // onBlur={handleBlur}
                              />
                            </Col>
                            <Col md="4" sm="6">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="example3cols2Input">
                                  Code
                                </label>
                                <Input
                                  readOnly
                                  id="code"
                                  placeholder="Enter Code"
                                  type="code"
                                  defaultValue={formData?.code}
                                  name="code"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <p className="ErrorMessage">
                                  {errors.code && touched.code && errors.code}
                                </p>
                              </FormGroup>
                            </Col>
                            <Col md="4" sm="6">
                              {/* <FormGroup> */}
                              <label
                                className="form-control-label"
                                htmlFor="example3cols1Input">
                                Store Name
                              </label>
                              <Input
                                id="title"
                                placeholder="Enter Store Name"
                                type="title"
                                defaultValue={formData?.title}
                                name="title"
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              <p className="ErrorMessage">
                                {errors.title && touched.title && errors.title}
                              </p>
                              {/* </FormGroup> */}
                            </Col>

                            <Col md="4" sm="6">
                              {/* <FormGroup> */}
                              <label
                                className="form-control-label"
                                htmlFor="example3cols1Input">
                                DBA
                              </label>
                              <Input
                                id="dba"
                                placeholder="Enter DBA"
                                type="dba"
                                value={values.dba}
                                name="dba"
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              <p className="ErrorMessage">
                                {errors.dba && touched.dba && errors.dba}
                              </p>
                              {/* </FormGroup> */}
                            </Col>
                            {/* </Row> */}

                            {/* <Row> */}
                            <Col md="4" sm="6">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="example4cols3Input">
                                  Time Zone
                                </label>
                                <Input
                                  id="exampleFormControlSelect1"
                                  type="select"
                                  defaultValue={formData?.timezone}
                                  name="timezone"
                                  onChange={handleChange}
                                  onBlur={handleBlur}>
                                  <p className="ErrorMessage">
                                    {errors.timezone &&
                                      touched.timezone &&
                                      errors.timezone}
                                  </p>
                                  {timezones.map((zones) => (
                                    <option
                                      value={zones.timeZone}
                                      key={zones.timeZone}
                                      selected={
                                        zones.timeZone === formData?.timezone
                                      }>
                                      {zones.name}
                                    </option>
                                  ))}
                                </Input>
                              </FormGroup>
                            </Col>

                            <Col md="4">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="example3cols2Input">
                                  Is Active
                                </label>
                                <Input
                                  placeholder="Is Active"
                                  type="select"
                                  name="is_active"
                                  onChange={handleChange}
                                  onBlur={handleBlur}>
                                  <option value="">Select Is Active</option>
                                  <option
                                    selected={values.is_active == true}
                                    value="yes">
                                    Yes
                                  </option>
                                  <option
                                    selected={values.is_active == false}
                                    value="no">
                                    No
                                  </option>
                                </Input>
                                <p className="ErrorMessage">
                                  {errors.is_active &&
                                    touched.is_active &&
                                    errors.is_active}
                                </p>
                              </FormGroup>
                            </Col>

                            <Col md="4" sm="6">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="example4cols1Input">
                                  Description
                                </label>
                                <Input
                                  id="description"
                                  rows="3"
                                  type="textarea"
                                  placeholder="Enter Description"
                                  defaultValue={formData?.description}
                                  name="description"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <p className="ErrorMessage">
                                  {errors.description &&
                                    touched.description &&
                                    errors.description}
                                </p>
                              </FormGroup>
                            </Col>
                            {/* <Col md="4" sm="6">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="shopify_location_id"
                                >
                                  Shopify Location ID
                                </label>
                                <Input
                                  id="shopify_location_id"
                                  placeholder="Shopify Location ID"
                                  type="text"
                                  value={values.shopify_location_id}
                                  name="shopify_location_id"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                              </FormGroup>
                            </Col> */}
                            {/* <Col md="4" sm="6">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="example5cols3Input"
                                >
                                  Driver Assignment Mode
                                </label>
                                <div className="custom-control custom-radio mb-3">
                                  <input
                                    className="custom-control-input"
                                    id="customRadio5"
                                    defaultChecked={
                                      formData?.dam === false ? true : false
                                    }
                                    name="driver_assignment_mode"
                                    type="radio"
                                    value="Manual"
                                    onChange={(e) => handleRadioChange(e)}
                                  />
                                  <label
                                    className="custom-control-label"
                                    htmlFor="customRadio5"
                                  >
                                    Manual
                                  </label>
                                </div>
                                <div className="custom-control custom-radio mb-3">
                                  <input
                                    className="custom-control-input"
                                    defaultChecked={
                                      formData?.dam === true ? true : false
                                    }
                                    id="customRadio6"
                                    name="driver_assignment_mode"
                                    type="radio"
                                    value="Automatic"
                                    onChange={(e) => handleRadioChange(e)}
                                  />
                                  <label
                                    className="custom-control-label"
                                    htmlFor="customRadio6"
                                  >
                                    Automatic
                                  </label>
                                </div>
                              </FormGroup>
                            </Col> */}
                          </Row>
                          <div>
                            <h3 className="mb-4 mt-4">Address</h3>
                          </div>
                          <Row>
                            <Col md="4">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="example3cols2Input">
                                  Address1
                                </label>
                                <Input
                                  id="example3cols2Input"
                                  placeholder="Enter Address1"
                                  type="address1"
                                  defaultValue={formData?.address?.add1}
                                  name="address.add1"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <p className="ErrorMessage">
                                  {errors?.address?.add1 &&
                                    touched?.address?.add1 &&
                                    errors?.address?.add1}
                                </p>
                              </FormGroup>
                            </Col>
                            <Col md="4">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="example3cols3Input">
                                  Address2
                                </label>
                                <Input
                                  id="example3cols3Input"
                                  placeholder="Enter Address2"
                                  type="address2"
                                  defaultValue={formData?.address?.add2}
                                  name="address.add2"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                {/* <p className="ErrorMessage">
                                  {errors?.address?.add2 &&
                                    touched?.address?.add2 &&
                                    errors?.address?.add2}
                                </p> */}
                              </FormGroup>
                            </Col>
                            <Col md="4">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="example3cols1Input">
                                  Building
                                </label>
                                <Input
                                  id="example3cols1Input"
                                  placeholder="Enter Building"
                                  type="building"
                                  defaultValue={formData?.address?.building}
                                  name="address.building"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <p className="ErrorMessage">
                                  {errors?.address?.building &&
                                    touched?.address?.building &&
                                    errors?.address?.building}
                                </p>
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col md="4">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="example3cols1Input">
                                  City
                                </label>
                                <Input
                                  id="example3cols1Input"
                                  placeholder="Enter City"
                                  type="city"
                                  defaultValue={formData?.address?.city}
                                  name="address.city"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <p className="ErrorMessage">
                                  {errors?.address?.city &&
                                    touched?.address?.city &&
                                    errors?.address?.city}
                                </p>
                              </FormGroup>
                            </Col>

                            <Col md="4">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="example3cols2Input">
                                  State
                                </label>
                                <Input
                                  id="example3cols2Input"
                                  placeholder="Enter State"
                                  type="state"
                                  defaultValue={formData?.address?.state}
                                  name="address.state"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <p className="ErrorMessage">
                                  {errors?.address?.state &&
                                    touched?.address?.state &&
                                    errors?.address?.state}
                                </p>
                              </FormGroup>
                            </Col>
                            <Col md="4">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="example3cols3Input">
                                  Zip
                                </label>
                                <Input
                                  className={styles.lat_input}
                                  id="example3cols3Input"
                                  placeholder="Enter Zip"
                                  type="text"
                                  defaultValue={formData?.address?.zip}
                                  name="address.zip"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  inputMode="numeric"
                                  pattern="[0-9]*"
                                  onKeyDown={(e) => {
                                    if (
                                      e.key === "e" ||
                                      e.key === "." ||
                                      e.key === "+" ||
                                      e.key === "-"
                                    ) {
                                      e.preventDefault();
                                    }
                                  }}
                                  title="Only numeric characters are allowed"
                                />
                                <p className="ErrorMessage">
                                  {errors?.address?.zip &&
                                    touched?.address?.zip &&
                                    errors?.address?.zip}
                                </p>
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col md="4">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="example3cols1Input">
                                  Phone
                                </label>
                                <Input
                                  id="example3cols1Input"
                                  placeholder="Enter Phone"
                                  type="phone"
                                  defaultValue={formData?.address?.phone}
                                  name="address.phone"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <p className="ErrorMessage">
                                  {errors?.address?.phone &&
                                    touched?.address?.phone &&
                                    errors?.address?.phone}
                                </p>
                              </FormGroup>
                            </Col>
                            <Col md="4">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="example3cols2Input">
                                  Email
                                </label>
                                <Input
                                  id="example3cols2Input"
                                  placeholder="Enter Email"
                                  type="text"
                                  defaultValue={formData?.address?.email}
                                  name="address.email"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <p className="ErrorMessage">
                                  {errors?.address?.email &&
                                    touched?.address?.email &&
                                    errors?.address?.email}
                                </p>
                              </FormGroup>
                            </Col>
                            <Col md="4">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="example3cols2Input">
                                  Customer Contact
                                </label>
                                <Input
                                  id="example3cols2Input"
                                  placeholder="Enter Customer Contact"
                                  type="customerContact"
                                  defaultValue={
                                    formData?.address?.customer_contact
                                  }
                                  name="address.customer_contact"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <p className="ErrorMessage">
                                  {errors?.address?.customer_contact &&
                                    touched?.address?.customer_contact &&
                                    errors?.address?.customer_contact}
                                </p>
                              </FormGroup>
                            </Col>
                          </Row>

                          <div>
                            <h3 className="mb-4 mt-4">Support</h3>
                          </div>

                          <Row>
                            <Col md="4">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="example3cols2Input">
                                  Support Email
                                </label>
                                <Input
                                  id="example3cols2Input"
                                  placeholder="Enter Support Email"
                                  type="support_email"
                                  defaultValue={formData?.support_email}
                                  name="support_email"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <p className="ErrorMessage">
                                  {errors.support_email &&
                                    touched.support_email &&
                                    errors.support_email}
                                </p>
                              </FormGroup>
                            </Col>
                            <Col md="4">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="example3cols2Input">
                                  Support Phone
                                </label>
                                <Input
                                  id="example3cols2Input"
                                  placeholder="Enter Support Phone"
                                  type="support_phone"
                                  defaultValue={formData?.support_phone}
                                  name="support_phone"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <p className="ErrorMessage">
                                  {errors.support_phone &&
                                    touched.support_phone &&
                                    errors.support_phone}
                                </p>
                              </FormGroup>
                            </Col>
                            <Col md="4">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="example3cols1Input">
                                  Slack Channel
                                </label>
                                <Input
                                  id="example3cols1Input"
                                  placeholder="Enter Slack Channel"
                                  type="slack_channel"
                                  defaultValue={formData?.slack_channel}
                                  name="slack_channel"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <p className="ErrorMessage">
                                  {errors?.slack_channel &&
                                    touched?.slack_channel &&
                                    errors?.slack_channel}
                                </p>
                              </FormGroup>
                            </Col>
                          </Row>

                          {/* <div>
                        <h3 className="mb-4 mt-4">Loyalty Tiers</h3>
                      </div>

                      <Row>
                      <Col md="4">
                        <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="example3cols2Input"
                            >
                              Fun Freind
                            </label>
                            <Input
                              id="example3cols2Input"
                              placeholder="Fun Freind"
                              type="fun_friend"
                              defaultValue={formData?.address?.fun_friend}
                              name="fun_friend"
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </FormGroup>
                        </Col>
                        <Col md="4">
                        <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="example3cols2Input"
                            >
                              Life of the Party
                            </label>
                            <Input
                              id="example3cols2Input"
                              placeholder="Life of the Party"
                              type="life_of_the_party"
                              defaultValue={formData?.address?.life_of_the_party}
                              name="life_of_the_party"
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </FormGroup>
                        </Col>

                        <Col md="4">
                        <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="example3cols2Input"
                            >
                              Savvy Socialite
                            </label>
                            <Input
                              id="example3cols2Input"
                              placeholder="Savvy Socialite"
                              type="savvy_socialite"
                              defaultValue={formData?.address?.savvy_socialite}
                              name="savvy_socialite"
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </FormGroup>
                        </Col>
                        
                      </Row> */}

                          <div>
                            <h3 className="mb-4 mt-4">Order</h3>
                          </div>

                          <Row>
                            <Col md="4">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="example3cols2Input">
                                  Min Order Value
                                </label>
                                <Input
                                  id="example3cols2Input"
                                  placeholder="Enter Min Order Value"
                                  type="min_order_value"
                                  defaultValue={formData?.min_order_value}
                                  name="min_order_value"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <p className="ErrorMessage">
                                  {errors.min_order_value &&
                                    touched.min_order_value &&
                                    errors.min_order_value}
                                </p>
                              </FormGroup>
                            </Col>

                            <Col md="4">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="example3cols2Input">
                                  Delivery Charges
                                </label>
                                <Input
                                  id="example3cols2Input"
                                  placeholder="Enter Delivery Charges"
                                  type="number"
                                  step="0.01"
                                  defaultValue={formData?.delivery_charge}
                                  name="delivery_charge"
                                  onChange={handleChange}
                                  min={0}
                                  onBlur={(e) => {
                                    const value = e.target.value;
                                    if (!/^\d*$/.test(value)) {
                                    } else {
                                      setFieldValue("delivery_charge", value);
                                    }
                                  }}
                                />
                                <p className="ErrorMessage">
                                  {errors.delivery_charge &&
                                    touched.delivery_charge &&
                                    errors.delivery_charge}
                                </p>
                              </FormGroup>
                            </Col>
                          </Row>

                          <div>
                            <h3 className="mb-4 mt-4">Pick Up</h3>
                          </div>

                          <Row>
                            <Col md="4">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="example3cols2Input">
                                  Pick Up Enabled
                                </label>
                                <Input
                                  // id="example3cols2Input"
                                  placeholder="Pick Up Enabled"
                                  type="select"
                                  defaultValue={formData?.pickup_enabled}
                                  name="pickup_enabled"
                                  onChange={handleChange}
                                  onBlur={handleBlur}>
                                  <option value="">Select an option</option>
                                  <option
                                    selected={values.pickup_enabled == true}
                                    value="yes">
                                    Yes
                                  </option>
                                  <option
                                    selected={values.pickup_enabled == false}
                                    value="no">
                                    No
                                  </option>
                                </Input>
                                <p className="ErrorMessage">
                                  {errors.pickup_enabled &&
                                    touched.pickup_enabled &&
                                    errors.pickup_enabled}
                                </p>
                              </FormGroup>
                            </Col>

                            <Col md="4">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="example3cols2Input">
                                  Pick Up Time
                                </label>
                                <div className="d-flex align-items-center">
                                  <Input
                                    id="example3cols2Input"
                                    placeholder="Enter Pick Up Time"
                                    type="number"
                                    defaultValue={formData?.pick_up_time}
                                    name="pick_up_time"
                                    onChange={handleChange}
                                    min={0}
                                    max={60}
                                    onBlur={(e) => {
                                      const value = e.target.value;
                                      if (!/^\d*$/.test(value)) {
                                      } else {
                                        setFieldValue("pick_up_time", value);
                                      }
                                    }}
                                    onInput={(e) => {
                                      if (e.target.value > 60) {
                                        e.target.value = 60;
                                        alert(
                                          "Value cannot be greater than 60"
                                        );
                                      }

                                      if (e.target.value < 0) {
                                        e.target.value = 0;
                                        alert("Value cannot be less than 0");
                                      }

                                      if (e.target.value) {
                                        let inputVolume =
                                          e.target.value.toString();
                                        let pickUpTime = parseInt(inputVolume);
                                        e.target.value = pickUpTime;
                                      }
                                    }}
                                  />
                                  <Input
                                    type="select"
                                    name="pick_up_time_unit"
                                    onChange={handleChange}
                                    onBlur={handleBlur}>
                                    <option value="mins">mins</option>
                                  </Input>
                                </div>
                                <p className="ErrorMessage">
                                  {errors.pick_up_time &&
                                    touched.pick_up_time &&
                                    errors.pick_up_time}
                                </p>
                              </FormGroup>
                            </Col>
                          </Row>

                          <div>
                            <h3 className="mb-4 mt-4">Location</h3>
                          </div>

                          <Row>
                            <Col md="4">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="example3cols2Input">
                                  Latitude
                                </label>
                                <Input
                                  className={styles.lat_input}
                                  id="example3cols2Input"
                                  placeholder="Enter Latitude"
                                  type="number"
                                  value={values?.location?.lat}
                                  name="location.lat"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <p className="ErrorMessage">
                                  {errors.location?.lat &&
                                    touched.location?.lat &&
                                    errors.location?.lat}
                                </p>
                              </FormGroup>
                            </Col>

                            <Col md="4">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="example3cols2Input">
                                  Longitude
                                </label>
                                <Input
                                  className={styles.lat_input}
                                  id="example3cols2Input"
                                  placeholder="Enter Longitude"
                                  type="number"
                                  value={values?.location?.lng}
                                  name="location.lng"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <p className="ErrorMessage">
                                  {errors.location?.lng &&
                                    touched.location?.lng &&
                                    errors.location?.lng}
                                </p>
                              </FormGroup>
                            </Col>
                          </Row>

                          <div>
                            <h3 className="mb-4 mt-4">Operation Hours</h3>
                          </div>
                          <Row>
                            <Col md="2" sm="6">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="example3cols1Input">
                                  Delivery Start Time
                                </label>
                                <Input
                                  id="time"
                                  type="time"
                                  defaultValue={formData?.operation_hours?.st}
                                  name="operation_hours.st"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <p className="ErrorMessage">
                                  {errors?.operation_hours?.st &&
                                    touched?.operation_hours?.st &&
                                    errors?.operation_hours?.st}
                                </p>
                              </FormGroup>
                            </Col>
                            <Col md="2" sm="6">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="example3cols2Input">
                                  Delivery End Time
                                </label>
                                <Input
                                  id="example-datetime-local-input"
                                  type="time"
                                  defaultValue={formData?.operation_hours?.et}
                                  name="operation_hours.et"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <p className="ErrorMessage">
                                  {errors?.operation_hours?.et &&
                                    touched?.operation_hours?.et &&
                                    errors?.operation_hours?.et}
                                </p>
                              </FormGroup>
                            </Col>
                          </Row>

                          <Row>
                            <Col md="4">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="example3cols2Input">
                                  <h3>Operation Email</h3>
                                </label>
                                <Input
                                  id="example3cols2Input"
                                  placeholder="Enter Operation Email"
                                  type="operation_email"
                                  defaultValue={formData?.operation_email}
                                  name="operation_email"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <p className="ErrorMessage">
                                  {errors.operation_email &&
                                    touched.operation_email &&
                                    errors.operation_email}
                                </p>
                              </FormGroup>
                            </Col>
                          </Row>

                          {/* <div>
                        <h3 className="mb-4 mt-4">Delivery Area</h3>
                      </div>
                      <FieldArray
                        name="zip_codes"
                        render={(arrayHelpers) => (
                          <>
                            {values.zip_codes.map((friend, index) => (
                              <Row>
                                <Col md="2" sm="6">
                                  <FormGroup>
                                    <label
                                      className="form-control-label"
                                      htmlFor="example3cols1Input"
                                    >
                                      Zip Codes
                                    </label>
                                    <Input
                                      id="example3cols1Input"
                                      placeholder="Zip Codes"
                                      type="zip_codes"
                                      // value={values.zip_codes}
                                      name={`zip_codes[${index}].code`}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                    />
                                  </FormGroup>
                                </Col> */}
                          {/* <Col md="2" sm="6">
                                  <FormGroup>
                                    <label
                                      className="form-control-label"
                                      htmlFor="example3cols2Input"
                                    >
                                      Zip Priority
                                    </label>
                                    <Input
                                      id="exampleFormControlSelect1"
                                      type="select"
                                      value={values.priority}
                                      name={`zip_codes.${index}.priority`}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                    >
                                      <option value="1" name="1">
                                        1
                                      </option>
                                      <option value="2" name="2">
                                        2
                                      </option>
                                      <option value="3" name="3">
                                        3
                                      </option>
                                      <option value="4" name="4">
                                        4
                                      </option>
                                      <option value="5" name="5">
                                        5
                                      </option>
                                      <option value="6" name="6">
                                        6
                                      </option>
                                    </Input>
                                  </FormGroup>
                                </Col> */}
                          {/* <button
                                  type="button"
                                  className="PrimaryButton"
                                  style={{
                                    width: "21px",
                                    height: "25px",
                                    marginTop: "40px",
                                  }}
                                  onClick={() => arrayHelpers.remove(index)}
                                >
                                  -
                                </button>
                              </Row>
                            ))}
                            <button
                              type="button"
                              className="PrimaryButton"
                              onClick={() =>
                                arrayHelpers.push({ code: "", priority: "" })
                              }
                            >
                              +
                            </button>
                          </>
                        )}
                      />

                      <div>
                        <h3 className="mb-6 mt-6"></h3>
                      </div> */}

                          <Row>
                            <Col lg="6">
                              <FormGroup>
                                <label className="form-control-label">
                                  <h3>Zip Codes</h3>
                                </label>
                                <TagsInput
                                  value={formData?.zip_code}
                                  onChange={(e) => {
                                    setOptions(e);
                                    setFieldValue("zip_code", e);
                                  }}
                                  name="zip_code"
                                  placeHolder="Enter Zip Codes"
                                />
                                <p className="ErrorMessage">
                                  {errors.zip_code &&
                                    touched.zip_code &&
                                    errors.zip_code}
                                </p>
                              </FormGroup>
                              <p></p>
                            </Col>
                          </Row>

                          <Row>
                            <Col md="2" sm="6">
                              <FormGroup>
                                <div className="custom-control custom-checkbox mb-3">
                                  <input
                                    className="custom-control-input"
                                    id="customCheck1"
                                    type="checkbox"
                                    checked={formData?.delivery_enabled}
                                    defaultValue={formData?.delivery_enabled}
                                    name="delivery_enabled"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                  {/* <p className="ErrorMessage">
                                     {errors.delivery_enabled &&
                                      touched.delivery_enabled &&
                                      errors.delivery_enabled}
                                    </p> */}
                                  <label
                                    className="custom-control-label"
                                    htmlFor="customCheck1">
                                    Enable Delivery
                                  </label>
                                </div>
                              </FormGroup>
                            </Col>
                            {/* <Col md="2" sm="6">
                              <FormGroup>
                                <div className="custom-control custom-checkbox mb-3">
                                  <input
                                    className="custom-control-input"
                                    id="customCheck2"
                                    type="checkbox"
                                    checked={formData?.shipping_enabled}
                                    defaultValue={formData?.shipping_enabled}
                                    name="is_shipping_enabled"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                  <label
                                    className="custom-control-label"
                                    htmlFor="customCheck2"
                                  >
                                    Enable Shipping
                                  </label>
                                </div>
                              </FormGroup>
                            </Col> */}
                          </Row>

                          <Button
                            color="primary"
                            type="submit"
                            disabled={isSubmitting}>
                            Submit Form
                          </Button>
                        </Form>
                      )}
                    </Formik>
                  </TabPane>
                  <TabPane tabId="delivery_slots">
                    <Formik
                      initialValues={{ ...formData }}
                      validate={(values) => {
                        console.log(values);
                        values = { ...values };
                        const errors = {};

                        {
                          /* Insert all form errors here */
                        }
                        // if (values.delivery_slots) {
                        //   values.delivery_slots.map((delivery_slot, index) => {
                        //     if (delivery_slot.from != "" && delivery_slot.until != "") {
                        //       var until = moment(delivery_slot.until, 'hh:mm').format();
                        //       var from = moment(delivery_slot.from, 'hh:mm').format();

                        //       var res = moment(until).isAfter(from);
                        //       if (res) {

                        //         var now = moment(from); //todays date
                        //         var end = moment(until); // another date
                        //         var duration = moment.duration(end.diff(now));
                        //         var minutes = duration.asMinutes();
                        //         console.log(minutes);
                        //         if (minutes < 30) {
                        //           errors.delivery_slots = "Delivery until should be 30 min greater than delivery from.";
                        //         }
                        //       } else {
                        //         errors.delivery_slots = "Delivery until should be greater than delivery from.";
                        //       }
                        //     }
                        //   })
                        // }
                        //return errors;
                      }}
                      onSubmit={(values, { setSubmitting }) => {
                        UpdateDeliverySlot(values, deliverySlots);
                      }}>
                      {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        setValues,
                      }) => (
                        <Form>
                          <Row>
                            <Col sm="12" style={{ overflow: "auto" }}>
                              <ButtonGroup
                                aria-label="Basic example"
                                role="group">
                                <Button
                                  color="secondary"
                                  className={`
                                    ${
                                      tabContentKey === "sunday" ? "active" : ""
                                    } ${styles.button}`}
                                  onClick={() => tabToggle("sunday")}
                                  type="button">
                                  Sunday
                                </Button>
                                <Button
                                  className={`
                                ${tabContentKey === "monday" ? "active" : ""} ${
                                    styles.button
                                  }`}
                                  onClick={() => tabToggle("monday")}
                                  color="secondary"
                                  type="button">
                                  Monday
                                </Button>
                                <Button
                                  color="secondary"
                                  className={`
                                  ${
                                    tabContentKey === "tuesday" ? "active" : ""
                                  } ${styles.button}`}
                                  onClick={() => tabToggle("tuesday")}
                                  type="button">
                                  Tuesday
                                </Button>
                                <Button
                                  color="secondary"
                                  className={`
                                  ${
                                    tabContentKey === "wednesday"
                                      ? "active"
                                      : ""
                                  } ${styles.button}`}
                                  onClick={() => tabToggle("wednesday")}
                                  type="button">
                                  Wednesday
                                </Button>
                                <Button
                                  color="secondary"
                                  className={`
                                  ${
                                    tabContentKey === "thursday" ? "active" : ""
                                  } ${styles.button}`}
                                  onClick={() => tabToggle("thursday")}
                                  type="button">
                                  Thursday
                                </Button>
                                <Button
                                  color="secondary"
                                  className={`
                                  ${
                                    tabContentKey === "friday" ? "active" : ""
                                  } ${styles.button}`}
                                  onClick={() => tabToggle("friday")}
                                  type="button">
                                  Friday
                                </Button>
                                <Button
                                  color="secondary"
                                  className={`
                                  ${
                                    tabContentKey === "saturday" ? "active" : ""
                                  } ${styles.button}`}
                                  onClick={() => tabToggle("saturday")}
                                  type="button">
                                  Saturday
                                </Button>
                                {/* <Button
                                  color="secondary"
                                  className={`
                                  ${
                                    tabContentKey === "holiday" ? "active" : ""
                                  } ${styles.button}`}
                                  onClick={() => tabToggle("holiday")}
                                  type="button"
                                >
                                  Holiday
                                </Button> */}
                              </ButtonGroup>
                            </Col>
                            <Col sm="12">
                              <TabContent activeTab={tabContentKey}>
                                <TabPane tabId="sunday">
                                  <Row className={styles.tabPaneRow}>
                                    <Col md="4" sm="3">
                                      <h5 className="mt-4">Delivery From</h5>
                                    </Col>
                                    <Col md="4" sm="3">
                                      <h5
                                        className="mt-4"
                                        style={{
                                          marginLeft: isMobile ? "15px" : "",
                                        }}>
                                        Delivery Until
                                      </h5>
                                    </Col>
                                    <Col md="4" sm="3">
                                      <Button
                                        type="button"
                                        className="PrimaryButton"
                                        color="primary"
                                        size="sm"
                                        onClick={() =>
                                          addDeliverySlots(deliverySlots)
                                        }>
                                        Add Slot
                                      </Button>
                                    </Col>
                                  </Row>
                                  {deliverySlots?.map((val, index) => (
                                    <Row className={styles.tabPaneRow}>
                                      <Col md="4" sm="4">
                                        <FormGroup>
                                          <Input
                                            id="example3cols11Input"
                                            type="select"
                                            value={val.startTime}
                                            name={"startTime"}
                                            onChange={(e) =>
                                              handleData(index, e)
                                            }>
                                            <option key={"0"} value={""}>
                                              Select Delivery From
                                            </option>
                                            {allTimes.map((allTime, index) => (
                                              <>
                                                {/* {console.log("TIMES", allTime)} */}
                                                <option
                                                  key={index + 1}
                                                  value={moment(allTime, [
                                                    "h:mm A",
                                                  ]).format("HH:mm")}>
                                                  {allTime}
                                                </option>
                                              </>
                                            ))}
                                          </Input>
                                        </FormGroup>
                                      </Col>
                                      <Col md="4" sm="4">
                                        <FormGroup>
                                          <Input
                                            id="example3cols12Input"
                                            type="select"
                                            value={val.endTime}
                                            name={"endTime"}
                                            onChange={(e) =>
                                              handleData(index, e)
                                            }>
                                            <option key={"0"} value={""}>
                                              Select Delivery Until
                                            </option>
                                            {allTimes.map((allTime, index) => (
                                              <option
                                                key={index + 1}
                                                value={moment(allTime, [
                                                  "h:mm A",
                                                ]).format("HH:mm")}>
                                                {allTime}
                                              </option>
                                            ))}
                                          </Input>
                                        </FormGroup>
                                      </Col>
                                      <Col md="4" sm="4">
                                        <Button
                                          type="button"
                                          color="danger"
                                          size="sm"
                                          className="btn-icon"
                                          onClick={() =>
                                            removeDeliverySlots(index, val)
                                          }>
                                          <i class="fas fa-trash-alt"></i>
                                        </Button>
                                      </Col>
                                    </Row>
                                  ))}
                                </TabPane>
                                <TabPane tabId="monday">
                                  <Row className={styles.tabPaneRow}>
                                    <Col md="4" sm="3">
                                      <h5 className="mt-4">Delivery From</h5>
                                    </Col>
                                    <Col md="4" sm="3">
                                      <h5
                                        className="mt-4"
                                        style={{
                                          marginLeft: isMobile ? "15px" : "",
                                        }}>
                                        Delivery Until
                                      </h5>
                                    </Col>
                                    <Col md="4" sm="3">
                                      <Button
                                        type="button"
                                        className="PrimaryButton"
                                        color="primary"
                                        size="sm"
                                        onClick={() =>
                                          addDeliverySlots(deliverySlots)
                                        }>
                                        Add Slot
                                      </Button>
                                    </Col>
                                  </Row>
                                  {deliverySlots?.map((val, index) => (
                                    <Row className={styles.tabPaneRow}>
                                      <Col md="4" sm="4">
                                        <FormGroup>
                                          <Input
                                            id="example3cols11Input"
                                            type="select"
                                            value={val.startTime}
                                            name={"startTime"}
                                            onChange={(e) =>
                                              handleData(index, e)
                                            }>
                                            <option key={"0"} value={""}>
                                              Select Delivery From
                                            </option>
                                            {allTimes.map((allTime, index) => (
                                              <option
                                                key={index + 1}
                                                value={moment(allTime, [
                                                  "h:mm A",
                                                ]).format("HH:mm")}>
                                                {allTime}
                                              </option>
                                            ))}
                                          </Input>
                                        </FormGroup>
                                      </Col>
                                      <Col md="4" sm="4">
                                        <FormGroup>
                                          <Input
                                            id="example3cols12Input"
                                            type="select"
                                            value={val.endTime}
                                            name={"endTime"}
                                            onChange={(e) =>
                                              handleData(index, e)
                                            }>
                                            <option key={"0"} value={""}>
                                              Select Delivery Until
                                            </option>
                                            {allTimes.map((allTime, index) => (
                                              <option
                                                key={index + 1}
                                                value={moment(allTime, [
                                                  "h:mm A",
                                                ]).format("HH:mm")}>
                                                {allTime}
                                              </option>
                                            ))}
                                          </Input>
                                        </FormGroup>
                                      </Col>
                                      <Col md="4" sm="4">
                                        <Button
                                          type="button"
                                          color="danger"
                                          size="sm"
                                          className="btn-icon"
                                          onClick={() =>
                                            removeDeliverySlots(index, val)
                                          }>
                                          <i class="fas fa-trash-alt"></i>
                                        </Button>
                                      </Col>
                                    </Row>
                                  ))}
                                  {/* <Button
                                    color="primary"
                                    onClick={addMondaySlots(deliverySlots,'monday')}
                                  >
                                    Add
                                  </Button> */}
                                </TabPane>
                                <TabPane tabId="tuesday">
                                  <Row className={styles.tabPaneRow}>
                                    <Col md="4" sm="3">
                                      <h5 className="mt-4">Delivery From</h5>
                                    </Col>
                                    <Col md="4" sm="3">
                                      <h5
                                        className="mt-4"
                                        style={{
                                          marginLeft: isMobile ? "15px" : "",
                                        }}>
                                        Delivery Until
                                      </h5>
                                    </Col>
                                    <Col md="4" sm="3">
                                      <Button
                                        type="button"
                                        className="PrimaryButton"
                                        color="primary"
                                        size="sm"
                                        onClick={() =>
                                          addDeliverySlots(deliverySlots)
                                        }>
                                        Add Slot
                                      </Button>
                                    </Col>
                                  </Row>
                                  {deliverySlots?.map((val, index) => (
                                    <Row className={styles.tabPaneRow}>
                                      <Col md="4" sm="4">
                                        <FormGroup>
                                          <Input
                                            id="example3cols11Input"
                                            type="select"
                                            value={val.startTime}
                                            name={"startTime"}
                                            onChange={(e) =>
                                              handleData(index, e)
                                            }>
                                            <option key={"0"} value={""}>
                                              Select Delivery From
                                            </option>
                                            {allTimes.map((allTime, index) => (
                                              <option
                                                key={index + 1}
                                                value={moment(allTime, [
                                                  "h:mm A",
                                                ]).format("HH:mm")}>
                                                {allTime}
                                              </option>
                                            ))}
                                          </Input>
                                        </FormGroup>
                                      </Col>
                                      <Col md="4" sm="4">
                                        <FormGroup>
                                          <Input
                                            id="example3cols12Input"
                                            type="select"
                                            value={val.endTime}
                                            name={"endTime"}
                                            onChange={(e) =>
                                              handleData(index, e)
                                            }>
                                            <option key={"0"} value={""}>
                                              Select Delivery Until
                                            </option>
                                            {allTimes.map((allTime, index) => (
                                              <option
                                                key={index + 1}
                                                value={moment(allTime, [
                                                  "h:mm A",
                                                ]).format("HH:mm")}>
                                                {allTime}
                                              </option>
                                            ))}
                                          </Input>
                                        </FormGroup>
                                      </Col>
                                      <Col md="4" sm="4">
                                        <Button
                                          type="button"
                                          color="danger"
                                          size="sm"
                                          className="btn-icon"
                                          onClick={() =>
                                            removeDeliverySlots(index, val)
                                          }>
                                          <i class="fas fa-trash-alt"></i>
                                        </Button>
                                      </Col>
                                    </Row>
                                  ))}
                                </TabPane>

                                <TabPane tabId="wednesday">
                                  <Row className={styles.tabPaneRow}>
                                    <Col md="4" sm="3">
                                      <h5 className="mt-4">Delivery From</h5>
                                    </Col>
                                    <Col md="4" sm="3">
                                      <h5
                                        className="mt-4"
                                        style={{
                                          marginLeft: isMobile ? "15px" : "",
                                        }}>
                                        Delivery Until
                                      </h5>
                                    </Col>
                                    <Col md="4" sm="3">
                                      <Button
                                        type="button"
                                        className="PrimaryButton"
                                        color="primary"
                                        size="sm"
                                        onClick={() =>
                                          addDeliverySlots(deliverySlots)
                                        }>
                                        Add Slot
                                      </Button>
                                    </Col>
                                  </Row>
                                  {deliverySlots?.map((val, index) => (
                                    <Row className={styles.tabPaneRow}>
                                      <Col md="4" sm="4">
                                        <FormGroup>
                                          <Input
                                            id="example3cols11Input"
                                            type="select"
                                            value={val.startTime}
                                            name={"startTime"}
                                            onChange={(e) =>
                                              handleData(index, e)
                                            }>
                                            <option key={"0"} value={""}>
                                              Select Delivery From
                                            </option>
                                            {allTimes.map((allTime, index) => (
                                              <option
                                                key={index + 1}
                                                value={moment(allTime, [
                                                  "h:mm A",
                                                ]).format("HH:mm")}>
                                                {allTime}
                                              </option>
                                            ))}
                                          </Input>
                                        </FormGroup>
                                      </Col>
                                      <Col md="4" sm="4">
                                        <FormGroup>
                                          <Input
                                            id="example3cols12Input"
                                            type="select"
                                            value={val.endTime}
                                            name={"endTime"}
                                            onChange={(e) =>
                                              handleData(index, e)
                                            }>
                                            <option key={"0"} value={""}>
                                              Select Delivery Until
                                            </option>
                                            {allTimes.map((allTime, index) => (
                                              <option
                                                key={index + 1}
                                                value={moment(allTime, [
                                                  "h:mm A",
                                                ]).format("HH:mm")}>
                                                {allTime}
                                              </option>
                                            ))}
                                          </Input>
                                        </FormGroup>
                                      </Col>
                                      <Col md="4" sm="4">
                                        <Button
                                          type="button"
                                          color="danger"
                                          size="sm"
                                          className="btn-icon"
                                          onClick={() =>
                                            removeDeliverySlots(index, val)
                                          }>
                                          <i class="fas fa-trash-alt"></i>
                                        </Button>
                                      </Col>
                                    </Row>
                                  ))}
                                </TabPane>

                                <TabPane tabId="thursday">
                                  <Row className={styles.tabPaneRow}>
                                    <Col md="4" sm="3">
                                      <h5 className="mt-4">Delivery From</h5>
                                    </Col>
                                    <Col md="4" sm="3">
                                      <h5
                                        className="mt-4"
                                        style={{
                                          marginLeft: isMobile ? "15px" : "",
                                        }}>
                                        Delivery Until
                                      </h5>
                                    </Col>
                                    <Col md="4" sm="3">
                                      <Button
                                        type="button"
                                        className="PrimaryButton"
                                        color="primary"
                                        size="sm"
                                        onClick={() =>
                                          addDeliverySlots(deliverySlots)
                                        }>
                                        Add Slot
                                      </Button>
                                    </Col>
                                  </Row>
                                  {deliverySlots?.map((val, index) => (
                                    <Row className={styles.tabPaneRow}>
                                      <Col md="4" sm="4">
                                        <FormGroup>
                                          <Input
                                            id="example3cols11Input"
                                            type="select"
                                            value={val.startTime}
                                            name={"startTime"}
                                            onChange={(e) =>
                                              handleData(index, e)
                                            }>
                                            <option key={"0"} value={""}>
                                              Select Delivery From
                                            </option>
                                            {allTimes.map((allTime, index) => (
                                              <option
                                                key={index + 1}
                                                value={moment(allTime, [
                                                  "h:mm A",
                                                ]).format("HH:mm")}>
                                                {allTime}
                                              </option>
                                            ))}
                                          </Input>
                                        </FormGroup>
                                      </Col>
                                      <Col md="4" sm="4">
                                        <FormGroup>
                                          <Input
                                            id="example3cols12Input"
                                            type="select"
                                            value={val.endTime}
                                            name={"endTime"}
                                            onChange={(e) =>
                                              handleData(index, e)
                                            }>
                                            <option key={"0"} value={""}>
                                              Select Delivery Until
                                            </option>
                                            {allTimes.map((allTime, index) => (
                                              <option
                                                key={index + 1}
                                                value={moment(allTime, [
                                                  "h:mm A",
                                                ]).format("HH:mm")}>
                                                {allTime}
                                              </option>
                                            ))}
                                          </Input>
                                        </FormGroup>
                                      </Col>
                                      <Col md="4" sm="4">
                                        <Button
                                          type="button"
                                          color="danger"
                                          size="sm"
                                          className="btn-icon"
                                          onClick={() =>
                                            removeDeliverySlots(index, val)
                                          }>
                                          <i class="fas fa-trash-alt"></i>
                                        </Button>
                                      </Col>
                                    </Row>
                                  ))}
                                </TabPane>

                                <TabPane tabId="friday">
                                  <Row className={styles.tabPaneRow}>
                                    <Col md="4" sm="3">
                                      <h5 className="mt-4">Delivery From</h5>
                                    </Col>
                                    <Col md="4" sm="3">
                                      <h5
                                        className="mt-4"
                                        style={{
                                          marginLeft: isMobile ? "15px" : "",
                                        }}>
                                        Delivery Until
                                      </h5>
                                    </Col>
                                    <Col md="4" sm="3">
                                      <Button
                                        type="button"
                                        className="PrimaryButton"
                                        color="primary"
                                        size="sm"
                                        onClick={() =>
                                          addDeliverySlots(deliverySlots)
                                        }>
                                        Add Slot
                                      </Button>
                                    </Col>
                                  </Row>
                                  {deliverySlots?.map((val, index) => (
                                    <Row className={styles.tabPaneRow}>
                                      <Col md="4" sm="4">
                                        <FormGroup>
                                          <Input
                                            id="example3cols11Input"
                                            type="select"
                                            value={val.startTime}
                                            name={"startTime"}
                                            onChange={(e) =>
                                              handleData(index, e)
                                            }>
                                            <option key={"0"} value={""}>
                                              Select Delivery From
                                            </option>
                                            {allTimes.map((allTime, index) => (
                                              <option
                                                key={index + 1}
                                                value={moment(allTime, [
                                                  "h:mm A",
                                                ]).format("HH:mm")}>
                                                {allTime}
                                              </option>
                                            ))}
                                          </Input>
                                        </FormGroup>
                                      </Col>
                                      <Col md="4" sm="4">
                                        <FormGroup>
                                          <Input
                                            id="example3cols12Input"
                                            type="select"
                                            value={val.endTime}
                                            name={"endTime"}
                                            onChange={(e) =>
                                              handleData(index, e)
                                            }>
                                            <option key={"0"} value={""}>
                                              Select Delivery Until
                                            </option>
                                            {allTimes.map((allTime, index) => (
                                              <option
                                                key={index + 1}
                                                value={moment(allTime, [
                                                  "h:mm A",
                                                ]).format("HH:mm")}>
                                                {allTime}
                                              </option>
                                            ))}
                                          </Input>
                                        </FormGroup>
                                      </Col>
                                      <Col md="4" sm="4">
                                        <Button
                                          type="button"
                                          color="danger"
                                          size="sm"
                                          className="btn-icon"
                                          onClick={() =>
                                            removeDeliverySlots(index, val)
                                          }>
                                          <i class="fas fa-trash-alt"></i>
                                        </Button>
                                      </Col>
                                    </Row>
                                  ))}
                                </TabPane>

                                <TabPane tabId="saturday">
                                  <Row className={styles.tabPaneRow}>
                                    <Col md="4" sm="3">
                                      <h5 className="mt-4">Delivery From</h5>
                                    </Col>
                                    <Col md="4" sm="3">
                                      <h5
                                        className="mt-4"
                                        style={{
                                          marginLeft: isMobile ? "15px" : "",
                                        }}>
                                        Delivery Until
                                      </h5>
                                    </Col>
                                    <Col md="4" sm="3">
                                      <Button
                                        type="button"
                                        className="PrimaryButton"
                                        color="primary"
                                        size="sm"
                                        onClick={() =>
                                          addDeliverySlots(deliverySlots)
                                        }>
                                        Add Slot
                                      </Button>
                                    </Col>
                                  </Row>
                                  {deliverySlots?.map((val, index) => (
                                    <Row className={styles.tabPaneRow}>
                                      <Col md="4" sm="4">
                                        <FormGroup>
                                          <Input
                                            id="example3cols11Input"
                                            type="select"
                                            value={val.startTime}
                                            name={"startTime"}
                                            onChange={(e) =>
                                              handleData(index, e)
                                            }>
                                            <option key={"0"} value={""}>
                                              Select Delivery From
                                            </option>
                                            {allTimes.map((allTime, index) => (
                                              <option
                                                key={index + 1}
                                                value={moment(allTime, [
                                                  "h:mm A",
                                                ]).format("HH:mm")}>
                                                {allTime}
                                              </option>
                                            ))}
                                          </Input>
                                        </FormGroup>
                                      </Col>
                                      <Col md="4" sm="4">
                                        <FormGroup>
                                          <Input
                                            id="example3cols12Input"
                                            type="select"
                                            value={val.endTime}
                                            name={"endTime"}
                                            onChange={(e) =>
                                              handleData(index, e)
                                            }>
                                            <option key={"0"} value={""}>
                                              Select Delivery Until
                                            </option>
                                            {allTimes.map((allTime, index) => (
                                              <option
                                                key={index + 1}
                                                value={moment(allTime, [
                                                  "h:mm A",
                                                ]).format("HH:mm")}>
                                                {allTime}
                                              </option>
                                            ))}
                                          </Input>
                                        </FormGroup>
                                      </Col>
                                      <Col md="4" sm="4">
                                        <Button
                                          type="button"
                                          color="danger"
                                          size="sm"
                                          className="btn-icon"
                                          onClick={() =>
                                            removeDeliverySlots(index, val)
                                          }>
                                          <i class="fas fa-trash-alt"></i>
                                        </Button>
                                      </Col>
                                    </Row>
                                  ))}
                                </TabPane>
                                <TabPane tabId="holiday">
                                  <Row className={styles.tabPaneRow}>
                                    <Col lg-12>
                                      <h3>Holidays List</h3>
                                    </Col>
                                  </Row>
                                  {/* <Row>
                                    <Col lg-12>
                                      <h3>All Holidays</h3>
                                    </Col>
                                  </Row> */}
                                  <Table
                                    className="align-items-center table-flush"
                                    responsive>
                                    <thead className="thead-light">
                                      <tr>
                                        <th scope="col">Serial No</th>
                                        <th scope="col">Title</th>
                                        <th scope="col">From</th>
                                        <th scope="col">To</th>
                                        <th scope="col" />
                                      </tr>
                                    </thead>
                                    <tbody className="list">
                                      {holidayDeliverySlots?.map(
                                        (val, index) => (
                                          <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>{val.title}</td>
                                            <td>
                                              {moment(
                                                val.startDate,
                                                "x"
                                              ).format("DD MMM YYYY hh:mm a")}
                                            </td>
                                            <td>
                                              {" "}
                                              {moment(val.endDate, "x").format(
                                                "DD MMM YYYY hh:mm a"
                                              )}
                                            </td>
                                            <td>
                                              {" "}
                                              <Button
                                                type="button"
                                                color="danger"
                                                size="sm"
                                                className="btn-icon"
                                                // onClick={() =>
                                                //   deleteHoliday(index, val)
                                                // }
                                              >
                                                <i class="fas fa-trash-alt"></i>
                                              </Button>
                                            </td>
                                          </tr>
                                        )
                                      )}
                                    </tbody>
                                  </Table>
                                  {showForm ? (
                                    <>
                                      <Row>
                                        <Col md="4" sm="4">
                                          <h5 className="mt-4">Title</h5>
                                        </Col>
                                      </Row>
                                      <Row className={styles.tabPaneRow}>
                                        <Col md="4" sm="4">
                                          <Input
                                            type="text"
                                            name="title"
                                            onChange={(e) => {
                                              setTitle(e.target.value);
                                              setTitleError(false);
                                            }}
                                            value={title}
                                            placeholder="Holiday Name"
                                          />
                                          {titleError && (
                                            <p
                                              style={{
                                                color: "red",
                                                fontSize: "12px",
                                              }}>
                                              Title is required
                                            </p>
                                          )}
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col
                                          md="4"
                                          sm="4"
                                          className={styles.tabPaneRowHoliday}>
                                          <h5 className="mt-4">Holiday From</h5>
                                        </Col>
                                        <Col
                                          md="4"
                                          sm="4"
                                          className={styles.tabPaneRowHoliday}>
                                          <h5
                                            className="mt-4"
                                            style={{
                                              marginLeft: isMobile
                                                ? "-6px"
                                                : "",
                                            }}>
                                            Holiday Until
                                          </h5>
                                        </Col>
                                      </Row>

                                      <Row className={styles.dateAndTimePicker}>
                                        <Col
                                          md="4"
                                          sm="4"
                                          className={styles.dateAndTime}>
                                          <DatePicker
                                            selected={startDate}
                                            selectsStart
                                            startDate={startDate}
                                            showTimeSelect
                                            dateFormat="MMMM d, yyyy h:mm aa"
                                            onChange={handleDateChange}
                                          />
                                        </Col>
                                        <Col
                                          md="4"
                                          sm="4"
                                          className={styles.dateAndTime}>
                                          <DatePicker
                                            selected={endDate}
                                            selectsStart
                                            startDate={endDate}
                                            showTimeSelect
                                            dateFormat="MMMM d, yyyy h:mm aa"
                                            onChange={handleEndDate}
                                          />
                                        </Col>
                                      </Row>
                                    </>
                                  ) : null}
                                  <br></br>
                                  {/* <Row>
                                    <Col lg-12>
                                      <h3>All Holidays</h3>
                                    </Col>
                                  </Row>
                                  {holidayDeliverySlots?.map((val, index) => (
                                    <Row>
                                      <Col md="4" sm="6">
                                        {moment(val.startDate, "x").format(
                                          "DD MMM YYYY hh:mm a"
                                        )}
                                      </Col>
                                      <Col md="4" sm="6">
                                        {moment(val.endDate, "x").format(
                                          "DD MMM YYYY hh:mm a"
                                        )}
                                      </Col>
                                      <Col md="4" sm="6">
                                        <Button
                                          type="button"
                                          color="danger"
                                          size="sm"
                                          className="btn-icon"
                                          onClick={() =>
                                            deleteHoliday(index, val)
                                          }
                                        >
                                          <i class="fas fa-trash-alt"></i>
                                        </Button>
                                      </Col>
                                    </Row>
                                  ))} */}
                                </TabPane>
                              </TabContent>
                            </Col>
                          </Row>
                          {/* <Button
                                    color="primary"
                                    onClick={addSundaySlots(deliverySlots)}
                                  >
                                    Add
                                  </Button> */}
                          {/* <hr /> */}
                          {/* <Row>
                            <Col md="6" sm="6">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="delivery_fee"
                                >
                                  Delivery Fee
                                </label>
                                <Input
                                  id="delivery_fee"
                                  placeholder="Delivery Fee"
                                  type="text"
                                  value={values.delivery_fee}
                                  name="delivery_fee"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                              </FormGroup>
                            </Col>
                            <Col md="6" sm="6">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="minimum_order_value"
                                >
                                  Minimum Order Value
                                </label>
                                <Input
                                  id="minimum_order_value"
                                  placeholder="Minimum Order Value"
                                  type="minimum_order_value"
                                  value={values.minimum_order_value}
                                  name="minimum_order_value"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                              </FormGroup>
                            </Col>
                            <Col md="12" sm="12">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="zip_codes"
                                >
                                  Zip Code
                                </label>
                                <Input
                                  id="zip_codes"
                                  rows="3"
                                  type="textarea"
                                  placeholder="Zip Code"

                                  name="zip_codes"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                              </FormGroup>
                            </Col>
                          </Row>

                          <Row>
                            <Col md="3" sm="6">
                              <FormGroup>
                                <div className="custom-control custom-checkbox mb-3">
                                  <input
                                    className="custom-control-input"
                                    id="customCheck1"
                                    type="checkbox"
                                    value={values.is_delivery_enabled}
                                    name="is_delivery_enabled"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                  <label
                                    className="custom-control-label"
                                    htmlFor="customCheck1"
                                  >
                                    Enable Delivery
                                  </label>
                                </div>
                              </FormGroup>
                            </Col>
                            <Col md="3" sm="6">
                              <FormGroup>
                                <div className="custom-control custom-checkbox mb-3">
                                  <input
                                    className="custom-control-input"
                                    id="customCheck2"
                                    type="checkbox"
                                    value={values.is_shipping_enabled}
                                    name="is_shipping_enabled"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                  <label
                                    className="custom-control-label"
                                    htmlFor="customCheck2"
                                  >
                                    Enable Shipping
                                  </label>
                                </div>
                              </FormGroup>
                            </Col>
                          </Row> */}
                          {tabContentKey == "holiday" ? (
                            <>
                              <Button
                                color="primary"
                                onClick={
                                  showForm
                                    ? handleButtonClick
                                    : () => setShowForm(true)
                                }>
                                {showForm ? "Save" : "Add Holiday"}
                              </Button>
                              {showForm && (
                                <Button
                                  color="secondary"
                                  onClick={() => {
                                    setShowForm(false);
                                    setTitle("");
                                    setStartDate(new Date());
                                    setEndDate(new Date());
                                  }}>
                                  Cancel
                                </Button>
                              )}
                            </>
                          ) : (
                            <Button
                              color="primary"
                              type="submit"
                              ref={somsection}>
                              Submit Form
                            </Button>
                          )}
                        </Form>
                      )}
                    </Formik>
                  </TabPane>
                  <TabPane tabId="third_party_app_partners">
                    <Formik
                      initialValues={{ ...formData }}
                      validate={(values) => {
                        console.log(values);
                        values = { ...values };
                        const errors = {};
                      }}
                      onSubmit={(values, { setSubmitting }) => {
                        selectedDeliveryPartner(values, setSubmitting);
                      }}>
                      {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        setValues,
                      }) => (
                        <Form>
                          <Table
                            className="align-items-center table-flush"
                            responsive>
                            <tbody className="list">
                              <tr>
                                <td>
                                  <FormGroup check>
                                    <Input
                                      name="selectAll"
                                      type="checkbox"
                                      checked={
                                        deliveryOptions.length ==
                                        selectedDeliveryOptions.length
                                      }
                                      onChange={handleSelectAll}
                                      style={{
                                        marginLeft: "30%",
                                        marginTop: "3px",
                                      }}
                                    />
                                    <Label check>Select All</Label>
                                  </FormGroup>
                                </td>
                              </tr>
                              {deliveryOptions.map((option) => (
                                <tr key={option.id}>
                                  <td>
                                    <FormGroup check>
                                      <Input
                                        name={`deliveryOption-${option.id}`}
                                        type="checkbox"
                                        value={option.id}
                                        checked={selectedDeliveryOptions.includes(
                                          option.id
                                        )}
                                        onChange={(event) =>
                                          handleCheckboxChange(event, option.id)
                                        }
                                        style={{
                                          marginLeft: "30%",
                                          marginTop: "3px",
                                        }}
                                      />
                                      <Label check>{option.name}</Label>
                                    </FormGroup>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                          {/* {selectedDeliveryOptions.length > 0 && ( */}
                          <Button
                            color="primary"
                            type="submit"
                            // onClick={selectedDeliveryPartner}
                            // disabled={isSubmitting}
                          >
                            Submit
                          </Button>
                          {/* )} */}
                        </Form>
                      )}
                    </Formik>
                  </TabPane>
                  <TabPane tabId="active_upc">
                    <Row>
                      <Col md="4" sm="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="example1cols1Input"
                            style={{ fontWeight: 700, marginLeft: "20px" }}>
                            Active UPCs : {activeUPC[0]?.active_upc} /{" "}
                            {activeUPC[0]?.total_upc}{" "}
                          </label>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Table className="align-items-center table-flush">
                      <thead
                        className="thead-light"
                        style={{ position: "sticky", top: 0, zIndex: 100 }}>
                        <tr>
                          <th>Category Name</th>
                          <th>
                            Total UPC
                            <span style={{ textTransform: "lowercase" }}>
                              s
                            </span>
                          </th>
                          <th>
                            Active UPC
                            <span style={{ textTransform: "lowercase" }}>
                              s
                            </span>
                          </th>
                        </tr>
                      </thead>
                      <tbody className="list">
                        {categoryData && categoryData?.length ? (
                          categoryData?.map((item, index) => (
                            <tr key={index}>
                              <td style={{ textTransform: "capitalize" }}>
                                {item?.category_name?.length > 0
                                  ? item?.category_name
                                  : "Uncategorized"}
                              </td>
                              <td>
                                {item?.total_upc?.length > 0
                                  ? item?.total_upc
                                  : "0"}
                              </td>
                              <td>
                                {item?.active_upc?.length > 0
                                  ? item?.active_upc
                                  : "0"}
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            {isLoading ? (
                              <td colSpan="6">Loading...</td>
                            ) : (
                              <td colSpan="6">No data found...</td>
                            )}
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </TabPane>
                  <TabPane tabId="generic_settings">
                    <Formik
                      initialValues={{ ...genericSettingsData }}
                      validationSchema={validationSchemaGenericSettings}
                      onSubmit={(values, { setSubmitting, setFieldValue }) => {
                        updateGenericSettings(
                          values,
                          setSubmitting,
                          setFieldValue
                        );
                      }}
                      enableReinitialize>
                      {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        setFieldValue,
                        setValues,
                        /* and other goodies */
                      }) => (
                        <Form>
                          <div>
                            <h3 className="mb-4 mt-4">Store Information</h3>
                          </div>

                          <Row>
                            <Col md="4" sm="6">
                              <label
                                className="form-control-label"
                                htmlFor="example3cols1Input">
                                Store Location name
                              </label>
                              <Input
                                readOnly
                                id="store_location_name"
                                placeholder="Enter Store Location name"
                                type="text"
                                name="store_location_name"
                                value={values?.store_name}
                                onChange={handleChangeGenericSettings}
                                onBlur={handleBlur}
                              />
                            </Col>
                            <Col md="4" sm="6">
                              <label
                                className="form-control-label"
                                htmlFor="example3cols1Input">
                                Onboarding Date
                              </label>
                              <Input
                                id="onboarding_date"
                                placeholder="onborading Date"
                                type="date"
                                name="onboarding_date"
                                value={values?.onboarding_date}
                                onChange={handleChangeGenericSettings}
                                onBlur={handleBlur}
                              />
                            </Col>
                            <Col md="4" sm="6">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="example3cols2Input">
                                  Client Store ID
                                </label>
                                <Input
                                  readOnly
                                  id="store_id"
                                  placeholder="Enter Client Store ID"
                                  type="text"
                                  name="store_id"
                                  value={values?.store_id}
                                  onChange={handleChangeGenericSettings}
                                  onBlur={handleBlur}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <div>
                            <h3 className="mb-4 mt-4">
                              Client/Owner information
                            </h3>
                          </div>
                          <Row>
                            <Col md="4">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="example3cols2Input">
                                  Owner Firstname
                                </label>
                                <Input
                                  id="owner_first_name"
                                  placeholder="Enter Owner Firstname"
                                  type="text"
                                  name="owner_first_name"
                                  value={values?.owner_first_name}
                                  onChange={handleChangeGenericSettings}
                                  onBlur={handleBlur}
                                />
                              </FormGroup>
                            </Col>
                            <Col md="4">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="example3cols2Input">
                                  Owner Lastname
                                </label>
                                <Input
                                  id="owner_last_name"
                                  placeholder="Enter Owner Lastname"
                                  type="text"
                                  name="owner_last_name"
                                  value={values?.owner_last_name}
                                  onChange={handleChangeGenericSettings}
                                  onBlur={handleBlur}
                                />
                              </FormGroup>
                            </Col>
                            <Col md="4">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="example3cols3Input">
                                  Owner Email
                                </label>
                                <Input
                                  id="owner_email_name"
                                  placeholder="Enter Owner Email"
                                  type="email"
                                  name="owner_email_name"
                                  value={values?.owner_email_name}
                                  onChange={handleChangeGenericSettings}
                                  onBlur={handleBlur}
                                />
                              </FormGroup>
                            </Col>
                            <Col md="4">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="example3cols1Input">
                                  Stores owned by the same Owner
                                </label>
                                <Input
                                  id="stores_owned_by_the_same_owner"
                                  placeholder="Enter Stores owned by the same Owner"
                                  type="text"
                                  name="stores_owned_by_the_same_owner"
                                  value={values?.stores_owned_by_the_same_owner}
                                  onChange={handleChangeGenericSettings}
                                  onBlur={handleBlur}
                                />
                              </FormGroup>
                            </Col>
                            {/* </Row>
                          <Row> */}
                            <Col md="4">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="example3cols1Input">
                                  Manager Firstname
                                </label>
                                <Input
                                  id="manager_first_name"
                                  placeholder="Enter Manager Firstname"
                                  type="text"
                                  name="manager_first_name"
                                  value={values?.manager_first_name}
                                  onChange={handleChangeGenericSettings}
                                  onBlur={handleBlur}
                                />
                              </FormGroup>
                            </Col>

                            <Col md="4">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="example3cols1Input">
                                  Manager Lastname
                                </label>
                                <Input
                                  id="manager_last_name"
                                  placeholder="Enter Manager Lastname"
                                  type="text"
                                  name="manager_last_name"
                                  value={values?.manager_last_name}
                                  onChange={handleChangeGenericSettings}
                                  onBlur={handleBlur}
                                />
                              </FormGroup>
                            </Col>

                            <Col md="4">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="example3cols2Input">
                                  Manager Email
                                </label>
                                <Input
                                  id="manager_email_name"
                                  placeholder="Enter Manager Email"
                                  type="email"
                                  name="manager_email_name"
                                  value={values?.manager_email_name}
                                  onChange={handleChangeGenericSettings}
                                  onBlur={handleBlur}
                                />
                              </FormGroup>
                            </Col>
                            <Col md="4">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="example3cols1Input">
                                  Phone
                                </label>
                                <Input
                                  className={styles.lat_input}
                                  id="phone_number"
                                  placeholder="Enter Phone Number"
                                  type="phone_number"
                                  name="phone_number"
                                  value={values?.phone_number}
                                  onChange={handleChangeGenericSettings}
                                  onBlur={handleBlur}
                                />
                                <p className="ErrorMessage">
                                  {errors?.phone_number &&
                                    touched?.phone_number &&
                                    errors?.phone_number}
                                </p>
                              </FormGroup>
                            </Col>
                          </Row>

                          <div>
                            <h3 className="mb-4 mt-4">Operation and Fin Ops</h3>
                          </div>

                          <Row>
                            <Col md="4">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="example3cols2Input">
                                  Operation Communication Email ID
                                </label>
                                <Input
                                  id="operation_communication_email_id"
                                  placeholder="Enter Operation Communication Email ID"
                                  type="email"
                                  name="operation_communication_email_id"
                                  value={
                                    values?.operation_communication_email_id
                                  }
                                  onChange={handleChangeGenericSettings}
                                  onBlur={handleBlur}
                                />
                              </FormGroup>
                            </Col>
                            <Col md="4">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="example3cols2Input">
                                  Fin Ops Communication Email ID
                                </label>
                                <Input
                                  id="fin_ops_communication_email_id"
                                  placeholder="Enter Fin Ops Communication Email ID"
                                  type="email"
                                  name="fin_ops_communication_email_id"
                                  value={values?.fin_ops_communication_email_id}
                                  onChange={handleChangeGenericSettings}
                                  onBlur={handleBlur}
                                />
                              </FormGroup>
                            </Col>
                          </Row>

                          <div>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}>
                              <div className={styles.sales_tax_remittance_text}>
                                Sales Tax Remittance Settings
                              </div>
                              <span className={styles.orderid_discliamer_text}>
                                * If Checked Sales tax is remitted to the State
                              </span>
                            </div>
                          </div>

                          <Table
                            className="align-items-center table-flush"
                            responsive>
                            <tbody className="list">
                              {genericSettingsData &&
                                Object.entries(genericSettingsData).map(
                                  ([key, value]) =>
                                    key.startsWith("sales_tax_remit_") && (
                                      <tr key={key}>
                                        <td>
                                          <FormGroup check>
                                            <div
                                              style={{
                                                width: "300px",
                                                display: "flex",
                                                justifyContent: "space-between",
                                                flexDirection: "row",
                                              }}>
                                              <div style={{ width: "80%" }}>
                                                <Label check>
                                                  {formatKeyToLabel(key)}
                                                </Label>
                                              </div>
                                              <div style={{ width: "20%" }}>
                                                <Input
                                                  name={`salesTaxOption-${key}`}
                                                  type="checkbox"
                                                  checked={value}
                                                  onChange={(event) =>
                                                    handleSalesTaxCheckboxChange(
                                                      event,
                                                      key
                                                    )
                                                  }
                                                  style={{
                                                    // marginLeft: "6%",
                                                    marginTop: "3px",
                                                  }}
                                                />
                                              </div>
                                            </div>
                                          </FormGroup>
                                        </td>
                                      </tr>
                                    )
                                )}
                            </tbody>
                          </Table>

                          <div>
                            <h3 className="mb-4 mt-4">Alias Name</h3>
                          </div>

                          <Table
                            className="align-items-center table-flush"
                            responsive>
                            <tbody className="list">
                              {genericSettingsData &&
                                Object.entries(genericSettingsData)
                                  .filter(([key]) =>
                                    isMobile
                                      ? key.endsWith("_alias") ||
                                        key === "doordash_store_id"
                                      : key.endsWith("_alias")
                                  )
                                  .sort(([keyA], [keyB]) =>
                                    keyA.localeCompare(keyB)
                                  )
                                  .map(([key, value]) => (
                                    <tr
                                      key={key}
                                      style={{
                                        marginBottom: 0,
                                      }}>
                                      <td>
                                        <FormGroup check>
                                          <div className={styles.aliasName}>
                                            {key !== "doordash_store_id" ? (
                                              <div
                                                style={{
                                                  marginTop: "10px",
                                                }}>
                                                <Label check>
                                                  {formatKeyToLabel(key)}
                                                </Label>
                                              </div>
                                            ) : (
                                              <div
                                                style={{
                                                  marginTop: "10px",
                                                }}>
                                                <Label check>
                                                  {formatKeyToLabel(key)}
                                                </Label>
                                              </div>
                                            )}
                                            <div
                                              className={styles.aliasContainer}>
                                              {key !== "doordash_store_id" ? (
                                                <div
                                                  className={styles.aliasInput}>
                                                  <Input
                                                    id={`aliasName-${key}`}
                                                    name={`aliasName-${key}`}
                                                    type="text"
                                                    value={
                                                      genericSettingsData[
                                                        key
                                                      ] || value
                                                    }
                                                    onChange={(e) =>
                                                      handleAliasChange(e, key)
                                                    }
                                                  />
                                                </div>
                                              ) : (
                                                <div>
                                                  <Input
                                                    id={`aliasName-${key}`}
                                                    name={`aliasName-${key}`}
                                                    type="text"
                                                    value={
                                                      genericSettingsData[
                                                        key
                                                      ] || value
                                                    }
                                                    onChange={(e) =>
                                                      handleAliasChange(e, key)
                                                    }
                                                  />
                                                </div>
                                              )}

                                              {key === "doordash_alias" && (
                                                <>
                                                  <div
                                                    className={
                                                      styles.aliasDoordash
                                                    }>
                                                    <Label check>
                                                      {formatKeyToLabel(
                                                        "doordash_store_id"
                                                      )}
                                                    </Label>
                                                  </div>
                                                  <div
                                                    className={
                                                      styles.aliasInput_doordash
                                                    }>
                                                    <Input
                                                      id={`aliasName-doordash_store_id`}
                                                      name={`aliasName-doordash_store_id`}
                                                      type="text"
                                                      value={
                                                        genericSettingsData[
                                                          "doordash_store_id"
                                                        ] || value
                                                      }
                                                      onChange={(e) =>
                                                        handleAliasChange(
                                                          e,
                                                          "doordash_store_id"
                                                        )
                                                      }
                                                    />
                                                  </div>
                                                </>
                                              )}
                                            </div>
                                          </div>
                                        </FormGroup>
                                      </td>
                                    </tr>
                                  ))}
                            </tbody>
                          </Table>

                          <Button
                            color="primary"
                            type="submit"
                            disabled={isSubmitting}>
                            Submit Form
                          </Button>
                        </Form>
                      )}
                    </Formik>
                  </TabPane>
                </TabContent>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
      <Backdrop className={classes.backdrop} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}

export default withRouter(Elements);
