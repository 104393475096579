import React, { useEffect, useState } from "react";
import {
  Card,
  CardHeader,
  Col,
  Container,
  Row,
  Button,
  CardBody,
  FormGroup,
  Form,
  Input,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  ButtonGroup,
  CustomInput,
} from "reactstrap";
import FullPageLoader from "../../../components/FullPageLoader";
import { ApiEndpoints } from "../../constants/constant";
import { useHistory, useLocation, Link, useParams } from "react-router-dom";
import axios from "axios";
import "./styles.css";
import moment from "moment";
import Select from "react-select";

import { logUserMetrics } from "../../../utils/file";
import { toast } from "react-toastify";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

let productArray = [];

const arrData = [
  {
    main_title: "Product Name",
    id: "product_name",
    options: [
      {
        title: "Product Name",
        id: "product_name",
      },
      {
        title: "UPC",
        id: "upc",
      },
      {
        title: "Quantity",
        id: "quantity",
      },
      {
        title: "Price",
        id: "price",
      },
    ],
  },
  {
    main_title: "UPC",
    id: "upc",
    options: [
      {
        title: "Product Name",
        id: "product_name",
      },
      {
        title: "UPC",
        id: "upc",
      },
      {
        title: "Quantity",
        id: "quantity",
      },
      {
        title: "Price",
        id: "price",
      },
    ],
  },
  {
    main_title: "Category",
    id: "category",
    options: [
      {
        title: "Product Name",
        id: "product_name",
      },
      {
        title: "UPC",
        id: "upc",
      },
      {
        title: "Quantity",
        id: "quantity",
      },
      {
        title: "Price",
        id: "price",
      },
    ],
  },
  {
    main_title: "Amount",
    id: "amount",
    options: [
      {
        title: "Product Name",
        id: "product_name",
      },
      {
        title: "UPC",
        id: "upc",
      },
      {
        title: "Quantity",
        id: "quantity",
      },
      {
        title: "Price",
        id: "price",
      },
    ],
  },
];

const InventoryFileEdit = () => {
  //const history = useHistory();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const history = useHistory();
  const { item, permission } = location?.state;
  //console.log("id", id);
  const [data, setData] = useState([]);
  const [modal, setModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const toggleModal = () => {
    setShowModal(!showModal);
  };
  const handleClose = () => setShowModal(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [importModal, setImportModal] = useState(false);
  const [rejectModal, setRejectModal] = useState(false);
  const [reasonModalCheck, setReasonModalCheck] = useState(false);
  const reasonModalToggle = () => setReasonModalCheck(!reasonModalCheck);
  const [approvalStatus, setApprovalStatus] = useState("");
  const [reason, setReason] = useState("");
  const [exportProducts, setExportProducts] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [productSuggModal, setProductSuggModal] = useState(false);
  const [allSelectedItems, setAllSelectedItems] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [allOptions, setAllOptions] = useState([]);
  const [selectedHeaderOptions, setSelectedHeaderOptions] = useState([]);
  const [allHeaderOptions, setHeaderAllOptions] = useState([]);
  const [allData, setAllData] = useState([]);
  const [allFileData, setAllFileData] = useState([]);
  const [importFileData, setImportFileData] = useState([]);
  const [systemMappedChecked, setSystemMappedChecked] = useState(true);
  const [invalidDataChecked, setInvalidDataChecked] = useState(false);
  const [notFoundChecked, setNotFoundChecked] = useState(false);
  const [importalert, setImportAlert] = useState(false);
  const [suggestionsDataChecked, setSuggestionsDataChecked] = useState(false);
  const [manuallyDataChecked, setManuallyDataChecked] = useState(true);

  console.log(
    "checked",
    systemMappedChecked,
    invalidDataChecked,
    notFoundChecked,
    suggestionsDataChecked
  );

  useEffect(() => {
    if (importFileData?.invalidDataCount === "0") {
      setInvalidDataChecked(true);
    }
    if (importFileData?.productNotFoundCount === "0") {
      setNotFoundChecked(true);
    }
    if (importFileData?.suggestionCount === "0") {
      setSuggestionsDataChecked(true);
    }
  }, [
    importFileData?.invalidDataCount,
    importFileData?.productNotFoundCount,
    importFileData?.suggestionCount,
  ]);

  const { id } = useParams();

  const [dataItem, setDataItem] = useState(item);

  console.log("dataItem", dataItem);

  const modalToggle = () => {
    // setApprovalStatus("");
    setModal(!modal);
  };

  const confirmModalToggle = () => {
    setConfirmModal(!confirmModal);
  };

  const confirmImportToggle = () => {
    setImportModal(!importModal);
  };

  const confirmAlertToggle = () => {
    setImportAlert(!importalert);
    setImportModal(false);
  };

  const confirmRejectToggle = () => {
    setRejectModal(!rejectModal);
  };

  console.log("ITEM", item);

  async function getImportAsJson() {
    try {
      setIsLoading(true);
      let importApi = `${ApiEndpoints.jsonMenuDataImport}?transaction_id=${id}`;
      let res = await axios.get(importApi);
      console.log("TES", res);
      let response = JSON.parse(JSON.stringify(res.data.data));
      console.log("response4564646", response);
      setData(response);
      // checkErrorUPCs(response);
      productArray = response;
      setExportProducts(response);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  }

  const getPosData = () => {
    setIsLoading(true);
    let getData = `${ApiEndpoints.posInventoryDataByID}/${id}`;

    fetch(getData)
      .then((response) => response.json())
      .then((res) => {
        setIsLoading(false);
        console.log("response", res);
        setAllData(res?.data);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log("61616166116", err);
      });
  };
  useEffect(() => {
    getPosData();
  }, []);

  const getPosFileData = () => {
    setIsLoading(true);
    let getData = `${ApiEndpoints.posImportedDataByID}/${id}`;

    fetch(getData)
      .then((response) => response.json())
      .then((res) => {
        setIsLoading(false);
        console.log("responsFile", res);
        setAllFileData(res?.data);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log("61616166116", err);
      });
  };
  useEffect(() => {
    getPosFileData();
  }, []);

  const getImportFileData = () => {
    setIsLoading(true);
    let getData = `${ApiEndpoints.posStatusCount}/${id}`;

    fetch(getData)
      .then((response) => response.json())
      .then((res) => {
        setIsLoading(false);
        console.log("responsImportFile", res);
        setImportFileData(res?.data?.rows[0]);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log("61616166116", err);
      });
  };
  useEffect(() => {
    getImportFileData();
  }, []);

  console.log("importFileData", importFileData);

  const getHeaderOptions = () => {
    setIsLoading(true);
    let getHeaders = `${ApiEndpoints.posUploadedHeaders}/${id}`;

    fetch(getHeaders)
      .then((response) => response.json())
      .then((res) => {
        let response = res?.data?.headers;
        console.log("responseres", response);
        let tempResponseHeaders = [...response];
        let allHeaders = [];
        for (let i = 0; i < tempResponseHeaders.length; i++) {
          allHeaders.push({
            value: tempResponseHeaders[i],
            label: tempResponseHeaders[i],
          });
        }
        console.log("all headers", allHeaders);
        setHeaderAllOptions(allHeaders);
        let transformedHeaderData = response.map((item, index) => ({
          id: index.toString(),
          main_title: item,
          options: [
            {
              title: item,
              id: index.toString(),
            },
          ],
          options2: allOptions,
        }));
        console.log("responseHeaders", transformedHeaderData);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log("61616166116", err);
      });
  };
  useEffect(() => {
    getHeaderOptions();
  }, []);

  console.log("headeropt", allHeaderOptions);

  // const checkErrorUPCs = (response) => {
  //   const errorUPCsList = [];
  //   const targetUPCs = [
  //     "820007764644",
  //     "8700094594594",
  //     "8809595955959",
  //     "989848949948",
  //     "85489418498498",
  //   ];

  //   response.forEach((item) => {
  //     console.log("itemsaans", item);
  //     if (targetUPCs.includes(item.upc)) {
  //       errorUPCsList.push(item.upc);
  //     }
  //   });

  //   console.log("ajksdsjabjdks", errorUPCsList);

  //   setErrorUPCs(errorUPCsList);
  // };

  console.log("expoertgayusgdyusa", exportProducts);

  useEffect(() => {
    if (Object.keys(dataItem).length > 0) {
      getImportAsJson();
    } else {
      history.push("/admin/menu-import");
    }
  }, []);

  console.log("DATA", data);

  const updateAprrovalStatus = (value) => {
    console.log("valuevalue", value);
    setApprovalStatus(value);
    if (value === "approve") {
      setConfirmModal(true);
    } else if (value === "reject") {
      setModal(true);
    } else {
      setApprovalStatus("pending");
    }
  };

  const handleImportData = async () => {
    let approvalApi = `${ApiEndpoints.mappedPosDataImport}`;
    let body = {
      imported_request_id: allData.id,
    };

    console.log("importbody", body);
    setIsLoading(true);
    try {
      let res = await axios.post(approvalApi, body);
      console.log("RESPONSE", res);
      if (res.status === 200) {
        setModal(false);
        setImportModal(false);
        setReasonModalCheck(false);
        setImportAlert(false);
        toast.success("File Imported successfully");
        getPosFileData();
        getPosData();
        getImportFileData();
        window.location.reload();
        let action = "pos-transaction";
        let type = "Info";
        let message = "POS Transaction imported successfully.";
        const apiResponse = await logUserMetrics(action, type, message);
        console.log("apiirresponse", apiResponse);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setModal(false);
      setImportModal(false);
      setReasonModalCheck(false);
      setIsLoading(false);
      console.log(error);
      toast.error("Error in transaction approval");
      let action = "pos-transaction";
      let type = "Error";
      let message = "Error in POS Transaction Import.";
      const apiResponse = await logUserMetrics(action, type, message);
      console.log("apiirresponse", apiResponse);
    }
  };

  const hanldeUpdateApproval = async () => {
    console.log("Reject api call");
    let approvalApi = `${ApiEndpoints.rejectMappedPosDataImport}`;
    let body = {
      id: allData.id,
      approved: "Rejected",
      reason: reason,
      updated_by: localStorage.getItem("fullname"),
    };
    let headers = {
      "Access-Control-Allow-Origin": "*",
    };
    console.log("cancelbody", body);
    setIsLoading(true);
    try {
      let res = await axios.post(approvalApi, body);
      console.log("RESPONSE", res);
      if (res.status === 200) {
        setModal(false);
        setReasonModalCheck(false);
        setIsLoading(false);
        setAllData({
          ...allData,
          approved: "Rejected",
          reason: reason,
        });
        setReason("");
        toast.success("Transaction is successfully rejected");
        getPosFileData();
        getPosData();
        getImportFileData();
        window.location.reload();
        let action = "pos-transaction-reject";
        let type = "Info";
        let message = "POS Transaction rejected successfully.";
        const apiResponse = await logUserMetrics(action, type, message);
        console.log("apiirresponse", apiResponse);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      console.log("error", error?.response?.data?.message);
      // toast.error(error?.response?.data?.message[0].error);
      if (error?.response?.data?.message === "Already Approved") {
        toast.error("Already Approved");
      } else {
        toast.error("Error in transaction approval");
      }
      let action = "pos-transaction-reject";
      let type = "Error";
      let message = "Error in POS Transaction Reject Import.";
      const apiResponse = await logUserMetrics(action, type, message);
      console.log("apiirresponse", apiResponse);
      setIsLoading(false);
    }

    // if (approvalStatus === "approve") {
    //   console.log("Approved api call");
    //   let approvalApi = `https://i0a73s5uf3.execute-api.us-east-1.amazonaws.com/dev/v1/approve-pos-import`;
    //   // `${ApiEndpoints.aaprovedMenuImports}`;
    //   let body = {
    //     id: allData.id,
    //     approved: "Approved",
    //     // updated_by: localStorage.getItem("fullname"),
    //   };
    //   let headers = {
    //     "Access-Control-Allow-Origin": "*",
    //   };
    //   setIsLoading(true);
    //   try {
    //     let res = await axios.post(approvalApi, body);
    //     console.log("RESPONSE", res);
    //     if (res.status === 200) {
    //       setConfirmModal(false);
    //       setAllData({ ...allData, approved: "Approved" });
    //       toast.success("Transaction is successfully approved");
    //       getPosData();
    //       getPosFileData();
    //       window.location.reload();
    //       // let action = "menu-transaction";
    //       // let type = "Info";
    //       // let message = "Menu Transaction approved successfully.";
    //       // const apiResponse = await logUserMetrics(action, type, message);
    //       // console.log("apiirresponse", apiResponse);
    //       setIsLoading(false);
    //     } else {
    //       setIsLoading(false);
    //       toast.error("Error in transaction approval");
    //       // let action = "menu-transaction";
    //       // let type = "Error";
    //       // let message = "Error in Menu Transaction approval.";
    //       // const apiResponse = await logUserMetrics(action, type, message);
    //       // console.log("apiirresponse", apiResponse);
    //     }
    //   } catch (error) {
    //     console.log(error);
    //   }
    // } else if (approvalStatus === "reject") {
    //   console.log("Reject api call");
    //   let approvalApi = `https://i0a73s5uf3.execute-api.us-east-1.amazonaws.com/dev/v1/approve-pos-import`;
    //   // `${ApiEndpoints.aaprovedMenuImports}`;
    //   let body = {
    //     id: allData.id,
    //     approved: "Rejected",
    //     reason: reason,
    //     // updated_by: localStorage.getItem("fullname"),
    //   };
    //   let headers = {
    //     "Access-Control-Allow-Origin": "*",
    //   };
    //   setIsLoading(true);
    //   try {
    //     let res = await axios.post(approvalApi, body);
    //     console.log("RESPONSE", res);
    //     if (res.status === 200) {
    //       setModal(false);
    //       setReasonModalCheck(false);
    //       setIsLoading(false);
    //       setAllData({
    //         ...allData,
    //         approved: "Rejected",
    //         reason: reason,
    //       });
    //       setReason("");
    //       toast.success("Transaction is successfully rejected");
    //       getPosFileData();
    //       getPosData();
    //       window.location.reload();
    //       // let action = "menu-transaction";
    //       // let type = "Info";
    //       // let message = "Menu Transaction rejected successfully.";
    //       // const apiResponse = await logUserMetrics(action, type, message);
    //       // console.log("apiirresponse", apiResponse);
    //       setIsLoading(false);
    //     } else {
    //       setIsLoading(false);
    //     }
    //   } catch (error) {
    //     console.log(error);
    //     toast.error("Error in transaction approval");
    //     // let action = "menu-transaction";
    //     // let type = "Error";
    //     // let message = "Error in Menu Transaction reject.";
    //     // const apiResponse = await logUserMetrics(action, type, message);
    //     // console.log("apiirresponse", apiResponse);
    //   }
    // }
  };

  useEffect(() => {
    if (arrData.length) {
      let optns = arrData[0].options;
      let newOptions = optns.map((elm) => ({
        ...elm,
        value: elm.id,
        label: elm.title,
      }));
      setAllOptions(newOptions);
      //setInitialOptions(newOptions);
    }
  }, []);

  // const dropdownArr = ["Pending For Approval", "Approve", "Reject"];
  // const [dropdown, setDropdown] = useState(dropdownArr);

  // const selectedValue = (value) => {
  //   let index = dropdownArr.findIndex((item) => item === value);
  //   dropdownArr.splice(index, 1);
  //   dropdownArr.unshift(value);
  //   setDropdown(dropdownArr);
  // };

  const handleMappingSubmit = async () => {
    let mapping_key = [];
    let optionsMissing = false;

    allSelectedItems.map((item) => {
      if (!item?.selected_file_column || !item?.selected_master_data) {
        optionsMissing = true;
        console.error("Please select all four options inside");
      } else {
        mapping_key.push({
          file_key: item?.selected_file_column,
          mapping_key: item?.selected_master_data,
        });
      }
    });

    console.log("mapping_key", mapping_key);

    if (optionsMissing || mapping_key?.length !== 4) {
      toast.error("Please select all options.");
      return;
    }

    let reqBody = {
      pos_name: allData?.pos_name,
      mapping_key: mapping_key,
      imported_request_id: allData.id,
      updated_by: localStorage.getItem("fullname"),
      uploaded_by: localStorage.getItem("fullname"),
    };

    console.log("reqBody", reqBody);
    setIsLoading(true);

    try {
      let response = await axios.post(`${ApiEndpoints.posMapping}`, reqBody);

      if (response.status === 200) {
        setIsLoading(false);
        setShowModal(false);
        toast.success("Mapping created successfully");
        window.location.reload();
        let action = "pos-menu";
        let type = "Info";
        let message = "POS Menu created successfully.";
        const apiResponse = await logUserMetrics(action, type, message);
        console.log("apiirresponse", apiResponse);
      }
    } catch (error) {
      console.error("12345", error);
      setIsLoading(false);
      let action = "pos-menu";
      let type = "Error";
      let message = "Error in pos menu.";
      const apiResponse = await logUserMetrics(action, type, message);
      console.log("apiirresponse", apiResponse);
    }
  };

  const handleDropdownChange = (selectedOption, index) => {
    if (!selectedOption) {
      console.log("change cross", selectedOptions[index]);
      let updatedOptions = [...allOptions, selectedOptions[index]];
      setAllOptions(updatedOptions);
      console.log("change selected", selectedOptions);
      delete selectedOptions[index];
      let tempSelectedArr = [...allSelectedItems];
      delete tempSelectedArr[index].selected_master_data;
      console.log("tempSelectedArr", tempSelectedArr);
      setAllSelectedItems(tempSelectedArr);
    } else {
      if (selectedOptions.hasOwnProperty(index)) {
        let lastSelected = selectedOptions[index];
        console.log("change last", lastSelected);
        let updatedOptions = allOptions.filter(
          (option) => option.value !== selectedOption.value
        );
        let newUpdateOption = [...updatedOptions, lastSelected];
        setAllOptions(newUpdateOption);
        setSelectedOptions((p) => ({
          ...p,
          [index]: selectedOption,
        }));
      } else {
        const updatedOptions = allOptions.filter(
          (option) => option.value !== selectedOption.value
        );
        setAllOptions(updatedOptions);
        setSelectedOptions((p) => ({
          ...p,
          [index]: selectedOption,
        }));
      }
      let tempSelectedArr = [...allSelectedItems];
      tempSelectedArr[index].selected_master_data = selectedOption.value;
      console.log("tempSelectedArr", tempSelectedArr);
      setAllSelectedItems(tempSelectedArr);
    }
  };

  console.log("arrdataa", arrData);

  const handleDropdownChangeHeader = (selectedHeaderOption, index) => {
    console.log("selected header option", selectedHeaderOption);
    if (!selectedHeaderOption) {
      console.log("change cross", selectedHeaderOptions[index]);
      let updatedOptions = [...allHeaderOptions, selectedHeaderOptions[index]];
      setHeaderAllOptions(updatedOptions);
      console.log("change selected", selectedHeaderOptions);
      delete selectedHeaderOptions[index];
      let tempSelectedArr = [...allSelectedItems];
      delete tempSelectedArr[index].selected_file_column;
      console.log("tempSelectedArr", tempSelectedArr);
      setAllSelectedItems(tempSelectedArr);
    } else {
      if (selectedHeaderOptions.hasOwnProperty(index)) {
        let lastSelected = selectedHeaderOptions[index];
        console.log("change last", lastSelected);
        let updatedOptions = allHeaderOptions.filter(
          (option) => option.value !== selectedHeaderOption.value
        );
        let newUpdateOption = [...updatedOptions, lastSelected];
        setHeaderAllOptions(newUpdateOption);
        setSelectedHeaderOptions((p) => ({
          ...p,
          [index]: selectedHeaderOption,
        }));
      } else {
        const updatedOptions = allHeaderOptions.filter(
          (option) => option.value !== selectedHeaderOption.value
        );
        setHeaderAllOptions(updatedOptions);
        setSelectedHeaderOptions((p) => ({
          ...p,
          [index]: selectedHeaderOption,
        }));
      }
      let tempSelectedArr = [...allSelectedItems];
      tempSelectedArr[index].selected_file_column = selectedHeaderOption.value;
      console.log("tempSelectedArr", tempSelectedArr);
      setAllSelectedItems(tempSelectedArr);
    }
  };

  console.log("change", selectedOptions, allOptions);

  useEffect(() => {
    if (allSelectedItems.length == 0) {
      let tempArr = [];
      if (allHeaderOptions.length && allOptions.length) {
        for (let i = 0; i < allOptions.length; i++) {
          tempArr.push({
            option1: allHeaderOptions,
            option2: allOptions,
          });
        }
      }
      console.log("temp arr", tempArr);
      if (tempArr.length) {
        setAllSelectedItems(tempArr);
      }
    }
  }, [allHeaderOptions.length, allOptions.length]);

  console.log("selected arr", allSelectedItems);

  const handleSearch = (arr, str) => {
    console.log("ARRR", arr);
    let res = arr.filter(
      (item) =>
        (item &&
          item["upc"] &&
          item["upc"].toLowerCase().includes(str.toLowerCase())) ||
        item["name"].toLowerCase().includes(str.toLowerCase())
    );
    console.log("RESULT", res);
    return res;
  };

  const handleOnSearch = (string, results) => {
    // onSearch will have as the first callback parameter
    // the string searched and for the second the results.
    setSearchText(string);
    console.log("STRING", string, results);

    let searchRes = handleSearch(productArray, string);
    console.log("SEra", searchRes);
    let products = [];

    for (let i = 0; i < searchRes.length; i++) {
      products.push({
        sku: searchRes[i]["sku"],
        upc: searchRes[i]["upc"],
        price: searchRes[i]["price"],
        name: searchRes[i]["name"],
        ls_qty: searchRes[i]["ls_qty"],
        status: searchRes[i]["status"],
      });
    }

    setData(products);

    var stringLength = string.length;
    if (stringLength <= 0) {
      let products = [];
      for (let i = 0; i < exportProducts.length; i++) {
        products.push({
          sku: exportProducts[i]["sku"],
          upc: exportProducts[i]["upc"],
          price: exportProducts[i]["price"],
          name: exportProducts[i]["name"],
          ls_qty: exportProducts[i]["ls_qty"],
          status: exportProducts[i]["status"],
        });
      }
      setData(products);
    }
  };

  function onClear() {
    let products = [];
    for (let i = 0; i < exportProducts.length; i++) {
      products.push({
        sku: exportProducts[i]["sku"],
        upc: exportProducts[i]["upc"],
        price: exportProducts[i]["price"],
        name: exportProducts[i]["name"],
        ls_qty: exportProducts[i]["ls_qty"],
        status: exportProducts[i]["status"],
      });
    }
    console.log("asdsad4as84", products);
    setData(products);
  }

  useEffect(() => {
    if (searchText.length === 0) {
      onClear();
    }
  }, [searchText.length]);

  const handleOnHover = (result) => {
    // the item hovered
    //console.log(result)
  };

  const handleOnSelect = (item) => {
    const exists = data.find((p) => p.id === item.id);
    if (exists) {
      alert("Item already selected.");
      return;
    }
    setData([...data, item]);
  };

  const handleOnFocus = () => {
    console.log("Focused");
  };

  const handleViewSystemMapped = (data) => {
    console.log("datsadsua", data);
    const viewPath = {
      pathname: `/admin/inventory/view/${dataItem.id}`,
      state: {
        item: data,
        permission: "edit",
      },
    };
    history.push(viewPath);
  };

  const handleViewInvalid = (data) => {
    console.log("datsadsua", data);
    const viewPath = {
      pathname: `/admin/inventory/view/${dataItem.id}`,
      state: {
        item: data,
        permission: "edit",
      },
    };
    history.push(viewPath);
  };

  const handleViewNotFound = (data) => {
    console.log("datsadsua", data);
    const viewPath = {
      pathname: `/admin/inventory/view/${dataItem.id}`,
      state: {
        item: data,
        permission: "edit",
      },
    };
    history.push(viewPath);
  };

  // if (isLoading) {
  //   return <FullPageLoader />;
  // }

  return (
    <>
      <Container fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="">
                <Row className="align-items-center">
                  <Col lg="6" xs="7">
                    <span
                      className="back-btn"
                      style={{ cursor: "pointer", marginRight: 5 }}
                      onClick={() => history.goBack()}
                    >
                      <i class="fa fa-arrow-left " aria-hidden="true"></i>
                    </span>
                    <h6 className="fullcalendar-title h2 d-inline-block mb-0">
                      {"Imports"}
                    </h6>
                  </Col>
                  <Col className="text-md-right" lg="6" xs="7">
                    {allData.processing_status === "Mapping pending" ? (
                      <Button color="default" onClick={toggleModal} size="sm">
                        Create Mapping
                      </Button>
                    ) : null}
                    <Modal
                      isOpen={showModal}
                      // onHide={handleClose}
                      size="lg"
                      centered
                    >
                      <ModalBody>
                        <Row>
                          <Col lg="6">
                            <h2>Mapping POS Inventory</h2>
                          </Col>

                          <Col lg="6">
                            <FormGroup controlId="mappingName">
                              <Label>Mapping Name</Label>
                              <Input
                                type="text"
                                value={allData?.pos_name}
                                readOnly
                                // onChange={(e) => setMappingName(e.target.value)}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="6">
                            <h2>File Columns</h2>

                            {allSelectedItems?.map((item, index) => (
                              <FormGroup key={item.id}>
                                <Select
                                  // isDisabled
                                  // defaultValue={{
                                  //   value: item.id,
                                  //   label: item.main_title,
                                  // }}
                                  options={allHeaderOptions}
                                  onChange={(selectedHeaderOption) =>
                                    handleDropdownChangeHeader(
                                      selectedHeaderOption,
                                      index
                                    )
                                  }
                                  isClearable
                                  onInputChange={() => {}}
                                  value={selectedHeaderOptions[item.id]}
                                />
                              </FormGroup>
                            ))}
                          </Col>
                          <Col md="6">
                            <h2>Master Data</h2>
                            {allSelectedItems?.map((item, index) => (
                              <FormGroup key={item.id}>
                                {/* <Label>{item.main_title}</Label> */}
                                <Select
                                  options={allOptions}
                                  onChange={(selectedOption) =>
                                    handleDropdownChange(selectedOption, index)
                                  }
                                  isClearable
                                  onInputChange={() => {}}
                                  value={selectedOptions[item.id]}
                                />
                              </FormGroup>
                            ))}
                          </Col>
                        </Row>
                      </ModalBody>
                      <ModalFooter>
                        <Button variant="secondary" onClick={handleClose}>
                          Cancel
                        </Button>
                        {/* <Button color="primary" onClick={handleSave}>
                                    Save
                                  </Button> */}
                        <Button
                          color="primary"
                          onClick={() => handleMappingSubmit()}
                        >
                          Submit
                        </Button>
                      </ModalFooter>
                    </Modal>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md="4" sm="4">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="example1cols1Input"
                        style={{ fontWeight: 700 }}
                      >
                        Imported By
                      </label>
                      <div>{allData?.uploaded_by}</div>
                    </FormGroup>
                  </Col>
                  <Col md="4" sm="4">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="example1cols1Input"
                        style={{ fontWeight: 700 }}
                      >
                        Status
                      </label>
                      <div>{allData?.processing_status}</div>
                    </FormGroup>
                  </Col>

                  <Col md="4" sm="4">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="example3cols1Input"
                        style={{ fontWeight: 700 }}
                      >
                        Imported On
                      </label>
                      <div>
                        {moment(allData?.created_at).format("MM/DD/YYYY")}
                      </div>
                    </FormGroup>
                  </Col>
                  <Col md="4" sm="4">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="example3cols1Input"
                        style={{ fontWeight: 700 }}
                      >
                        Transaction Id
                      </label>
                      <div>{allData?.id}</div>
                    </FormGroup>
                  </Col>
                  <Col md="4" sm="4">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="example3cols1Input"
                        style={{ fontWeight: 700 }}
                      >
                        File Name
                      </label>
                      <div>{allData?.file_name}</div>
                    </FormGroup>
                  </Col>
                  <Col md="4" sm="4">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="example3cols1Input"
                        style={{ fontWeight: 700 }}
                      >
                        Download File
                      </label>
                      <div>
                        {item.uploaded_s3_url !== "" && (
                          <a
                            href={item.uploaded_s3_url}
                            download="MyExampleDoc"
                            target="_blank"
                          >
                            <Button
                              className="buttons-copy buttons-html5 btn-link"
                              // color="default"
                              size="sm"
                            >
                              <span>
                                <i class="fas fa-download"></i>
                              </span>
                            </Button>
                          </a>
                        )}
                      </div>
                    </FormGroup>
                  </Col>
                  {allData?.reason ? (
                    <Col md="4" sm="4">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="example1cols1Input"
                          style={{ fontWeight: 700 }}
                        >
                          Reason
                        </label>
                        <div>{allData?.reason}</div>
                      </FormGroup>
                    </Col>
                  ) : null}
                  {/* </div> */}
                </Row>
                {allData?.processing_status !== "Mapping pending" &&
                importFileData !== undefined ? (
                  // && allData?.approval_status !== "Pending For Approval"
                  <>
                    <Row>
                      {" "}
                      <Col md="6">
                        <FormGroup>
                          <Label style={{ fontWeight: 700 }}>File Data</Label>
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      {" "}
                      <Col lg="6">
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            // marginLeft: "20px",
                            marginBottom: "30px",
                          }}
                        >
                          {
                            // importFileData?.suggestionCount > "0" &&
                            // allData.approval_status === "Upload in progress" &&
                            // allData.approval_status === "Rejected" &&
                            // allData.approval_status === "N/A"
                            allData.approval_status === "Approved" ||
                            allData.approval_status === "Rejected" ||
                            allData.approval_status === "N/A" ? (
                              <label className="check">
                                <input
                                  disabled
                                  type="checkbox"
                                  checked={!suggestionsDataChecked}
                                  // onChange={() =>
                                  //   setSuggestionsDataChecked(
                                  //     suggestionsDataChecked
                                  //   )
                                  // }
                                />
                                <span className="checkmark"></span>
                              </label>
                            ) : (
                              <label className="check">
                                <input
                                  type="checkbox"
                                  disabled
                                  checked={suggestionsDataChecked}
                                />
                                <span className="checkmark"></span>
                              </label>
                            )
                          }
                          <Col lg="6">
                            <label style={{ marginBottom: "0" }}>
                              Product Suggestions
                            </label>
                          </Col>
                          <Col lg="2">
                            <span>{importFileData?.suggestionCount}</span>
                          </Col>
                          <Col
                            lg="4"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "50px",
                            }}
                          >
                            {importFileData?.suggestionCount > "0" &&
                            allData.processing_status !==
                              "Upload in progress" &&
                            allData.processing_status !== "Rejected" &&
                            allData.processing_status !== "Completed" ? (
                              <>
                                <Link
                                  style={{ fontSize: "15px" }}
                                  to={{
                                    pathname: `/admin/inventory/product-suggestion/${dataItem.id}`,
                                    state: {
                                      item: allData,
                                      permission: "edit",
                                      selectedKey: "Product Suggestion",
                                    },
                                  }}
                                >
                                  View
                                </Link>
                                {importFileData?.systemMappedCount > "0" &&
                                  importFileData?.suggestionCount > "0" &&
                                  allData.processing_status !==
                                    "Upload in progress" &&
                                  allData.processing_status !== "Rejected" &&
                                  allData.processing_status !== "Completed" && (
                                    <>
                                      <CustomInput
                                        type="switch"
                                        id="suggestionsDataSwitch"
                                        name="suggestionsDataSwitch"
                                        checked={suggestionsDataChecked}
                                        onChange={() =>
                                          setSuggestionsDataChecked(
                                            !suggestionsDataChecked
                                          )
                                        }
                                        className="custom-switch"
                                      />
                                      <span style={{ marginLeft: "-50px" }}>
                                        {suggestionsDataChecked
                                          ? "Skipped"
                                          : "Skip"}
                                      </span>
                                    </>
                                  )}
                              </>
                            ) : null}
                          </Col>
                        </div>
                      </Col>
                    </Row>

                    {importFileData?.manuallyUpdatedCount > "0" && (
                      <Row>
                        {" "}
                        <Col lg="6">
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              // marginLeft: "20px",
                              marginBottom: "30px",
                            }}
                          >
                            {importFileData?.manuallyUpdatedCount ? (
                              <label className="check">
                                <input
                                  disabled
                                  type="checkbox"
                                  checked={manuallyDataChecked}
                                  // onChange={() =>
                                  //   setManuallyDataChecked(manuallyDataChecked)
                                  // }
                                />
                                <span className="checkmark"></span>
                              </label>
                            ) : (
                              <label className="check">
                                <input
                                  disabled
                                  type="checkbox"
                                  checked={manuallyDataChecked}
                                  // onChange={() =>
                                  //   setManuallyDataChecked(manuallyDataChecked)
                                  // }
                                />
                                <span className="checkmark"></span>
                              </label>
                            )}
                            <Col lg="6">
                              <label style={{ marginBottom: "0" }}>
                                Manually Updated
                              </label>
                            </Col>
                            <Col lg="2">
                              <span>
                                {importFileData?.manuallyUpdatedCount}
                              </span>
                            </Col>
                            <Col
                              lg="5"
                              style={{
                                display: "flex",
                                alignItems: "center",
                                // justifyContent: "center",
                                // gap: "40px",
                              }}
                            >
                              {importFileData?.manuallyUpdatedCount > "0" ? (
                                <Link
                                  style={{ fontSize: "15px" }}
                                  to={{
                                    pathname: `/admin/inventory/manually-updated/${dataItem.id}`,
                                    state: {
                                      item: allData,
                                      permission: "edit",
                                      selectedKey: "Manually Updated",
                                    },
                                  }}
                                >
                                  View
                                </Link>
                              ) : null}
                            </Col>
                          </div>
                        </Col>
                      </Row>
                    )}

                    <Row>
                      {" "}
                      <Col lg="6">
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            // marginLeft: "20px",
                            marginBottom: "30px",
                          }}
                        >
                          {importFileData?.systemMappedCount ? (
                            <label className="check">
                              <input
                                disabled
                                type="checkbox"
                                checked={systemMappedChecked}
                                // onChange={() =>
                                //   setSystemMappedChecked(systemMappedChecked)
                                // }
                              />
                              <span className="checkmark"></span>
                            </label>
                          ) : (
                            <label className="check">
                              <input
                                disabled
                                type="checkbox"
                                checked={systemMappedChecked}
                                // onChange={() =>
                                //   setSystemMappedChecked(systemMappedChecked)
                                // }
                              />
                              <span className="checkmark"></span>
                            </label>
                          )}
                          <Col lg="6">
                            <label style={{ marginBottom: "0" }}>
                              Mapped Successfully
                            </label>
                          </Col>
                          <Col lg="2">
                            <span>{importFileData?.systemMappedCount}</span>
                          </Col>
                          <Col
                            lg="5"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              // justifyContent: "center",
                              // gap: "40px",
                            }}
                          >
                            {importFileData?.systemMappedCount > "0" ? (
                              <Link
                                style={{ fontSize: "15px" }}
                                to={{
                                  pathname: `/admin/inventory/view/${dataItem.id}`,
                                  state: {
                                    item: allData,
                                    permission: "edit",
                                    selectedKey: "System Mapped",
                                  },
                                }}
                              >
                                View
                              </Link>
                            ) : null}
                          </Col>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      {" "}
                      <Col lg="6">
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginBottom: "30px",
                          }}
                        >
                          {
                            // importFileData?.invalidDataCount > "0" &&
                            // allData.processing_status !== "Upload in progress" &&
                            // allData.processing_status !== "Rejected" &&
                            // allData.processing_status !== "Completed"
                            allData.approval_status === "Approved" ||
                            allData.approval_status === "Rejected" ||
                            allData.approval_status === "N/A" ? (
                              <label className="check">
                                <input
                                  disabled
                                  type="checkbox"
                                  checked={!invalidDataChecked}
                                  // onChange={() =>
                                  //   setInvalidDataChecked(!invalidDataChecked)
                                  // }
                                />
                                <span className="checkmark"></span>
                              </label>
                            ) : (
                              <label className="check">
                                <input
                                  type="checkbox"
                                  disabled
                                  checked={invalidDataChecked}
                                />
                                <span className="checkmark"></span>
                              </label>
                            )
                          }

                          <Col lg="6">
                            <label style={{ marginBottom: "0" }}>
                              Invalid Data
                            </label>
                          </Col>
                          <Col lg="2">
                            <span>{importFileData?.invalidDataCount}</span>
                          </Col>
                          <Col
                            lg="4"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "50px",
                            }}
                          >
                            {importFileData?.invalidDataCount > "0" ? (
                              <>
                                <Link
                                  style={{ fontSize: "15px" }}
                                  to={{
                                    pathname: `/admin/inventory/view/${dataItem.id}`,
                                    state: {
                                      item: allData,
                                      permission: "edit",
                                      selectedKey: "Invalid input",
                                    },
                                  }}
                                >
                                  View
                                </Link>
                                {importFileData?.systemMappedCount > "0" &&
                                  importFileData?.invalidDataCount > "0" &&
                                  allData.processing_status !==
                                    "Upload in progress" &&
                                  allData.processing_status !== "Rejected" &&
                                  allData.processing_status !== "Completed" && (
                                    <>
                                      <CustomInput
                                        type="switch"
                                        id="invalidDataSwitch"
                                        name="invalidDataSwitch"
                                        checked={invalidDataChecked}
                                        onChange={() =>
                                          setInvalidDataChecked(
                                            !invalidDataChecked
                                          )
                                        }
                                        className="custom-switch"
                                      />
                                      <span style={{ marginLeft: "-50px" }}>
                                        {invalidDataChecked
                                          ? "Skipped"
                                          : "Skip"}
                                      </span>
                                    </>
                                  )}
                              </>
                            ) : null}
                          </Col>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      {" "}
                      <Col lg="6">
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            // marginLeft: "20px",
                            marginBottom: "30px",
                          }}
                        >
                          {
                            // importFileData?.productNotFoundCount > "0" &&
                            // allData.processing_status !== "Upload in progress" &&
                            // allData.processing_status !== "Rejected" &&
                            // allData.processing_status !== "Completed"
                            allData.approval_status === "Approved" ||
                            allData.approval_status === "Rejected" ||
                            allData.approval_status === "N/A" ? (
                              <label className="check">
                                <input
                                  disabled
                                  type="checkbox"
                                  checked={!notFoundChecked}
                                  // onChange={() =>
                                  //   setNotFoundChecked(!notFoundChecked)
                                  // }
                                />
                                <span className="checkmark"></span>
                              </label>
                            ) : (
                              <label className="check">
                                <input
                                  disabled
                                  type="checkbox"
                                  checked={notFoundChecked}
                                />
                                <span className="checkmark"></span>
                              </label>
                            )
                          }
                          <Col lg="6">
                            <label style={{ marginBottom: "0" }}>
                              Not found
                            </label>
                          </Col>
                          <Col lg="2">
                            <span>{importFileData?.productNotFoundCount}</span>
                          </Col>
                          <Col
                            lg="4"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              // justifyContent: "end",
                              gap: "50px",
                            }}
                          >
                            {importFileData?.productNotFoundCount > "0" ? (
                              <>
                                <Link
                                  style={{ fontSize: "15px" }}
                                  to={{
                                    pathname: `/admin/inventory/view/${dataItem.id}`,
                                    state: {
                                      item: allData,
                                      permission: "edit",
                                      selectedKey: "Not Found",
                                    },
                                  }}
                                >
                                  View
                                </Link>
                                {importFileData?.systemMappedCount > "0" &&
                                  importFileData?.productNotFoundCount > "0" &&
                                  allData.processing_status !==
                                    "Upload in progress" &&
                                  allData.processing_status !== "Rejected" &&
                                  allData.processing_status !== "Completed" && (
                                    <>
                                      <CustomInput
                                        type="switch"
                                        id="notFoundSwitch"
                                        name="notFoundSwitch"
                                        checked={notFoundChecked}
                                        onChange={() =>
                                          setNotFoundChecked(!notFoundChecked)
                                        }
                                      />
                                      <span style={{ marginLeft: "-50px" }}>
                                        {notFoundChecked ? "Skipped" : "Skip"}
                                      </span>
                                    </>
                                  )}
                              </>
                            ) : null}
                          </Col>
                        </div>
                      </Col>
                    </Row>

                    {importFileData?.systemMappedCount > "0" &&
                    allData.processing_status !== "Upload in progress" &&
                    allData.processing_status !== "Rejected" &&
                    allData.processing_status !== "Completed" ? (
                      <Row>
                        <Col md="6">
                          <Button
                            color="secondary"
                            className="float-right"
                            type="button"
                            size="sm"
                            onClick={() => modalToggle()}
                          >
                            Reject
                          </Button>
                          <Button
                            color="primary"
                            className="float-right"
                            type="button"
                            size="sm"
                            onClick={() => confirmImportToggle()}
                            disabled={
                              !(
                                systemMappedChecked &&
                                invalidDataChecked &&
                                notFoundChecked &&
                                suggestionsDataChecked
                              )
                            }
                          >
                            Approve & Import
                          </Button>
                        </Col>

                        {/* confirm button modal */}
                        <Modal
                          isOpen={importModal}
                          fade={false}
                          toggle={confirmImportToggle}
                          size="sm"
                          centered
                          backdrop="static"
                        >
                          <ModalBody>
                            <span>
                              Are you sure you want to approve & import?
                            </span>
                          </ModalBody>
                          <ModalFooter>
                            <Button
                              color="primary"
                              // onClick={() => handleImportData()}
                              onClick={() => confirmAlertToggle()}
                            >
                              Submit
                            </Button>
                            <Button
                              color="secondary"
                              onClick={() => {
                                confirmImportToggle();
                              }}
                            >
                              Cancel
                            </Button>
                          </ModalFooter>
                        </Modal>

                        {/* after submit modal */}

                        <Modal
                          isOpen={importalert}
                          fade={false}
                          toggle={confirmAlertToggle}
                          size="sm"
                          centered
                          backdrop="static"
                        >
                          <ModalHeader toggle={confirmAlertToggle}>
                            <div>Note</div>
                            <br />
                            <div style={{ fontWeight: 500 }}>
                              Import may take sometime
                            </div>
                          </ModalHeader>

                          <ModalFooter>
                            <Button
                              color="primary"
                              onClick={() => handleImportData()}
                            >
                              Okay
                            </Button>
                          </ModalFooter>
                        </Modal>

                        {/* reject button modal */}

                        <Modal
                          isOpen={modal}
                          fade={false}
                          toggle={modalToggle}
                          backdrop="static"
                          centered
                        >
                          <ModalHeader toggle={modalToggle}>
                            Reason For Rejection
                          </ModalHeader>
                          <ModalBody>
                            <label>Reason</label>
                            <Input
                              type="text"
                              name="reason"
                              value={reason}
                              onChange={(e) => setReason(e.target.value)}
                            />
                          </ModalBody>
                          <ModalFooter>
                            <Button
                              color="primary"
                              // onClick={() => reasonModalToggle()}
                              onClick={() => hanldeUpdateApproval()}
                              disabled={!reason ? true : false}
                            >
                              Submit
                            </Button>
                            <Button
                              color="secondary"
                              onClick={() => {
                                setReason();
                                modalToggle();
                                // updateAprrovalStatus("pending");
                              }}
                            >
                              Cancel
                            </Button>
                          </ModalFooter>
                        </Modal>
                      </Row>
                    ) : null}
                  </>
                ) : null}
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
      <Backdrop className={classes.backdrop} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default InventoryFileEdit;
