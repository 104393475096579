import React, { useState, useEffect } from "react";

import Multiselect from "multiselect-react-dropdown";
import Header from "../../ui/Header.js";
import { Formik } from "formik";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logUserMetrics } from "../../../utils/file.js";
import DatePicker from "react-datepicker";
import {
  Form,
  FormGroup,
  Input,
  Card,
  CardHeader,
  CardFooter,
  Container,
  Button,
  Col,
  CardBody,
  UncontrolledAlert,
  UncontrolledTooltip,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  CardGroup,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import { XSquare } from "react-bootstrap-icons";
import FullPageLoader from "../../../components/FullPageLoader";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import { setHours, setMinutes } from "date-fns";
import moment from "moment";
import styles from "../../styles/showTenant.module.css";
import Loader from "../../../onboarding/components/Loader.js";
import { toast } from "react-toastify";
import { ApiUrls } from "../../../common/apiconstant";
import { ApiEndpoints } from "../../constants/constant.js";
import { useMediaQuery } from "@material-ui/core";
import { Switch } from "antd";
import { TagsInput } from "react-tag-input-component";
import { values } from "lodash";
const baseAWSAPIURL = require("../../config").baseAWSAPIURL;

const profileImgUUid = uuidv4();

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));
function EmployeesProfileEdit() {
  const classes = useStyles();

  const location = useLocation();

  // const { permission, actions ,id} = location?.state.item;

  // console.log("Location Permission to show==>",location.state.item);
  // console.log("39393939393===.",permission);
  // console.log("actions===>",actions);
  const [originalData, setOriginalData] = useState({});
  const [modifiedNonFormData, setModifiedNonFormData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [image, setImage] = useState({ preview: "", raw: "" });
  // const [options, setOptions] =useState(actions);
  const [toggle, setToggle] = useState(false);
  console.log("ORORORORO===>", originalData);
  const [endDate, setEndDate] = useState(new Date());
  const [avatarKey, setAvatarKey] = useState("2023-04-16T13:48:04.772Z");
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [subCategoryDetail, setSubCategoryDetail] = useState([]);
  const auth = useSelector((state) => state.auth);
  const [modal, setModal] = useState(false);
  let { emailId } = useParams();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const moreToggle = () => setDropdownOpen((prevState) => !prevState);

  const isMobile = useMediaQuery("(max-width: 500px)");

  console.log("emailId", emailId);
  const history = useHistory();

  const getTenantDetail = () => {
    setIsLoading(true);

    let getTenantDetailApi = `${ApiEndpoints.viewtenantDetail}?email=${emailId}`;
    fetch(getTenantDetailApi)
      .then((response) => response.json())
      .then((res) => {
        setIsLoading(false);
        console.log(res);
        const responseTenantDetail = res?.data?.customer;
        console.log("ReSpinse 57", res);
        setOriginalData(responseTenantDetail);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log("989889898", err);
      });
  };
  // const update = async () => {
  //     console.log("Datattta===>",originalData.permission);
  //     // if (window.confirm('Are You Sure You want to edit permission')) {
  //         setIsLoading(true);
  //         let name = localStorage.getItem("fullname");
  //         let sendToApi = JSON.stringify({
  //             "id":id,
  //             "permission": originalData.permission,
  //             "actions": options,
  //             "updated_by": name
  //         });
  //         console.log("sendToApisendToApi==>",sendToApi);
  //         fetch(
  //             ApiEndpoints.permissionGetURL,
  //             {
  //                 method: 'PUT',
  //                 headers: {
  //                     'Accept': 'application/json',
  //                     'Content-Type': 'application/json',
  //                 },
  //                 body: sendToApi
  //             }
  //         )
  //             .then((response) => response.json())
  //             .then((res) => {
  //                 console.log('data', res);
  //                 if (res.data !== "") {
  //                     setIsLoading(false);
  //                     // setSubmitting(false);
  //                     handleView();
  //                 }
  //                 else {
  //                     setIsLoading(false);
  //                     setModal(true)
  //                 }
  //             })
  //             .catch((err) => {
  //                 console.log(err);
  //                 handleView();
  //             });

  // }
  const modalToggle = () => setModal(!modal);

  const handleView = () => {
    history.goBack();
  };
  useEffect(() => {
    getTenantDetail();
  }, []);

  const handleChangeImage = async (e, fieldName) => {
    let toastId = toast.info("Avatar uploading is in processing", {
      autoClose: false,
    });
    var newData = { ...modifiedNonFormData };
    setButtonDisabled(true);
    if (e.target.files.length) {
      let selectedFile = e.target.files[0];
      let fileType = e.target.files[0].type;
      setImage({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0],
      });

      let preSingnedUrl = ApiUrls.avatarUploadPresigned;

      try {
        let preSignedRes = await axios.get(
          `${preSingnedUrl}?contentType=${fileType}`
        );
        console.log("D", preSignedRes);
        if (preSignedRes.status === 200) {
          let url = preSignedRes?.data?.data?.url;
          let ukey = preSignedRes?.data?.data?.key;
          let res = await uploadFile(url, selectedFile);
          console.log("RES1", res);
          console.log("ULR", url, ukey);
          if (res.status === 200) {
            setButtonDisabled(false);
            setAvatarKey(ukey);
            newData[fieldName] = ukey;
            setModifiedNonFormData(newData);
            console.log("MODI", modifiedNonFormData);
            console.log("DATa", newData);
            let d = JSON.parse(JSON.stringify(originalData));

            let arr = d?.logo.split("/");
            arr[arr.length - 1] = ukey;
            let newUrl = arr.join("/");
            d.logo = newUrl;
            setOriginalData(d);
            toast.dismiss(toastId);
            toast.success("Image upload successfully");
          }
        }
      } catch (error) {
        setButtonDisabled(false);
        console.error(error);
      }
    } else {
      setButtonDisabled(false);
      newData[fieldName] = false;
      setModifiedNonFormData(newData);
    }
  };

  const handleRemoveImage = () => {
    let d = JSON.parse(JSON.stringify(originalData));

    let arr = d?.logo.split("/");
    console.log("arrshjvfhsd", arr);
    if (arr[arr.length - 1] == "2023-04-16T13:48:04.772Z") {
      // toast.info("Please Upload Image")
      return;
    }
    arr[arr.length - 1] = "2023-04-16T13:48:04.772Z";
    let newUrl = arr.join("/");
    d.logo = newUrl;
    setOriginalData(d);
    setImage({ preview: "", raw: "" });
  };

  const uploadFile = (url, data) => {
    console.log("UPLOAD");
    let promise = new Promise((resolve, reject) => {
      "in xhr";
      const xhr = new XMLHttpRequest();
      xhr.open("PUT", url);
      //xhr.responseType = "json";
      //xhr.setRequestHeader("X-Requested-With", "XMLHttpRequest");
      //xhr.setRequestHeader("Access-Control-Allow-Origin", "*");
      xhr.onload = () => {
        if (xhr.status >= 400) {
          console.log("REJECTED");
          reject({ status: 400, data: xhr.response });
        } else {
          console.log("RESOLVED");
          resolve({ status: 200, data: xhr.response });
        }
      };

      xhr.onerror = (e) => {
        console.log("RJECTED");
        reject({
          status: 400,
          data: xhr.response,
          msg: "Something went wrong",
        });
      };

      xhr.send(data);
    });

    return promise;
  };
  // const handlePermissionChange = (e) => {
  //     console.log("126126126126==>",e.target.value);
  //     let permission = JSON.parse(JSON.stringify(originalData));
  //     permission.permission = e.target.value;

  //         setOriginalData(permission)
  // };

  const editTenant = async (data, setSubmitting) => {
    setIsLoading(true);
    // if (avatarKey) {
    //   values.logo = avatarKey;
    // }

    console.log("VALUES", data);

    let reqBody = {
      id: data.id,
      business_name: data.business_name,
      business_email: data.email,
      business_phone: data.phone,
      contact_person_firstname: data.contact_person_firstname,
      contact_person_lastname: data.contact_person_lastname,
      contact_person_email: data.contact_person_email,
      contact_person_phone: data.contact_person_phone,
      logo: avatarKey,
      billing_address1: data.billing_address1,
      billing_address2: data.billing_address2,
      billing_city: data.billing_city,
      billing_state: data.billing_state,
      billing_zip_code: data.billing_zip_code,
      type: "Tenant",
      updated_by: localStorage.getItem("fullname"),
    };

    // reqBody.locations = [];
    reqBody.roles = [];
    // reqBody.email = "default@gmai.com";
    // reqBody.firstname = "test";
    // reqBody.lastname = "lst";
    // reqBody.timezone = "psd";

    // if (data.ischecked) {
    //   reqBody.firstname = data.contactpersonfirstname;
    //   reqBody.lastname = data.contactpersonlastname;
    //   reqBody.email = data.contactpersonemail;
    //   reqBody.phone = data.contactpersonphone;
    //   reqBody.timezone = "PDT";
    //   reqBody.is_verified = true;
    // } else {
    //   reqBody.firstname = data.firstname;
    //   reqBody.lastname = data.lastname;
    //   reqBody.email = data.email;
    //   reqBody.phone = data.phone;
    //   reqBody.timezone = data.timezone;
    //   reqBody.is_verified = data.is_verified;
    // }

    // if (data.is_verified === true) {
    //   reqBody.is_verified = true;
    // } else {
    //   reqBody.is_verified = false;
    // }
    // if (avatarKey) {
    //   reqBody.avatar = avatarKey;
    // }

    if (!data.logo) {
      reqBody.logo = avatarKey;
    } else {
      let arr = originalData.logo.split("/");
      let uKey = arr[arr.length - 1];
      reqBody.logo = uKey;
    }

    try {
      let res = await axios.put(ApiEndpoints.tenantApi, { ...reqBody });
      console.log("RESPONSE", res);
      if (res.status === 200) {
        setImage({ preview: "", raw: "" });
        setSubmitting(false);
        setIsLoading(false);
        toast.success("Tenant edited successfully");
        let action = "tenant-edit";
        let type = "Info";
        let message = "Tenant successfully edited.";
        const apiResponse = await logUserMetrics(action, type, message);
        console.log("apiirresponse", apiResponse);
        history.push("/admin/tenant");
      }
    } catch (error) {
      console.error(error);
      setSubmitting(false);
      setIsLoading(false);
      let action = "tenant-edit";
      let type = "Error";
      let message = "Error in Tenant edit.";
      const apiResponse = await logUserMetrics(action, type, message);
      console.log("apiirresponse", apiResponse);
    }
  };

  const handleImageUpload = (event, fieldName) => {
    let selectedfiles = "";
    var newData = { ...modifiedNonFormData };
    const imageFile = event.target.files;
    setButtonDisabled(true);
    if (imageFile.length > 0) {
      selectedfiles = imageFile[0];

      const imagesKays = '[{ "key": "' + fieldName + profileImgUUid + '"}]';

      fetch(`${ApiUrls.imageUpload}`, {
        method: "POST",
        body: imagesKays,
      })
        .then((response) => response.json())
        .then((response) => {
          //console.log(response);
          const presignedUrl = response[0].presignedUrl;
          console.log(presignedUrl);

          fetch(presignedUrl, {
            method: "PUT", // *GET, POST, PUT, DELETE, etc.
            body: selectedfiles,
          })
            .then((res) => {
              setButtonDisabled(false);
              newData[fieldName] = fieldName + profileImgUUid;
              setModifiedNonFormData(newData);
            })
            .catch((error) => {
              console.log(error);
            });
        })
        .catch((error) => {
          setButtonDisabled(false);
          console.error(error);
        });
    } else {
      setButtonDisabled(false);
      newData[fieldName] = false;
      setModifiedNonFormData(newData);
    }
  };

  if (isLoading) {
    return (
      <>
        <FullPageLoader />
      </>
    );
  }

  const openModalCheck = () => {
    modalToggle();
  };

  return (
    <>
      <Modal
        isOpen={modal}
        fade={false}
        toggle={modalToggle}
        size="sm"
        centered
      >
        <ModalHeader toggle={modalToggle}>Edit Tenant Details</ModalHeader>
      </Modal>
      <Container fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader>
                <Row
                  className="align-items-center"
                  // style={isMobile ? { fontSize: "13px" } : null}
                  // className={styles.header}
                >
                  <Col lg="6" xs="7" className={styles.column}>
                    <span
                      className="back-btn"
                      style={{ cursor: "pointer" }}
                      onClick={() => history.goBack()}
                    >
                      <i class="fa fa-arrow-left " aria-hidden="true"></i>
                    </span>
                    <h6
                      className="fullcalendar-title h2 d-inline-block mb-0"
                      style={{ marginLeft: "10px" }}
                    >
                      {"Edit Tenant Details"}
                    </h6>
                  </Col>
                  <Col
                    // className="mt-3 mt-md-0 text-md-right"
                    className={styles.buttons}
                    lg="6"
                    xs="5"
                    style={
                      !isMobile
                        ? { display: "flex", justifyContent: "end" }
                        : {}
                    }
                  >
                    <Dropdown
                      isOpen={dropdownOpen}
                      toggle={moreToggle}
                      className={styles.orderOpitons}
                    >
                      <DropdownToggle caret size="sm">
                        Actions
                      </DropdownToggle>
                      <DropdownMenu className={styles.orderDropDownOptions}>
                        <DropdownItem
                          size="sm"
                          onClick={() => history.push("/admin/locations")}
                          className={styles.orderDeatilsButton}
                        >
                          Locations
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => history.push("/admin/users")}
                          className={styles.orderDeatilsButton}
                        >
                          User
                        </DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                    {/* <Button
                      color="default"
                      size="sm"
                      // className="ml-2"
                      onClick={() => history.push("/admin/locations")}
                    >
                      Location
                    </Button>
                    <Button
                      color="default"
                      size="sm"
                      // className="ml-2"
                      onClick={() => history.push("/admin/users")}
                    >
                      User
                    </Button> */}
                    {/* <Button
                      color="primary"
                      onClick={() => handleView()}
                      size="sm"
                    >
                      Back To All Tenants
                    </Button> */}
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                {/* {successMsg != "" && (
                                    <UncontrolledAlert color="success">
                                        <span className="alert-text ml-1">
                                            {successMsg}
                                        </span>
                                    </UncontrolledAlert>
                                )} */}
                {/* {errorMsg != "" && (
                                    <UncontrolledAlert color="danger">
                                        <span className="alert-text ml-1">
                                            {errorMsg.map(itemEr => (
                                                <p key={itemEr.label}>{itemEr.error}</p>
                                            ))}
                                        </span>
                                    </UncontrolledAlert>
                                )} */}

                <Formik
                  enableReinitialize
                  initialValues={originalData}
                  validate={(values) => {
                    console.log("Values", values);
                    values = { ...values };
                    const errors = {};
                    var pattern = new RegExp(/^[0-9\b]+$/);

                    if (!values.business_name) {
                      errors.business_name = "Required!";
                    }

                    if (!values.email) {
                      errors.email = "Required";
                    } else if (
                      !/^[A-Z0-9._%+'-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                        values.email
                      )
                    ) {
                      errors.email = "Invalid businessemail address";
                    }

                    if (!values.phone) {
                      errors.phone = "Required";
                    } else if (!pattern.test(values.phone)) {
                      errors.phone = "Please enter only number.";
                    } else if (values.phone.length != 10) {
                      errors.phone = "Pleaenter valid phone number.";
                    }

                    if (!values.contact_person_firstname) {
                      errors.contact_person_firstname = "Required!";
                    }

                    if (!values.contact_person_lastname) {
                      errors.contact_person_lastname = "Required!";
                    }

                    if (!values.contact_person_email) {
                      errors.contact_person_email = "Required";
                    } else if (
                      !/^[A-Z0-9._%+'-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                        values.contact_person_email
                      )
                    ) {
                      errors.contact_person_email =
                        "Invalid contactpersonemail address";
                    }

                    if (!values.contact_person_phone) {
                      errors.contact_person_phone = "Required";
                    } else if (!pattern.test(values.contact_person_phone)) {
                      errors.contact_person_phone = "Please enter only number.";
                    } else if (values.contact_person_phone.length != 10) {
                      errors.contact_person_phone =
                        "Please enter valid phone number.";
                    }

                    if (!values.billing_address1) {
                      errors.billing_address1 = "Required!";
                    }

                    if (!values.billing_address2) {
                      errors.billing_address2 = "Required!";
                    }

                    if (!values.billing_city) {
                      errors.billing_city = "Required!";
                    }

                    if (!values.billing_state) {
                      errors.billing_state = "Required!";
                    }

                    if (!values.billing_zip_code) {
                      errors.billing_zip_code = "Required!";
                    }

                    // if (!values.firstname) {
                    //   errors.firstname = "Required!";
                    // }

                    // if (!values.lastname) {
                    //   errors.lastname = "Required!";
                    // }

                    // if (!values.timezone) {
                    //   errors.timezone = "Required!";
                    // }

                    // if (!values.phone) {
                    //   errors.phone = "Required";
                    // } else if (!pattern.test(values.phone)) {
                    //   errors.phone = "Please enter only number.";
                    // } else if (values.phone.length != 10) {
                    //   errors.phone = "Please enter valid phone number.";
                    // }

                    // if (!values.email) {
                    //   errors.email = "Required";
                    // } else if (
                    //   !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                    //     values.email
                    //   )
                    // ) {
                    //   errors.email = "Invalid email address";
                    // }

                    return errors;
                  }}
                  onSubmit={(values, { setSubmitting }) => {
                    editTenant(values, setSubmitting);
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    /* and other goodies */
                  }) => (
                    <Form onSubmit={handleSubmit}>
                      <Row className="align-items-center">
                        <Col lg="6" className="text-center">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="upload-button"
                            >
                              {image.preview ? (
                                <img
                                  src={image.preview}
                                  alt="dummy"
                                  width="150"
                                  height="150"
                                  style={{ objectFit: "cover" }}
                                />
                              ) : (
                                <>
                                  {
                                    <img
                                      src={values.logo}
                                      width="150"
                                      height="150"
                                      style={{ objectFit: "cover" }}
                                    />
                                  }
                                  <h5>Upload your photo</h5>
                                </>
                              )}
                            </label>
                            {originalData?.logo?.includes(
                              "2023-04-16T13:48:04.772Z"
                            ) ? null : (
                              <button
                                onClick={handleRemoveImage}
                                style={{
                                  position: "absolute",
                                  marginTop: "-10px",
                                  marginLeft: "15px",
                                  // bottom: "60px",
                                  // left: "15px",
                                  background: "transparent",
                                  border: "none",
                                  cursor: "pointer",
                                  fontSize: "22px",
                                }}
                              >
                                <XSquare />
                              </button>
                            )}
                            <br />
                            <input
                              lang="en"
                              type="file"
                              name="logo"
                              onChange={(event) => {
                                //handleImageUpload(event, "profileImg");
                                handleChangeImage(event, "logo");
                              }}
                              //value={image.raw}
                              id="upload-button"
                              style={{ display: "none" }}
                            />
                          </FormGroup>
                        </Col>

                        <Col lg="6">
                          <FormGroup>
                            <label className="form-control-label">
                              <h3>Business Info</h3>
                              Business Name
                            </label>
                            <Input
                              className="textCapitalize"
                              placeholder="Enter Business Name"
                              type="text"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.business_name}
                              name="business_name"
                            />
                            <p className="text-danger mb-0">
                              {errors.business_name &&
                                touched.business_name &&
                                errors.business_name}
                            </p>
                          </FormGroup>
                        </Col>

                        <Col lg="6">
                          <FormGroup>
                            <label className="form-control-label">
                              Business Email
                            </label>
                            <Input
                              placeholder="Enter Email"
                              type="text"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.email}
                              name="email"
                              readOnly
                            />
                            <p className="text-danger mb-0">
                              {errors.email && touched.email && errors.email}
                            </p>
                          </FormGroup>
                        </Col>

                        <Col lg="6">
                          <FormGroup>
                            <label className="form-control-label">
                              Business Phone
                            </label>
                            <Input
                              placeholder="Enter Phone"
                              type="text"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.phone}
                              name="phone"
                            />
                            <p className="text-danger mb-0">
                              {errors.phone && touched.phone && errors.phone}
                            </p>
                          </FormGroup>
                        </Col>

                        <Col lg="12">
                          <h3>Contact Info</h3>
                        </Col>

                        <Col lg="6">
                          <FormGroup>
                            <label className="form-control-label">
                              Contact Person First Name
                            </label>
                            <Input
                              className="textCapitalize"
                              placeholder="Enter Contact Person First Name"
                              type="text"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.contact_person_firstname}
                              name="contact_person_firstname"
                            />
                            <p className="text-danger mb-0">
                              {errors.contact_person_firstname &&
                                touched.contact_person_firstname &&
                                errors.contact_person_firstname}
                            </p>
                          </FormGroup>
                        </Col>

                        <Col lg="6">
                          <FormGroup>
                            <label className="form-control-label">
                              Contact Person Last Name
                            </label>
                            <Input
                              className="textCapitalize"
                              placeholder="Enter Contact Person Last Name"
                              type="text"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.contact_person_lastname}
                              name="contact_person_lastname"
                            />
                            <p className="text-danger mb-0">
                              {errors.contact_person_lastname &&
                                touched.contact_person_lastname &&
                                errors.contact_person_lastname}
                            </p>
                          </FormGroup>
                        </Col>

                        <Col lg="6">
                          <FormGroup>
                            <label className="form-control-label">
                              Contact Person Email
                            </label>
                            <Input
                              placeholder="Enter Contact Person Email"
                              type="text"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.contact_person_email}
                              name="contact_person_email"
                              // readOnly
                            />
                            <p className="text-danger mb-0">
                              {errors.contact_person_email &&
                                touched.contact_person_email &&
                                errors.contact_person_email}
                            </p>
                          </FormGroup>
                        </Col>

                        <Col lg="6">
                          <FormGroup>
                            <label className="form-control-label">
                              Contact Person Phone
                            </label>
                            <Input
                              placeholder="Enter Contact Person Phone"
                              type="text"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.contact_person_phone}
                              name="contact_person_phone"
                            />
                            <p className="text-danger mb-0">
                              {errors.contact_person_phone &&
                                touched.contact_person_phone &&
                                errors.contact_person_phone}
                            </p>
                          </FormGroup>
                        </Col>

                        <Col lg="12">
                          <h3>Address</h3>
                        </Col>

                        {/* <Col lg="6">
                          <FormGroup>
                            <label className="form-control-label">
                              Location
                            </label> */}
                        {/* <MultiSelect
                                onChange={setSelectedLocations}
                                value={selectedLocations}
                                options={options}
                                labelledBy="Select"
                              />
                              <p className="ErrorMessage">
                                {errors.locations &&
                                  touched.locations &&
                                  errors.locations}
                              </p> */}
                        {/* </FormGroup>
                          </Col> */}

                        <Col lg="6">
                          <FormGroup>
                            <label className="form-control-label">
                              Billing Address 1
                            </label>
                            <Input
                              className="textCapitalize"
                              placeholder="Enter Billing Address 1"
                              type="text"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.billing_address1}
                              name="billing_address1"
                            />
                            <p className="text-danger mb-0">
                              {errors.billing_address1 &&
                                touched.billing_address1 &&
                                errors.billing_address1}
                            </p>
                          </FormGroup>
                        </Col>

                        <Col lg="6">
                          <FormGroup>
                            <label className="form-control-label">
                              Billing Address 2
                            </label>
                            <Input
                              className="textCapitalize"
                              placeholder="Enter Billing Address 2"
                              type="text"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.billing_address2}
                              name="billing_address2"
                            />
                            <p className="text-danger mb-0">
                              {errors.billing_address2 &&
                                touched.billing_address2 &&
                                errors.billing_address2}
                            </p>
                          </FormGroup>
                        </Col>

                        <Col lg="6">
                          <FormGroup>
                            <label className="form-control-label">
                              Billing City
                            </label>
                            <Input
                              className="textCapitalize"
                              placeholder="Enter Billing City"
                              type="text"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.billing_city}
                              name="billing_city"
                            />
                            <p className="text-danger mb-0">
                              {errors.billing_city &&
                                touched.billing_city &&
                                errors.billing_city}
                            </p>
                          </FormGroup>
                        </Col>

                        <Col lg="6">
                          <FormGroup>
                            <label className="form-control-label">
                              Billing State
                            </label>
                            <Input
                              className="textCapitalize"
                              placeholder="Enter Billing State"
                              type="text"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.billing_state}
                              name="billing_state"
                            />
                            <p className="text-danger mb-0">
                              {errors.billing_state &&
                                touched.billing_state &&
                                errors.billing_state}
                            </p>
                          </FormGroup>
                        </Col>

                        <Col lg="6">
                          <FormGroup>
                            <label className="form-control-label">
                              Billing Zip Code
                            </label>
                            <Input
                              placeholder="Enter Billing Zip Code"
                              type="text"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.billing_zip_code}
                              name="billing_zip_code"
                            />
                            <p className="text-danger mb-0">
                              {errors.billing_state &&
                                touched.billing_state &&
                                errors.billing_state}
                            </p>
                          </FormGroup>
                        </Col>

                        {/* <Col lg="6">
                          <FormGroup>
                            <label className="form-control-label">
                              Is Active
                            </label>
                            <Input
                              type="select"
                              name="is_active"
                              onChange={handleChange}
                              onBlur={handleBlur}
                            >
                              <option value="">Select Is Active</option>
                              <option value="yes">Yes</option>
                              <option value="no">No</option>
                            </Input>
                            <p className="ErrorMessage">
                              {errors.is_active &&
                                touched.is_active &&
                                errors.is_active}
                            </p>
                          </FormGroup>
                        </Col> */}

                        <Col lg="6"></Col>

                        <Col>
                          <FormGroup>
                            <Button
                              color="primary"
                              type="submit"
                              disabled={isSubmitting}
                              className="d-block"
                            >
                              Update
                            </Button>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Form>
                  )}
                </Formik>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
      <Loader isLoading={isLoading} />
    </>
  );
}
export default EmployeesProfileEdit;
