// const constantVariable = {

// };

export let ApiEndpoints = {
  updateOrderState:
    "https://bhagt8zwld.execute-api.us-east-1.amazonaws.com/dev/v1/webhook",
  getOrderState:
    "https://9cvdtd4105.execute-api.us-east-1.amazonaws.com/prod/v1/get-order-state-dev/",
  locationList:
    // "https://bjwjn3w8gl.execute-api.us-east-1.amazonaws.com/dev/v1/location/getAll",
    "https://bjwjn3w8gl.execute-api.us-east-1.amazonaws.com/dev/v1/get-location",
  // "https://4duv8k145h.execute-api.us-east-1.amazonaws.com/prod/v1/get-location",
  locationSearch:
    // "https://bjwjn3w8gl.execute-api.us-east-1.amazonaws.com/dev/v1/location/getAll",
    // "https://bjwjn3w8gl.execute-api.us-east-1.amazonaws.com/dev/v1/search",
    "https://bjwjn3w8gl.execute-api.us-east-1.amazonaws.com/dev/v2/search",
  // "https://4duv8k145h.execute-api.us-east-1.amazonaws.com/prod/v1/search",
  // "https://4duv8k145h.execute-api.us-east-1.amazonaws.com/prod/v2/search",
  locationAdd:
    // "https://bjwjn3w8gl.execute-api.us-east-1.amazonaws.com/dev/v1/location",
    // "https://bjwjn3w8gl.execute-api.us-east-1.amazonaws.com/dev/v1/create-location",
    "https://bjwjn3w8gl.execute-api.us-east-1.amazonaws.com/dev/v2/locations",
  // "https://4duv8k145h.execute-api.us-east-1.amazonaws.com/prod/v1/create-location",
  locationUpdate:
    //"https://bjwjn3w8gl.execute-api.us-east-1.amazonaws.com/dev/v1/location/",
    // "https://bjwjn3w8gl.execute-api.us-east-1.amazonaws.com/dev/v1/update-location",
    "https://bjwjn3w8gl.execute-api.us-east-1.amazonaws.com/dev/v2/locations",
  // "https://4duv8k145h.execute-api.us-east-1.amazonaws.com/prod/v1/update-location",
  locationGetById:
    // "https://bjwjn3w8gl.execute-api.us-east-1.amazonaws.com/dev/v1/location/",
    "https://bjwjn3w8gl.execute-api.us-east-1.amazonaws.com/dev/v1/get-location-by-id",
  // "https://4duv8k145h.execute-api.us-east-1.amazonaws.com/prod/v1/get-location-by-id",
  getLocationByLocationId:
    "https://bjwjn3w8gl.execute-api.us-east-1.amazonaws.com/dev/v1/get-location-by-Location-Id",
  // "https://4duv8k145h.execute-api.us-east-1.amazonaws.com/prod/v1/get-location-by-Location-Id",
  shopifyLocationId:
    "https://bjwjn3w8gl.execute-api.us-east-1.amazonaws.com/dev/v1/update-shopify-location-id",
  // "https://4duv8k145h.execute-api.us-east-1.amazonaws.com/prod/v1/update-shopify-location-id",
  locationDelete:
    // "https://bjwjn3w8gl.execute-api.us-east-1.amazonaws.com/dev/v1/location/",
    "https://bjwjn3w8gl.execute-api.us-east-1.amazonaws.com/dev/v1/delete-location",
  // "https://4duv8k145h.execute-api.us-east-1.amazonaws.com/prod/v1/delete-location",
  orderListByLocationId:
    "https://9gvt8etmw4.execute-api.us-east-1.amazonaws.com/dev/v1/order/location",
  // "https://9cvdtd4105.execute-api.us-east-1.amazonaws.com/prod/v1/order/location",
  orderList:
    "https://7t7vd9lf5d.execute-api.us-east-1.amazonaws.com/dev/v1/search-order",
  // "https://9gy736jzx4.execute-api.us-east-1.amazonaws.com/prod/v1/search-order",
  priceOrderList:
    "https://9gvt8etmw4.execute-api.us-east-1.amazonaws.com/dev/v1/order-List",
  // "https://9cvdtd4105.execute-api.us-east-1.amazonaws.com/prod/v1/order-List",
  transactionApi:
    "https://i0a73s5uf3.sexecute-api.us-east-1.amazonaws.com/dev/v1/transaction",
  // "https://rcmzo85xjh.execute-api.us-east-1.amazonaws.com/prod/v1/transaction",
  transactionAdminApi:
    "https://i0a73s5uf3.execute-api.us-east-1.amazonaws.com/dev/v1/price-qty-transaction",
  // "https://rcmzo85xjh.execute-api.us-east-1.amazonaws.com/prod/v1/price-qty-transaction",
  transactionPartnerApi:
    "https://i0a73s5uf3.execute-api.us-east-1.amazonaws.com/dev/v1/partner-transaction",
  // "https://rcmzo85xjh.execute-api.us-east-1.amazonaws.com/prod/v1/partner-transaction",
  orderGetById:
    "https://9gvt8etmw4.execute-api.us-east-1.amazonaws.com/dev/v1/order/",
  // "https://9cvdtd4105.execute-api.us-east-1.amazonaws.com/prod/v1/order/",
  orderUpdateStatus:
    "https://9gvt8etmw4.execute-api.us-east-1.amazonaws.com/dev/v1/order/status/",
  // "https://9cvdtd4105.execute-api.us-east-1.amazonaws.com/prod/v1/order/status/",
  orderNote:
    "https://9gvt8etmw4.execute-api.us-east-1.amazonaws.com/dev/v1/order-note/",
  orderUpdateCustomerDetails:
    "https://9gvt8etmw4.execute-api.us-east-1.amazonaws.com/dev/v1/order/customerdetail/",
  // "https://9cvdtd4105.execute-api.us-east-1.amazonaws.com/prod/v1/order/customerdetail/",
  orderRefund:
    "https://9gvt8etmw4.execute-api.us-east-1.amazonaws.com/dev/v1/order/refund",
  // "https://9cvdtd4105.execute-api.us-east-1.amazonaws.com/prod/v1/order/refund",
  refundOrder:
    "https://9gvt8etmw4.execute-api.us-east-1.amazonaws.com/dev/v1/order/refund",
  // "https://9cvdtd4105.execute-api.us-east-1.amazonaws.com/prod/v1/order/refund",
  timezoneList:
    "https://bhagt8zwld.execute-api.us-east-1.amazonaws.com/dev/v1/timezone/all",
  driverList:
    "https://1ldhzr0mid.execute-api.us-east-1.amazonaws.com/dev/v1/user/getAll",
  assignDriver:
    "https://9gvt8etmw4.execute-api.us-east-1.amazonaws.com/dev/v1/order/driver",
  config:
    "https://bhagt8zwld.execute-api.us-east-1.amazonaws.com/dev/v1/configs",
  // "https://7plvpzb51g.execute-api.us-east-1.amazonaws.com/prod/v1/configs",
  getDriverById:
    "https://1ldhzr0mid.execute-api.us-east-1.amazonaws.com/dev/v1/user",
  productSearch:
    "https://7t7vd9lf5d.execute-api.us-east-1.amazonaws.com/dev/v1/search",
  myshopifyurl:
    "https://liquorsplit-development.myshopify.com/admin/api/2021-10/draft_orders.json",
  accesstoken: "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",
  draftOrders:
    "https://u4v3hfaq20.execute-api.us-east-1.amazonaws.com/dev/v1/quotations/get",
  rating_review:
    "https://9gvt8etmw4.execute-api.us-east-1.amazonaws.com/dev/order-review-rating",
  // "https://9gvt8etmw4.execute-api.us-east-1.amazonaws.com/dev/order-review-rating",
  // "https://9cvdtd4105.execute-api.us-east-1.amazonaws.com/prod/v1/order-review-rating",
  deliverySlotsUrl:
    "https://bhagt8zwld.execute-api.us-east-1.amazonaws.com/dev/get-admin-slots-by-location/",
  // "https://k3dde9ww19.execute-api.us-east-1.amazonaws.com/prod/v1/get-admin-slots-by-location/",
  addDeliverySlots:
    "https://bhagt8zwld.execute-api.us-east-1.amazonaws.com/dev/add-slots",
  // "https://k3dde9ww19.execute-api.us-east-1.amazonaws.com/prod/v1/add-slots",
  deleteDeliverySlots:
    "https://bhagt8zwld.execute-api.us-east-1.amazonaws.com/dev/v1/slots",
  // "https://k3dde9ww19.execute-api.us-east-1.amazonaws.com/prod/v1/slots",
  getAllDeliverySlotByDate:
    "https://bhagt8zwld.execute-api.us-east-1.amazonaws.com/dev/v1/get-delivery-slots-by-date",
  addHolidays:
    "https://bhagt8zwld.execute-api.us-east-1.amazonaws.com/dev/v1/add-holiday",
  getHolidays:
    "https://bhagt8zwld.execute-api.us-east-1.amazonaws.com/dev/v1/get-holidays",
  deleteHolidays:
    "https://bhagt8zwld.execute-api.us-east-1.amazonaws.com/dev/v1/delete-holiday",
  subscribeFcm:
    "https://bhagt8zwld.execute-api.us-east-1.amazonaws.com/dev/v1/subscribe-order-fcm",
  unsubscribeFcm:
    "https://bhagt8zwld.execute-api.us-east-1.amazonaws.com/dev/v1/unsubscribe-order-fcm",
  exportProducts:
    "https://i0a73s5uf3.execute-api.us-east-1.amazonaws.com/dev/v1/export-products",
  // "https://rcmzo85xjh.execute-api.us-east-1.amazonaws.com/prod/v1/export-products",
  exportPrice:
    "https://i0a73s5uf3.execute-api.us-east-1.amazonaws.com/dev/v1/exported-products",
  // "https://rcmzo85xjh.execute-api.us-east-1.amazonaws.com/prod/v1/exported-products",
  getAllProductsInventory:
    "https://i0a73s5uf3.execute-api.us-east-1.amazonaws.com/dev/v1/get-all-inventory",
  // "https://rcmzo85xjh.execute-api.us-east-1.amazonaws.com/prod/v1/get-all-inventory",
  importInventory:
    "https://i0a73s5uf3.execute-api.us-east-1.amazonaws.com/dev/v1/import-inventory",
  // "https://rcmzo85xjh.execute-api.us-east-1.amazonaws.com/prod/v1/import-inventory",
  importPrice:
    "https://i0a73s5uf3.execute-api.us-east-1.amazonaws.com/dev/v1/import-products-pricing",
  // "https://rcmzo85xjh.execute-api.us-east-1.amazonaws.com/prod/v1/import-products-pricing",
  listInventoryImport:
    "https://i0a73s5uf3.execute-api.us-east-1.amazonaws.com/dev/v1/list-inventory-import-request",
  // "https://rcmzo85xjh.execute-api.us-east-1.amazonaws.com/prod/v1/list-inventory-import-request",
  priceListInventoryImport:
    "https://i0a73s5uf3.execute-api.us-east-1.amazonaws.com/dev/v1/products-pricing-import-requests",
  // "https://rcmzo85xjh.execute-api.us-east-1.amazonaws.com/prod/v1/products-pricing-import-requests",

  pickUpLocationAdd:
    "https://bjwjn3w8gl.execute-api.us-east-1.amazonaws.com/dev/v1/location/",
  // "https://k3dde9ww19.execute-api.us-east-1.amazonaws.com/prod/v1/location/",
  jsonDataImport:
    "https://i0a73s5uf3.execute-api.us-east-1.amazonaws.com/dev/v1/get-import-data-json",
  // "https://rcmzo85xjh.execute-api.us-east-1.amazonaws.com/prod/v1/get-import-data-json",
  jsonPriceDataImport:
    "https://i0a73s5uf3.execute-api.us-east-1.amazonaws.com/dev/v1/get-import-price-data-json",
  // "https://rcmzo85xjh.execute-api.us-east-1.amazonaws.com/prod/v1/get-import-price-data-json",
  importApproval:
    "https://i0a73s5uf3.execute-api.us-east-1.amazonaws.com/dev/v1/import-inventory-approval",
  // "https://rcmzo85xjh.execute-api.us-east-1.amazonaws.com/prod/v1/import-inventory-approval",
  approvedImports:
    "https://i0a73s5uf3.execute-api.us-east-1.amazonaws.com/dev/v1/list-inventory-import-approved-request",
  // "https://rcmzo85xjh.execute-api.us-east-1.amazonaws.com/prod/v1/list-inventory-import-approved-request",
  approvePriceImports:
    "https://i0a73s5uf3.execute-api.us-east-1.amazonaws.com/dev/v1/import-pricing-approval",
  // "https://rcmzo85xjh.execute-api.us-east-1.amazonaws.com/prod/v1/import-pricing-approval",
  shopyfyUrlDispue:
    "https://liquorsplit.myshopify.com/admin/api/2022-10/shopify_payments/disputes.json",
  shopifyDisputes:
    "https://bhagt8zwld.execute-api.us-east-1.amazonaws.com/dev/v1/dispute",
  stripeDisputes:
    "https://bhagt8zwld.execute-api.us-east-1.amazonaws.com/dev/v1/stripe-dispute",
  permissionGetURL:
    "https://1ldhzr0mid.execute-api.us-east-1.amazonaws.com/dev/v1/permissions",
  // "https://hmvx936sw7.execute-api.us-east-1.amazonaws.com/prod/v1/permissions",
  roleGetURL:
    " https://1ldhzr0mid.execute-api.us-east-1.amazonaws.com/dev/v1/roles",
  // "https://hmvx936sw7.execute-api.us-east-1.amazonaws.com/prod/v1/roles",
  preSignedUrl:
    "https://i0a73s5uf3.execute-api.us-east-1.amazonaws.com/dev/v1/inventory/presignedurl",
  // "https://rcmzo85xjh.execute-api.us-east-1.amazonaws.com/prod/v1/inventory/presignedurl",
  collectionList:
    "https://hb4ildd64j.execute-api.us-east-1.amazonaws.com/dev/v2/get-collection",
  // "https://09ed4on6gd.execute-api.us-east-1.amazonaws.com/prod/v1/get-collection",
  pickedCollection:
    "https://hb4ildd64j.execute-api.us-east-1.amazonaws.com/dev/v2/picked-collection",
  // "https://09ed4on6gd.execute-api.us-east-1.amazonaws.com/prod/v1/picked-collection",
  pickCollection:
    "https://1tm9iv9ph2.execute-api.us-east-1.amazonaws.com/dev/v1/pick-collection",
  // "https://09ed4on6gd.execute-api.us-east-1.amazonaws.com/prod/v1/pick-collection",
  getCollectionByOrder:
    "https://hb4ildd64j.execute-api.us-east-1.amazonaws.com/dev/v2/get-collection-by-sort-order",
  // "https://09ed4on6gd.execute-api.us-east-1.amazonaws.com/prod/v1/get-collection-by-sort-order",
  updateCollectionByOrder:
    "https://1tm9iv9ph2.execute-api.us-east-1.amazonaws.com/dev/v1/update-collections-sort-order",
  // "https://09ed4on6gd.execute-api.us-east-1.amazonaws.com/prod/v1/update-collections-sort-order",
  createsubCategory:
    "https://1tm9iv9ph2.execute-api.us-east-1.amazonaws.com/dev/v1/create-sub-menu",
  // "https://09ed4on6gd.execute-api.us-east-1.amazonaws.com/prod/v1/create-sub-menu",
  subCategoryList:
    "https://hb4ildd64j.execute-api.us-east-1.amazonaws.com/dev/v2/get-sub-menus",
  // "https://09ed4on6gd.execute-api.us-east-1.amazonaws.com/prod/v1/get-sub-menus",
  editsubCategory:
    "https://1tm9iv9ph2.execute-api.us-east-1.amazonaws.com/dev/v1/edit-sub-menu",
  // "https://09ed4on6gd.execute-api.us-east-1.amazonaws.com/prod/v1/edit-sub-menu",
  viewsubCategoryDetail:
    "https://hb4ildd64j.execute-api.us-east-1.amazonaws.com/dev/v2/get-sub-menu",
  // "https://09ed4on6gd.execute-api.us-east-1.amazonaws.com/prod/v1/get-sub-menu",
  tenantApi:
    "https://1ldhzr0mid.execute-api.us-east-1.amazonaws.com/dev/v1/customers",
  // "https://qq1e8twnj9.execute-api.us-east-1.amazonaws.com/prod/v1/customers",
  viewtenantDetail:
    "https://1ldhzr0mid.execute-api.us-east-1.amazonaws.com/dev/v1/customers/details",
  // "https://qq1e8twnj9.execute-api.us-east-1.amazonaws.com/prod/v1/customers/details",
  discountsList:
    "https://i0a73s5uf3.execute-api.us-east-1.amazonaws.com/dev/v1/discounts",
  // "https://rcmzo85xjh.execute-api.us-east-1.amazonaws.com/prod/v1/discounts",
  userMetrics:
    "https://0sm542kky5.execute-api.us-east-1.amazonaws.com/v1/user-matric",
  // "https://qq1e8twnj9.execute-api.us-east-1.amazonaws.com/prod/v1/user-matric",
  locationsTitle:
    "https://bjwjn3w8gl.execute-api.us-east-1.amazonaws.com/dev/v1/locations-title",
  // "https://4duv8k145h.execute-api.us-east-1.amazonaws.com/prod/v1/locations-title",
  productCreate:
    "https://32qqspqu57.execute-api.us-east-1.amazonaws.com/dev/v1/create-product-sqs-handle",
  // "https://rpvyvytrok.execute-api.us-east-1.amazonaws.com/prod/v1/create-product-sqs-handle",
  productTransaction:
    "https://32qqspqu57.execute-api.us-east-1.amazonaws.com/dev/v1/product-transaction",
  // "https://rpvyvytrok.execute-api.us-east-1.amazonaws.com/prod/v1/product-transaction",
  productSearch:
    "https://32qqspqu57.execute-api.us-east-1.amazonaws.com/dev/v1/search-product",
  // "https://rpvyvytrok.execute-api.us-east-1.amazonaws.com/prod/v1/search-product",
  productDetail:
    "https://32qqspqu57.execute-api.us-east-1.amazonaws.com/dev/v1/master-product",
  // "https://rpvyvytrok.execute-api.us-east-1.amazonaws.com/prod/v1/master-product",
  productUpdate:
    "https://32qqspqu57.execute-api.us-east-1.amazonaws.com/dev/v1/update-product",
  // "https://rpvyvytrok.execute-api.us-east-1.amazonaws.com/prod/v1/update-product",
  getVendors:
    "https://i0a73s5uf3.execute-api.us-east-1.amazonaws.com/dev/v1/vendors",
  getVendorsByLocationId:
    "https://i0a73s5uf3.execute-api.us-east-1.amazonaws.com/dev/v1/vendors",
  createVendorsByLocationId:
    "https://i0a73s5uf3.execute-api.us-east-1.amazonaws.com/dev/v1/vendors",
  menuPresignedUrl:
    "https://i0a73s5uf3.execute-api.us-east-1.amazonaws.com/dev/v1/presigned-url-for-locationwise-product",
  productListLocationImport:
    "https://i0a73s5uf3.execute-api.us-east-1.amazonaws.com/dev/v1/list-location-product",
  jsonMenuDataImport:
    // "https://i0a73s5uf3.execute-api.us-east-1.amazonaws.com/dev/v1/import-location-product",
    "https://i0a73s5uf3.execute-api.us-east-1.amazonaws.com/dev/v1/location-product-import",
  aaprovedMenuImports:
    "https://i0a73s5uf3.execute-api.us-east-1.amazonaws.com/dev/v1/import-location-product-approval",
  createTransactionProduct:
    "https://i0a73s5uf3.execute-api.us-east-1.amazonaws.com/dev/v1/transaction-location-product",
  orderModify:
    "https://uukawl930j.execute-api.us-east-1.amazonaws.com/dev/v1/draft-order",
  // "https://9cvdtd4105.execute-api.us-east-1.amazonaws.com/prod/v1/draft-order",
  orderModification:
    "https://rdbuwq9pl9.execute-api.us-east-1.amazonaws.com/dev/v1/stripe-checkout/webhook",
  draftOrder:
    "https://uukawl930j.execute-api.us-east-1.amazonaws.com/dev/v1/draft-order/",
  // "https://9cvdtd4105.execute-api.us-east-1.amazonaws.com/prod/v1/draft-order/",
  modifyOrder:
    "https://uukawl930j.execute-api.us-east-1.amazonaws.com/dev/v1/order-modify",
  // "https://9cvdtd4105.execute-api.us-east-1.amazonaws.com/prod/v1/order-modify",
  searchProductsElastic:
    "https://7t7vd9lf5d.execute-api.us-east-1.amazonaws.com/dev/v1/search",
  getAllMenus:
    "https://1tm9iv9ph2.execute-api.us-east-1.amazonaws.com/dev/v1/get-menu",
  // "https://09ed4on6gd.execute-api.us-east-1.amazonaws.com/prod/v1/get-menu",
  getAllMenusSearch:
    "https://7t7vd9lf5d.execute-api.us-east-1.amazonaws.com/dev/v1/search",
  searchProducts:
    "https://9gvt8etmw4.execute-api.us-east-1.amazonaws.com/dev/v1/search-product",
  // "https://9cvdtd4105.execute-api.us-east-1.amazonaws.com/prod/v1/search-product",
  Suggestions:
    "https://i0a73s5uf3.execute-api.us-east-1.amazonaws.com/dev/v1/submit-product",
  blackListUserList:
    "https://0sm542kky5.execute-api.us-east-1.amazonaws.com/dev/v1/search-blacklist",
  // "https://qq1e8twnj9.execute-api.us-east-1.amazonaws.com/prod/v1/search-blacklist",
  createBlackListUser:
    "https://0sm542kky5.execute-api.us-east-1.amazonaws.com/dev/v1/blacklist",
  // "https://qq1e8twnj9.execute-api.us-east-1.amazonaws.com/prod/v1/blacklist",
  blackListUserDelete:
    "https://0sm542kky5.execute-api.us-east-1.amazonaws.com/dev/v1/activate-deactivate",
  // "https://qq1e8twnj9.execute-api.us-east-1.amazonaws.com/prod/v1/activate-deactivate",
  blackListUserDeatil:
    "https://0sm542kky5.execute-api.us-east-1.amazonaws.com/dev/v1/view-blacklist",
  // "https://qq1e8twnj9.execute-api.us-east-1.amazonaws.com/prod/v1/view-blacklist",
  blackListUserUpdate:
    "https://0sm542kky5.execute-api.us-east-1.amazonaws.com/dev/v1/update-blacklist",
  // "https://qq1e8twnj9.execute-api.us-east-1.amazonaws.com/prod/v1/update-blacklist",
  notificationList:
    "https://2ftpiulg9a.execute-api.us-east-1.amazonaws.com/v1/get-notification",
  // "https://m28q7scabh.execute-api.us-east-1.amazonaws.com/prod/v1/get-notification",
  createNotification:
    "https://2ftpiulg9a.execute-api.us-east-1.amazonaws.com/dev/v1/push-notification",
  // "https://m28q7scabh.execute-api.us-east-1.amazonaws.com/prod/v1/push-notification",
  consumersList:
    "https://0sm542kky5.execute-api.us-east-1.amazonaws.com/dev/v1/consumer",
  // "https://qq1e8twnj9.execute-api.us-east-1.amazonaws.com/prod/v1/consumer",
  deactivateNotification:
    "https://2ftpiulg9a.execute-api.us-east-1.amazonaws.com/dev/v1/deactivate-notification",
  // "https://m28q7scabh.execute-api.us-east-1.amazonaws.com/prod/v1/deactivate-notification",
  topicNotification:
    "https://2ftpiulg9a.execute-api.us-east-1.amazonaws.com/dev/v1/topic",
  // "https://m28q7scabh.execute-api.us-east-1.amazonaws.com/prod/v1/topic",
  activeUPC:
    "https://bjwjn3w8gl.execute-api.us-east-1.amazonaws.com/dev/v2/active-upc-location",
  // "https://4duv8k145h.execute-api.us-east-1.amazonaws.com/prod/v2/active-upc-location",
  activeUPCCategory:
    "https://bjwjn3w8gl.execute-api.us-east-1.amazonaws.com/dev/v2/active-upc-category",
  // "https://4duv8k145h.execute-api.us-east-1.amazonaws.com/prod/v2/active-upc-category",
  posInventoryList:
    "https://i0a73s5uf3.execute-api.us-east-1.amazonaws.com/dev/v1/pos-imported-request",
  // "https://rcmzo85xjh.execute-api.us-east-1.amazonaws.com/prod/v1/pos-imported-request",
  posPresignedUrl:
    "https://i0a73s5uf3.execute-api.us-east-1.amazonaws.com/dev/v1/presigned-url-file-upload",
  // "https://rcmzo85xjh.execute-api.us-east-1.amazonaws.com/prod/v1/presigned-url-file-upload",
  posImportFile:
    "https://i0a73s5uf3.execute-api.us-east-1.amazonaws.com/dev/v1/import-pos-file",
  // "https://rcmzo85xjh.execute-api.us-east-1.amazonaws.com/prod/v1/import-pos-file",
  posMapping:
    "https://i0a73s5uf3.execute-api.us-east-1.amazonaws.com/dev/v1/pos-mapping",
  // "https://rcmzo85xjh.execute-api.us-east-1.amazonaws.com/prod/v1/pos-mapping",
  posMappingSugg:
    "https://i0a73s5uf3.execute-api.us-east-1.amazonaws.com/dev/v1/pos-suggestion",
  // "https://rcmzo85xjh.execute-api.us-east-1.amazonaws.com/prod/v1/pos-suggestion",
  posImportedViewByStatus:
    "https://i0a73s5uf3.execute-api.us-east-1.amazonaws.com/dev/v1/pos-imported-by-status",
  // "https://rcmzo85xjh.execute-api.us-east-1.amazonaws.com/prod/v1/pos-imported-by-status",
  posInventoryDataByID:
    "https://i0a73s5uf3.execute-api.us-east-1.amazonaws.com/dev/v1/pos-imported-request",
  // "https://rcmzo85xjh.execute-api.us-east-1.amazonaws.com/prod/v1/pos-imported-request",
  posImportedDataByID:
    "https://i0a73s5uf3.execute-api.us-east-1.amazonaws.com/dev/v1/pos-imported-data",
  // "https://rcmzo85xjh.execute-api.us-east-1.amazonaws.com/prod/v1/pos-imported-data",
  posStatusCount:
    "https://i0a73s5uf3.execute-api.us-east-1.amazonaws.com/dev/v1/pos-validation-count",
  // "https://rcmzo85xjh.execute-api.us-east-1.amazonaws.com/prod/v1/pos-validation-count",
  posUploadedHeaders:
    "https://i0a73s5uf3.execute-api.us-east-1.amazonaws.com/dev/v1/uploaded-headers",
  // "https://rcmzo85xjh.execute-api.us-east-1.amazonaws.com/prod/v1/uploaded-headers",
  mappedPosDataImport:
    "https://i0a73s5uf3.execute-api.us-east-1.amazonaws.com/dev/v1/import-mapped-pos-data",
  // "https://rcmzo85xjh.execute-api.us-east-1.amazonaws.com/prod/v1/import-mapped-pos-data",
  rejectMappedPosDataImport:
    "https://i0a73s5uf3.execute-api.us-east-1.amazonaws.com/dev/v1/approve-pos-import",
  // "https://rcmzo85xjh.execute-api.us-east-1.amazonaws.com/prod/v1/approve-pos-import",
  mappingList:
    "https://i0a73s5uf3.execute-api.us-east-1.amazonaws.com/dev/v1/search-pos-mapping",
  // "https://rcmzo85xjh.execute-api.us-east-1.amazonaws.com/prod/v1/search-pos-mapping",
  editCashRemittedReconciliationReport:
    "https://hrjk9bspta.execute-api.us-east-1.amazonaws.com/dev/report/editReconCummulativeData",
  getCashRemittanceSummary:
    "https://hrjk9bspta.execute-api.us-east-1.amazonaws.com/dev/report/getCashRemittanceSummary",
  getSalesTrxSummaryReport:
    "https://hrjk9bspta.execute-api.us-east-1.amazonaws.com/dev/report/getSalesTrxSummaryReport",
  getCashRemittedReconciliationReport:
    "https://hrjk9bspta.execute-api.us-east-1.amazonaws.com/dev/report/getCashRemittedReconciliationReport",
  getCashRemittanceReportPDF:
    "https://hrjk9bspta.execute-api.us-east-1.amazonaws.com/dev/report/getCashRemittanceReportPDF",
  getPaBankReconData:
    "https://hrjk9bspta.execute-api.us-east-1.amazonaws.com/dev/report/getPaBankReconData",
  getSKUSalesTransactionData:
    "https://hrjk9bspta.execute-api.us-east-1.amazonaws.com/dev/report/getSKUSalesTransactionData",
  getSkuTransactionReportPdf:
    "https://hrjk9bspta.execute-api.us-east-1.amazonaws.com/dev/report/getSkuTransactionReportPdf",
  getPAWiseTransactions:
    "https://hrjk9bspta.execute-api.us-east-1.amazonaws.com/dev/report/getPAWiseTransactions",
  getErroneousPATransactions:
    "https://hrjk9bspta.execute-api.us-east-1.amazonaws.com/dev/report/getErroneousPATransactions",
  editPAWiseTransactions:
    "https://hrjk9bspta.execute-api.us-east-1.amazonaws.com/dev/report/editPAWiseTransactions",
  getManageSKUTransactions:
    "https://hrjk9bspta.execute-api.us-east-1.amazonaws.com/dev/report/getManageSKUTransactions",
  createSKUTransaction:
    "https://hrjk9bspta.execute-api.us-east-1.amazonaws.com/dev/report/createSKUTransaction",
  updateSKUTransaction:
    "https://hrjk9bspta.execute-api.us-east-1.amazonaws.com/dev/report/updateSKUTransaction",
  deleteSKUTransaction:
    "https://hrjk9bspta.execute-api.us-east-1.amazonaws.com/dev/report/deleteSKUTransaction",
  getErroneousPATransactions:
    "https://hrjk9bspta.execute-api.us-east-1.amazonaws.com/dev/report/getErroneousPATransactions",
  viewMasterDetail:
    "https://i0a73s5uf3.execute-api.us-east-1.amazonaws.com/dev/v1/view-master-product",
  // "https://rcmzo85xjh.execute-api.us-east-1.amazonaws.com/v1/view-master-product",
  masterProductsList:
    "https://i0a73s5uf3.execute-api.us-east-1.amazonaws.com/dev/v1/search-master-product",
  // "https://rcmzo85xjh.execute-api.us-east-1.amazonaws.com/prod/v1/search-master-product",
  masterProductsListDownloadList:
    "https://pos-inventory-file-upload-dev.s3.amazonaws.com/master-file/master_products.csv",
  // "https://pos-inventory-file-upload-prod.s3.amazonaws.com/master-file/master_products.csv",
  presignedUrlForMasterProductImage:
    "https://i0a73s5uf3.execute-api.us-east-1.amazonaws.com/dev/v1/presigned-url-master-image-upload",
  // "https://rcmzo85xjh.execute-api.us-east-1.amazonaws.com/prod/v1/presigned-url-master-image-upload",
  editMasterProductImage:
    "https://i0a73s5uf3.execute-api.us-east-1.amazonaws.com/dev/v1/edit-master-image",
  // "https://rcmzo85xjh.execute-api.us-east-1.amazonaws.com/prod/v1/edit-master-image",
  editMasterProductDetail:
    "https://i0a73s5uf3.execute-api.us-east-1.amazonaws.com/dev/v1/edit-master-product",
  // "https://rcmzo85xjh.execute-api.us-east-1.amazonaws.com/prod/v1/edit-master-product",
  posImportSugg:
    "https://i0a73s5uf3.execute-api.us-east-1.amazonaws.com/dev/v1/pos-import-suggestions",
  submitPosProduct:
    "https://i0a73s5uf3.execute-api.us-east-1.amazonaws.com/dev/v1/submit-pos-product",
  editTransactionNotes:
    "https://hrjk9bspta.execute-api.us-east-1.amazonaws.com/dev/report/editTransactionNotes",
  createMasterProduct:
    "https://i0a73s5uf3.execute-api.us-east-1.amazonaws.com/dev/v1/create-master-product",
  // "https://rcmzo85xjh.execute-api.us-east-1.amazonaws.com/prod/v1/create-master-product",
  flaggedSKUTransactions:
    "https://hrjk9bspta.execute-api.us-east-1.amazonaws.com/dev/report/flaggedSKUTransactions",
  flaggedSKUCashRemitTransactions:
    "https://hrjk9bspta.execute-api.us-east-1.amazonaws.com/dev/report/flaggedSKUCashRemitTransactions",
};

export let SourceName = {
  // {dev}
  LSWebsiteSourceName: "46035795969",
  //  LSAppSourceName: "41306324993",
  //  LSPos: "pos"

  // {prod}
  // LSWebsiteSourceName: "47480569857" || "web",
};

export let OrderStatus = [
  {
    id: "In-Progress",
    name: "In Progress",
  },
  {
    id: "Preparing",
    name: "Preparing",
  },
  {
    id: "Preparing-Order",
    name: "Preparing Order",
  },
  {
    id: "Ready-To-Delivery",
    name: "Ready To Delivery",
  },
  {
    id: "Out-For-Delivery",
    name: "Out For Delivery",
  },
  {
    id: "Delivered",
    name: "Delivered",
  },
  {
    id: "Cancelled",
    name: "Cancelled",
  },
  {
    id: "On-Hold",
    name: "On Hold",
  },
  {
    id: "Pending-Verification",
    name: "Pending Verification",
  },
  {
    id: "Delivery-Rejected",
    name: "Delivery Rejected",
  },
  {
    id: "Delivery-Accepted",
    name: "Delivery Accepted",
  },
];

export let imageUrl = {
  url: "https://master-product-image-dev.s3.amazonaws.com/master-image/",
  // url : "https://master-product-image-dev.s3.amazonaws.com/master-image/",
};

// module.exports = constantVariable;
