import React, { useEffect, useState } from "react";
import {
  Form,
  Card,
  CardBody,
  CardHeader,
  CardText,
  CardTitle,
  UncontrolledAlert,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
  Collapse,
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
} from "reactstrap";
import { Upload } from "react-bootstrap-icons";
import { Formik } from "formik";
import { useHistory } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useDropzone } from "react-dropzone";
import Draggable from "react-draggable";
import ImageUploader from "../../components/ImageUploader";
import { WithContext as ReactTags } from "react-tag-input";
import Dropzone from "react-dropzone";
import "./style.css";
import ReactDragListView from "react-drag-listview";
import axios from "axios";
import { logUserMetrics } from "../../../utils/file";
import { toast } from "react-toastify";
import { ApiUrls } from "../../../common/apiconstant";
import { ApiEndpoints } from "../../constants/constant";
import { useMediaQuery } from "@material-ui/core";

const fileData = [
  {
    url: "https://plus.unsplash.com/premium_photo-1665657351713-81c32fb5c148?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHwyfHx8ZW58MHx8fHx8&auto=format&fit=crop&w=500&q=60",
  },
  {
    url: "https://plus.unsplash.com/premium_photo-1665657351713-81c32fb5c148?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHwyfHx8ZW58MHx8fHx8&auto=format&fit=crop&w=500&q=60",
  },
  {
    url: "https://plus.unsplash.com/premium_photo-1665657351713-81c32fb5c148?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHwyfHx8ZW58MHx8fHx8&auto=format&fit=crop&w=500&q=60",
  },
  {
    url: "https://plus.unsplash.com/premium_photo-1665657351713-81c32fb5c148?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHwyfHx8ZW58MHx8fHx8&auto=format&fit=crop&w=500&q=60",
  },
  {
    url: "https://plus.unsplash.com/premium_photo-1665657351713-81c32fb5c148?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHwyfHx8ZW58MHx8fHx8&auto=format&fit=crop&w=500&q=60",
  },
  {
    url: "https://plus.unsplash.com/premium_photo-1665657351713-81c32fb5c148?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHwyfHx8ZW58MHx8fHx8&auto=format&fit=crop&w=500&q=60",
  },
  {
    url: "https://plus.unsplash.com/premium_photo-1665657351713-81c32fb5c148?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHwyfHx8ZW58MHx8fHx8&auto=format&fit=crop&w=500&q=60",
  },
  {
    url: "https://plus.unsplash.com/premium_photo-1665657351713-81c32fb5c148?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHwyfHx8ZW58MHx8fHx8&auto=format&fit=crop&w=500&q=60",
  },
  {
    url: "https://plus.unsplash.com/premium_photo-1665657351713-81c32fb5c148?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHwyfHx8ZW58MHx8fHx8&auto=format&fit=crop&w=500&q=60",
  },
];

const locationHeader = [
  {
    header: "Location Title",
  },
  {
    header: "Availability",
  },
];

const CreateProduct = () => {
  const initialMetafields = {
    package_type: {
      key: "package_type",
      type: "single_line_text_field",
      value: "",
    },
    category: {
      key: "category",
      type: "single_line_text_field",
      value: "",
    },
    brand: {
      key: "brand",
      type: "single_line_text_field",
      value: "",
    },
    alcoholic: {
      key: "alcoholic",
      type: "single_line_text_field",
      value: "",
    },
    alcohol_by_volume: {
      key: "alcohol_by_volume",
      type: "number_integer",
      value: "",
    },

    bin_location: {
      key: "bin_location",
      type: "single_line_text_field",
      value: "",
    },
    keywords: {
      key: "keywords",
      type: "multi_line_text_field",
      value: "",
    },
  };

  const history = useHistory();
  const [successMsg, setSuccessMsg] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [editorContent, setEditorContent] = useState("<strong></strong>");
  const [images, setImages] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [tags, setTags] = useState([]);
  const toggle = () => setIsOpen(!isOpen);
  const [inputs, setInputs] = useState([{ value: "" }]);
  const [isLoading, setIsLoading] = useState(false);
  const [priceValues, setPriceValues] = useState("");
  const [skuValues, setSkuValues] = useState("");
  const [barcodeValues, setBarcodeValues] = useState("");
  // const [skuValues, setSkuValues] = useState(Array(inputs.length).fill(''));
  // const [barcodeValues, setBarcodeValues] = useState(Array(inputs.length).fill(''));
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [optionName, setOptionName] = useState("");
  const [locations, setLocations] = useState([]);
  const [selectAll, setSelectAll] = useState(true);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [locationModal, setLocationModal] = useState(false);
  const [productTitle, setProductTitle] = useState("");
  const [productImages, setProductImages] = useState([]);
  const [variants, setVariants] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState("active");
  const [metafields, setMetafields] = useState(initialMetafields);
  const [variantTitles, setVariantTitles] = useState([]);
  const [productTitleError, setProductTitleError] = useState("");
  const [filteredInputs, setFilteredInputs] = useState([]);
  const [variantError, setVariantError] = useState("");
  const [variantDeleted, setVariantDeleted] = useState([]);
  const [deletedImages, setDeletedImages] = useState([]);

  const isMobile = useMediaQuery("(max-width: 500px)");

  const locModal = () => {
    setLocationModal(!locationModal);
  };

  const locationList = () => {
    fetch(`${ApiEndpoints.locationsTitle}`)
      .then((response) => response.json())
      .then((data) => {
        const locations = data?.data;
        console.log("locations", locations);
        setLocations(locations);
        let selectedLocation = [];
        for (let i in locations) {
          selectedLocation.push(locations[i].id);
        }

        setSelectedLocations(selectedLocation);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    locationList();
  }, []);

  // const createVariants = () => {
  //   const variantValues = inputs.map((input) => input.value);

  //   const hasEmptyInput = variantValues.some((value) => value.trim().length === 0);

  //   if (hasEmptyInput) {
  //     setVariantError("You can't submit the form with an empty variant title.");
  //     return false;
  //   }

  //   const newVariants = variantValues.map((value, index) => ({
  //     title: value,
  //     price: priceValues[index] || '',
  //     sku: skuValues[index] || '',
  //     barcode: barcodeValues[index] || '',
  //     position: index + 1,
  //     option1: value,
  //     option2: null,
  //     option3: null,
  //   }));

  //   setVariants(newVariants);
  //   setVariantError("");
  //   return true;
  // };

  // const skuErrors = new Array(inputs.length).fill(false);
  // const barcodeErrors = new Array(inputs.length).fill(false);

  const createVariants = () => {
    const variantValues = inputs.map((input) => input.value);

    const hasEmptyTitle = variantValues.some(
      (value) => value.trim().length === 0
    );

    if (hasEmptyTitle) {
      setVariantError("You can't submit the form with an empty variant title.");
      return false;
    }

    const newVariants = variantValues.map((value, index) => ({
      title: value,
      // price: priceValues[index] || '',
      sku: skuValues[index] || "",
      barcode: barcodeValues[index] || "",
      position: index + 1,
      option1: value,
      option2: null,
      option3: null,
    }));

    console.log("variansgysyuags", newVariants, variantValues);

    setVariants(newVariants);
    setVariantError("");
    return true;
  };

  console.log("626136", variants);

  const createOptions = () => {
    const optionValues = inputs.map((input) => input.value);

    const options = [
      {
        name: "Size",
        position: 1,
        values: optionValues,
      },
    ];

    return options;
  };

  const generateMetafields = () => {
    const productMetafields = [];
    for (const key in metafields) {
      const { key: fieldKey, value, type } = metafields[key];
      if (value) {
        let namespace = "custom";
        if (fieldKey === "bin_location") {
          namespace = "my_fields";
        }

        productMetafields.push({
          namespace,
          key: fieldKey,
          value,
          type,
        });
      }
    }
    return productMetafields;
  };

  const createProductTransaction = async (queryStatus) => {
    let productApi = ApiEndpoints.productCreate;

    if (locationModal == true) {
      productApi = productApi + `?duplicate=${queryStatus}`;
    }

    const variantsValid = createVariants();

    if (!variantsValid) {
      return;
    }

    if (productTitle.trim() === "") {
      setProductTitleError("Title is required.");
      return;
    }
    setProductTitleError("");

    setIsLoading(true);
    const options = createOptions();
    const metafields = generateMetafields();
    const tagsArray = tags.map((tag) => tag.text).join(",");

    let requestBody = {};
    requestBody.product = {};
    requestBody.product.title = productTitle;
    requestBody.product.body_html = editorContent;
    requestBody.images = productImages.map((image, index) => ({
      position: index + 1,
      attachment: image.base64,
      filename: image.filename,
    }));
    requestBody.locations = selectedLocations;
    requestBody.product.variants = variants;
    requestBody.product.options = options;
    requestBody.metafields = metafields;
    requestBody.product.status = selectedStatus;
    requestBody.product.tags = tagsArray;
    requestBody.updated_by = localStorage.getItem("fullname");

    console.log("requestBody", JSON.stringify(requestBody));

    try {
      setIsLoading(true);
      let res = await axios.post(productApi, requestBody);
      console.log("RESPONSE", res);
      if (res.status === 200) {
        toast.success("Product Create Request Submitted Successfully");
        history.push("/admin/products");
        setIsLoading(false);
        let action = "product-transaction-create";
        let type = "Info";
        let message = "Product Transaction successfully created.";
        const apiResponse = await logUserMetrics(action, type, message);
        console.log("apiirresponse", apiResponse);
      } else {
        console.log("resres", res);
      }
    } catch (error) {
      console.error("error", error.response);
      if (error.response.data.message == "Product already exist in master") {
        // setLocationModal(true);
        toast.error("Product Already Exist with this Name");
      } else {
        toast.error("Product Transaction is not submitted.");
      }
      setIsLoading(false);
      let action = "product-transaction-create";
      let type = "Error";
      let message = "Error in create product transaction.";
      const apiResponse = await logUserMetrics(action, type, message);
      console.log("apiirresponse", apiResponse);
    }
  };

  let initialValues = {
    productTitle: "",
    inputs: [{ value: "" }],
  };

  function generateRandomId(length) {
    let result = "";
    const characters = "0123456789";
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  // const randomId = generateRandomId(13);

  const handleDrop = (acceptedFiles) => {
    const newImages = acceptedFiles.map((file) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = function () {
        setProductImages((prevImages) => [
          ...prevImages,
          {
            file: file,
            preview: URL.createObjectURL(file),
            base64: reader.result.split(",")[1],
            filename: file.name,
            position: prevImages.length + 1,
            src: URL.createObjectURL(file),
          },
        ]);
      };
      reader.onerror = function (error) {
        console.log("Error: ", error);
      };

      return {
        file: file,
        preview: URL.createObjectURL(file),
      };
    });

    setImages((prevImages) => [
      ...prevImages,
      ...newImages.map((image, index) => ({
        ...image,
        position: prevImages.length + 1 + index,
        src: image.src,
      })),
    ]);
  };

  const renderImages = () => {
    return images.map((image, index) => (
      <div
        key={index}
        style={{
          width: "100%",
          height: "100%",
          position: "relative",
        }}
        draggable
        onDragStart={(e) => handleDragStart(e, index)}
        onDragOver={(e) => handleDragOver(e, index)}
        onDrop={(e) => handleDropImage(e, index)}
      >
        <img
          src={image.preview}
          alt={`Image ${index}`}
          style={{
            width: "100%",
            height: "100%",
            borderRadius: 10,
          }}
        />
        <button
          onClick={() => handleDeleteImage(index)}
          style={{
            position: "absolute",
            backgroundColor: "#8e8e93",
            color: "white",
            border: "none",
            borderRadius: "50%",
            width: "20px",
            height: "20px",
            cursor: "pointer",
            lineHeight: "10px",
            textAlign: "center",
          }}
        >
          x
        </button>
      </div>
    ));
  };

  const handleDragStart = (e, index) => {
    e.dataTransfer.setData("index", index);
  };

  const handleDragOver = (e, index) => {
    e.preventDefault();
    e.dataTransfer.dropEffect = "move";
  };

  const handleDropImage = (e, targetIndex) => {
    e.preventDefault();

    const sourceIndex = e.dataTransfer.getData("index");
    const updatedImages = [...productImages];
    const [movedImage] = updatedImages.splice(sourceIndex, 1);
    updatedImages.splice(targetIndex, 0, movedImage);

    const imagesWithUpdatedPositions = updatedImages.map((image, index) => ({
      ...image,
      position: index + 1,
    }));

    setProductImages(imagesWithUpdatedPositions);

    setImages(imagesWithUpdatedPositions);
  };

  const handleDeleteImage = (index) => {
    const updatedImages = productImages.filter((_, i) => i !== index);
    const imagesWithUpdatedPositions = updatedImages.map((image, i) => ({
      ...image,
      position: i + 1,
    }));

    setProductImages(imagesWithUpdatedPositions);

    setImages(imagesWithUpdatedPositions);
  };

  //   const files = acceptedFiles.map((file) => (
  //     <li key={file.path}>
  //       {file.path} - {file.size} bytes
  //     </li>
  //   ));
  //   console.log("FILES", acceptedFiles);
  //   const files = acceptedFiles.map((item, i) => (
  //     <div
  //       key={i}
  //       style={{
  //         width: "100%",
  //         height: "100%",
  //         gridRowStart: i == 1 && 1,
  //         gridRowEnd: i == 1 && 3,
  //         gridColumnStart: i == 1 && 1,
  //         gridColumnEnd: i == 1 && 3,
  //       }}
  //     >
  //       <img
  //         style={{ width: "100%", height: "100%" }}
  //         src={URL.createObjectURL(item)}
  //       />
  //     </div>
  //   ));

  const handleProductTitleChange = (e) => {
    setProductTitle(e.target.value);
    setProductTitleError("");
  };

  const dragProps = {
    onDragEnd(fromIndex, toIndex) {
      const data = [...inputs];
      const item = data.splice(fromIndex, 1)[0];
      data.splice(toIndex, 0, item);

      setInputs(data);

      const tempData = [...inputs];
      const tempItem = tempData.splice(fromIndex, 1)[0];
      tempData.splice(toIndex, 0, tempItem);

      let variantArr = [];

      const newVariants = tempData.map((value, index) => {
        console.log("drag", value);
        if (value?.title) {
          variantArr.push(value);
        } else {
          let newVariant = {
            title: value?.value,
            // price: priceValues[index] || '',
            sku: skuValues[index] || "",
            barcode: barcodeValues[index] || "",
            position: index + 1,
            option1: value?.value,
            option2: null,
            option3: null,
          };
          variantArr.push(newVariant);
        }
      });

      setVariants(variantArr);

      //   const updatedSkuValues = [...skuValues];
      // updatedSkuValues.splice(fromIndex, 1);
      // updatedSkuValues.splice(toIndex, 0, skuValues[fromIndex]);
      // setSkuValues(updatedSkuValues);

      // const updatedBarcodeValues = [...barcodeValues];
      // updatedBarcodeValues.splice(fromIndex, 1);
      // updatedBarcodeValues.splice(toIndex, 0, barcodeValues[fromIndex]);
      // setBarcodeValues(updatedBarcodeValues);

      console.log("tempData", tempData);
      // setDragState(!dragState)

      // setVariants(newVariants);
      console.log("jajgsduigsai", data, fromIndex, toIndex);
    },
    nodeSelector: "li",
    handleSelector: "div",
  };

  const onDelete = (i) => {
    const tags1 = tags.slice(0);
    tags1.splice(i, 1);
    setTags(tags1);
  };

  const onAddition = (tag) => {
    const tags1 = [].concat(tags, tag);
    console.log("hjagd", tags1, tag);
    setTags(tags1);
  };

  const addInput = () => {
    let newInput = { value: "" };
    setInputs([...inputs, newInput]);
  };

  const handleInput = () => {
    const lastInput = inputs[inputs.length - 1];

    if (lastInput && lastInput.value !== "") {
      let newInput = { value: "" };
      setInputs([...inputs, newInput]);
    }
  };

  // const handleInputChange = (index, value) => {
  //   const tempInputs = [...inputs];
  //   tempInputs[index].value = value;
  //   setInputs(tempInputs);

  //   const updatedVariantTitles = [...variantTitles];
  //   updatedVariantTitles[index] = value;
  //   setVariantTitles(updatedVariantTitles);
  // };

  const clearVariantError = () => {
    setVariantError("");
  };

  const handleInputChange = (index, value) => {
    const tempInputs = [...inputs];
    tempInputs[index].value = value;
    setInputs(tempInputs);

    // const tempVariants = [...inputs];
    // tempVariants[index].value = value;
    // setVariants(tempVariants);

    let variantArr = [];

    const newVariants = tempInputs.map((value, index) => {
      console.log("inputchange", value);
      if (value?.title) {
        variantArr.push(value);
      } else {
        let newVariant = {
          title: value?.value,
          // price: priceValues[index] || '',
          sku: skuValues[index] || "",
          barcode: barcodeValues[index] || "",
          position: index + 1,
          option1: value?.value,
          option2: null,
          option3: null,
        };
        variantArr.push(newVariant);
      }
    });
    setVariants(variantArr);
  };

  const handleKeyPress = (key, index) => {
    const tempInputs = [...inputs];
    if (
      tempInputs.length - 1 == index &&
      tempInputs[index].value != "" &&
      key == "Enter"
    ) {
      addInput();
    }
  };

  // let removedVariants = [];

  const handleRemove = (index) => {
    if (inputs.length > 1) {
      const tempInputs = [...inputs];
      tempInputs.splice(index, 1);
      // let removedVariant = tempInputs.splice(index, 1);
      // console.log("removedVariant",removedVariant);
      // removedVariants.push(removedVariant[0]);
      // setVariantDeleted(removedVariants)
      setInputs(tempInputs);
      const tempVariants = [...inputs];
      tempVariants.splice(index, 1);
      // setVariants(tempVariants);
      let variantArr = [];

      const newVariants = tempVariants.map((value, index) => {
        console.log("remove", value);
        if (value?.title) {
          variantArr.push(value);
        } else {
          let newVariant = {
            title: value?.value,
            // price: priceValues[index] || '',
            sku: skuValues[index] || "",
            barcode: barcodeValues[index] || "",
            position: index + 1,
            option1: value?.value,
            option2: null,
            option3: null,
          };
          variantArr.push(newVariant);
        }
      });

      setVariants(variantArr);
    }
  };

  // const handleRemove = (index) => {
  //   if(inputs.length > 1){
  //       const tempInputs = [...inputs];
  //       tempInputs.splice(index, 1);
  //       setInputs(tempInputs);
  //   }
  // };

  // const handleSkuChange = (e, index) => {
  //   const updatedSkuValues = [...skuValues];
  //   console.log("skuValues",skuValues);
  //   updatedSkuValues[index] = e.target.value;
  //   setSkuValues(updatedSkuValues);
  // };

  // const handleBarcodeChange = (e, index) => {
  //   const updatedBarcodeValues = [...barcodeValues];
  //   console.log("barcodeValues",barcodeValues);
  //   updatedBarcodeValues[index] = e.target.value;
  //   setBarcodeValues(updatedBarcodeValues);
  // };

  const handleSkuChange = (e, index) => {
    const updatedSkuValues = [...skuValues];
    updatedSkuValues[index] = e.target.value;
    console.log("Updated SKU Values: ", updatedSkuValues);
    setSkuValues(updatedSkuValues);

    const updatedVariants = [...variants];
    updatedVariants[index].sku = e.target.value;
    setVariants(updatedVariants);
  };

  const handleBarcodeChange = (e, index) => {
    const updatedBarcodeValues = [...barcodeValues];
    updatedBarcodeValues[index] = e.target.value;
    console.log("Updated Barcode Values: ", updatedBarcodeValues);
    setBarcodeValues(updatedBarcodeValues);

    const updatedVariants = [...variants];
    updatedVariants[index].barcode = e.target.value;
    setVariants(updatedVariants);
  };

  // const handleSkuChange = (e, index) => {
  //   skuErrors[index] = false;
  //   const updatedSkuValues = [...skuValues];
  //   updatedSkuValues[index] = e.target.value;
  //   setSkuValues(updatedSkuValues);
  // };

  // const handleBarcodeChange = (e, index) => {
  //   barcodeErrors[index] = false;
  //   const updatedBarcodeValues = [...barcodeValues];
  //   updatedBarcodeValues[index] = e.target.value;
  //   setBarcodeValues(updatedBarcodeValues);
  // };

  // const openModal = (option) => {
  //   setSelectedOption(option);
  //   if (option === "Edit SKU") {
  //     setSkuValues(skuValues);
  //   } else if (option === "Edit Barcode") {
  //     setBarcodeValues(barcodeValues);
  //   }

  //   setEditModalOpen(true);
  // };

  let tempSkuArr = [];
  let tempBarcodeArr = [];

  const openModal = (option) => {
    setSelectedOption(option);

    let tempData = [...inputs];

    console.log("filteredInputs", inputs, tempData);

    const filteredInputs = variants.filter((item) => item.title.trim() !== "");

    filteredInputs.map((item) => {
      tempSkuArr.push(item.sku);
      tempBarcodeArr.push(item.barcode);
    });

    console.log("123456789", filteredInputs, skuValues, barcodeValues);

    if (option === "Edit SKU") {
      setSkuValues(filteredInputs.map((item) => item.sku));
    } else if (option === "Edit Barcode") {
      setBarcodeValues(filteredInputs.map((item) => item.barcode));
    }

    // if (option === "Edit SKU") {
    //   setSkuValues(tempSkuArr);
    // } else if (option === "Edit Barcode") {
    //   setBarcodeValues(tempBarcodeArr);
    // }
    setFilteredInputs(filteredInputs);
    setEditModalOpen(true);
  };

  const closeModal = () => {
    setSelectedOption(null);
    setEditModalOpen(false);
  };

  const saveValues = () => {
    console.log("variantsssa", variants, inputs);

    const tempInputs = [...inputs];

    let variantArr = [];

    const newVariants = tempInputs.map((value, index) => {
      console.log("modalsave", value);
      if (value?.title) {
        variantArr.push(value);
      } else {
        let newVariant = {
          title: value?.value,
          // price: priceValues[index] || '',
          sku: skuValues[index] || "",
          barcode: barcodeValues[index] || "",
          position: index + 1,
          option1: value?.value,
          option2: null,
          option3: null,
        };
        variantArr.push(newVariant);
      }
    });
    setVariants(variantArr);
    closeModal();
  };

  const handleSelectAll = () => {
    setSelectAll(!selectAll);

    if (!selectAll) {
      setSelectedLocations([]);
      setSelectedLocations(locations.map((location) => location.id));
    } else {
      setSelectedLocations([]);
    }
  };

  const handleCheckboxChange = (event, locationId) => {
    const { checked } = event.target;

    if (checked) {
      setSelectedLocations((prevSelected) => [...prevSelected, locationId]);
    } else {
      setSelectedLocations((prevSelected) =>
        prevSelected.filter((id) => id !== locationId)
      );
    }

    const allLocationsChecked = selectedLocations.length == locations.length;

    setSelectAll(allLocationsChecked);
  };

  const handleMetafieldChange = (event) => {
    const { name, value } = event.target;
    setMetafields({
      ...metafields,
      [name]: { ...metafields[name], value },
    });
  };

  const handleStatusChange = (e) => {
    setSelectedStatus(e.target.value);
  };

  const onError = (error) => {
    console.log("Error While Uploading Image", error);

    if (
      error &&
      error[0]?.errors[0]?.message === "File is larger than 2048000 bytes"
    ) {
      toast.error(
        "File is larger than 2 MB, please choose image less than 2 MB"
      );
    }
  };

  return (
    <>
      <Container fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader>
                <Row className="align-items-center">
                  <Col lg="6" xs="7">
                    <span
                      className="back-btn"
                      style={{ cursor: "pointer" }}
                      onClick={() => history.goBack()}
                    >
                      <i class="fa fa-arrow-left " aria-hidden="true"></i>
                    </span>
                    <h6
                      className="fullcalendar-title h2 d-inline-block mb-0"
                      style={{ marginLeft: "10px" }}
                    >
                      {"Create product"}
                    </h6>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                {/* {successMsg != "" && (
                  <UncontrolledAlert color="success">
                    <span className="alert-text ml-1">{successMsg}</span>
                  </UncontrolledAlert>
                )}
                {errMsg != "" && (
                  <UncontrolledAlert color="danger">
                    <span className="alert-text ml-1">{errMsg}</span>
                  </UncontrolledAlert>
                )}
                {errorMsg != "" && (
                  <UncontrolledAlert color="danger">
                    <span className="alert-text ml-1">
                      {errorMsg.map((itemEr) => (
                        <p>{itemEr.error}</p>
                      ))}
                    </span>
                  </UncontrolledAlert>
                )}


                  <Formik
                  initialValues={initialValues}
                  validate={(values) => {
                    let value = values;
                    const errors = {};

                    var pattern = new RegExp(/^[0-9\b]+$/);

                    if (!values.productTitle.trim()) {
                      errors.productTitle = "Required!";
                    }

                 
                
                    console.log("ERR", errors);

                    return errors;
                  }}
                  onSubmit={(values, { setSubmitting }) => {
                    const variantsCreated = createVariants();
                    
                    if (variantsCreated) {
                      
                      createProductTransaction(values, setSubmitting);
                    } else {
                      
                      setSubmitting(false);
                    }
                  }}
                  


                  onSubmit={(
                    values, { setSubmitting }) => {
                      createProductTransaction(values, setSubmitting);
                      setSubmitting(false);
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    setFieldValue,

                    
                  }) => (

                  <Form onSubmit={handleSubmit}> */}
                <Row>
                  <Col lg="7">
                    <Card color="primary" outline>
                      <CardBody>
                        <CardText>
                          <FormGroup>
                            <Label check>Title</Label>
                            <Input
                              name="productTitle"
                              type="text"
                              value={productTitle}
                              onChange={handleProductTitleChange}
                            />
                            {productTitleError && (
                              <p style={{ color: "red", fontSize: 13 }}>
                                {productTitleError}
                              </p>
                            )}
                          </FormGroup>
                        </CardText>
                        <CardText>
                          <FormGroup>
                            <Label check>Description</Label>
                            <ReactQuill
                              value={editorContent}
                              onChange={setEditorContent}
                            />
                          </FormGroup>
                        </CardText>
                      </CardBody>
                    </Card>
                    <Card color="primary" outline>
                      <CardBody>
                        <CardText>
                          <FormGroup>
                            <Label check>Media</Label>
                            <div
                              style={{
                                width: "100%",
                                minHeight: 120,
                                height: "100%",
                                border: "0.0625rem dashed rgba(0, 0, 0, 0.2)",
                                padding: 10,
                                borderRadius: 20,
                                cursor: "pointer",
                              }}
                            >
                              <Dropzone
                                onDrop={handleDrop}
                                onDropRejected={(error) => onError(error)}
                                maxSize={2048000}
                                multiple
                              >
                                {({ getRootProps, getInputProps }) => (
                                  <div
                                    {...getRootProps()}
                                    className="dropzone"
                                    style={{
                                      width: "100%",
                                      alignItems: "center",
                                    }}
                                  >
                                    {/* <Button className="btn btn-primary btn-sm">
                                            Upload New <Upload />
                                          </Button> */}
                                    <input {...getInputProps()} />
                                    <p
                                      style={{
                                        textAlign: "center",
                                        margin: 25,
                                      }}
                                    >
                                      Insert Images
                                    </p>
                                    <p
                                      style={{
                                        marginTop: "-25px",
                                        fontSize: "10px",
                                      }}
                                    >
                                      Max. Image Size should be 2 MB
                                    </p>
                                  </div>
                                )}
                              </Dropzone>
                              <div
                                className="image-preview"
                                style={{
                                  display: "grid",
                                  gridTemplateColumns: "1fr 1fr 1fr 1fr",
                                  width: "100%",
                                  gap: 25,
                                }}
                              >
                                {renderImages()}
                              </div>
                            </div>
                          </FormGroup>
                        </CardText>
                      </CardBody>
                    </Card>

                    <Card color="primary" outline>
                      <CardHeader
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <h3 style={{ marginBottom: 0 }}>Availability</h3>
                      </CardHeader>
                      <CardBody>
                        <CardText>
                          <div style={{ height: 350, overflowY: "scroll" }}>
                            <Table>
                              <thead className="thead-light">
                                <tr>
                                  {locationHeader.map((item) => (
                                    <th>{item.header}</th>
                                  ))}
                                </tr>
                              </thead>
                              <tbody className="list">
                                <tr>
                                  <td>
                                    <FormGroup check>
                                      <Input
                                        name="selectAll"
                                        type="checkbox"
                                        checked={
                                          locations.length ==
                                          selectedLocations.length
                                        }
                                        onChange={handleSelectAll}
                                        style={{
                                          marginLeft: "70%",
                                          width: "100%",
                                        }}
                                      />
                                      <Label check>Select All</Label>
                                    </FormGroup>
                                  </td>
                                </tr>
                                <hr
                                  style={{
                                    marginRight: isMobile ? "-125px" : "-150px",
                                    marginTop: "auto",
                                    marginBottom: "auto",
                                  }}
                                />
                                {locations.map((location) => (
                                  <tr key={location.id}>
                                    <td>
                                      <FormGroup check>
                                        <Input
                                          name={`location-${location.id}`}
                                          type="checkbox"
                                          value={location.id}
                                          checked={selectedLocations.includes(
                                            location.id
                                          )}
                                          onChange={(event) =>
                                            handleCheckboxChange(
                                              event,
                                              location.id
                                            )
                                          }
                                          style={{
                                            marginLeft: "70%",
                                            width: "100%",
                                          }}
                                        />
                                        <Label check>{location.title}</Label>
                                      </FormGroup>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                          </div>
                        </CardText>
                        {/* <Button
                              onClick={locModal}
                              >
                              Pop 
                              </Button> 
                              <Modal isOpen={locationModal} toggle={locModal} centered>
                                <ModalHeader toggle={locModal}>Duplicate Products Found</ModalHeader>
                                <ModalBody>
                                    <div style={{marginBottom:"20px"}}>
                                <FormGroup check inline >
                                    <Input type="checkbox" />
                                    <Label check>
                                    Miramar
                                    </Label>
                                </FormGroup>
                                <FormGroup
                                    check
                                    inline
                                >
                                    <Input type="checkbox" />
                                    <Label check>
                                    Miramar
                                    </Label>
                                </FormGroup> 
                                </div>
                                <Button 
                                  size="sm"
                                  onClick={()=>createProductTransaction(false)}
                                  >
                                  Skip
                                </Button>
                                <Button 
                                  size="sm"
                                  onClick={()=>createProductTransaction(false)}
                                  >
                                  Override existing
                                </Button>
                                <Button 
                                  size="sm"
                                  onClick={()=>createProductTransaction(true)}
                                  >
                                  Create Duplicate
                                </Button>
                                </ModalBody>
                              </Modal>   */}
                      </CardBody>
                    </Card>

                    <Card color="primary" outline>
                      <CardBody>
                        <CardText>
                          <Row>
                            <Col>
                              <FormGroup>
                                <Label check>Variants</Label>
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <FormGroup>
                                {/* <Button
                                        onClick={toggle}
                                        color="link"
                                        >
                                        + Add option like size
                                        </Button>
                                        <Collapse isOpen={isOpen}> */}
                                <Card>
                                  <CardBody>
                                    <FormGroup>
                                      <Label for="exampleSelect">
                                        Variants Name
                                      </Label>
                                      <Input
                                        id="exampleSelect"
                                        name="select"
                                        type="select"
                                        style={{ width: "90%", height: 40 }}
                                        onChange={(e) =>
                                          setOptionName(e.target.value)
                                        }
                                      >
                                        <option>Size</option>
                                      </Input>
                                    </FormGroup>
                                    <FormGroup>
                                      <Label for="exampleSelect">
                                        Variants Values
                                      </Label>
                                      <ReactDragListView {...dragProps}>
                                        <ol style={{ paddingLeft: 0 }}>
                                          {inputs.map((item, index) => (
                                            <>
                                              <li
                                                key={index}
                                                style={{
                                                  padding: "10px 15px",
                                                  display: "flex",
                                                  margin: "5px 0",
                                                  background:
                                                    "rgba(246,249,252, 0.3)",
                                                  alignItems: "center",
                                                  cursor: "grab",
                                                  gap: "5%",
                                                }}
                                              >
                                                <span
                                                  style={{
                                                    display: "flex",
                                                    gap: 10,
                                                    alignItems: "center",
                                                  }}
                                                >
                                                  <div className="drag-icon">
                                                    <i className="fas fa-grip-vertical"></i>
                                                  </div>
                                                </span>
                                                <Input
                                                  id={`inputs[${index}].value`}
                                                  name={`inputs[${index}].value`}
                                                  type="text"
                                                  value={item.value}
                                                  onChange={(e) => {
                                                    handleInputChange(
                                                      index,
                                                      e.target.value
                                                    );
                                                    clearVariantError();
                                                  }}
                                                  onKeyPress={(e) =>
                                                    handleKeyPress(e.key, index)
                                                  }
                                                  style={{
                                                    width: "70%",
                                                    height: 35,
                                                  }}
                                                  autoFocus={
                                                    inputs.length - 1 === index
                                                  }
                                                />
                                                {inputs.length > 1 && (
                                                  <span
                                                    aria-hidden="true"
                                                    style={{
                                                      marginRight: "30px",
                                                      cursor: "pointer",
                                                    }}
                                                    onClick={() => {
                                                      handleRemove(index);
                                                      clearVariantError();
                                                    }}
                                                  >
                                                    <i class="fas fa-trash-alt"></i>
                                                  </span>
                                                )}
                                              </li>
                                            </>
                                          ))}
                                          {variantError && (
                                            <p
                                              style={{
                                                color: "red",
                                                fontSize: 13,
                                              }}
                                            >
                                              {variantError}
                                            </p>
                                          )}
                                          <Button
                                            className="btn btn-primary btn-sm"
                                            color="secondary"
                                            style={{
                                              marginRight: "30px",
                                              cursor: "pointer",
                                            }}
                                            onClick={() => {
                                              handleInput();
                                            }}
                                          >
                                            + Add Variant
                                          </Button>
                                        </ol>
                                      </ReactDragListView>
                                    </FormGroup>

                                    <FormGroup>
                                      {/* <hr style={{ marginLeft: -24, marginRight: -24, marginTop:0, marginBottom:10 }} /> */}
                                      <hr
                                        style={{ margin: "-10px -24px 10px" }}
                                      />
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                          alignItems: "center",
                                        }}
                                      >
                                        <Label check>
                                          Total {inputs.length}{" "}
                                          {inputs.length === 1
                                            ? "Variant"
                                            : "Variants"}
                                        </Label>
                                        <UncontrolledDropdown>
                                          <DropdownToggle caret>
                                            Edit
                                          </DropdownToggle>
                                          <DropdownMenu>
                                            <DropdownItem
                                              onClick={() =>
                                                openModal("Edit SKU")
                                              }
                                            >
                                              SKU
                                            </DropdownItem>
                                            <DropdownItem
                                              onClick={() =>
                                                openModal("Edit Barcode")
                                              }
                                            >
                                              Barcode
                                            </DropdownItem>
                                          </DropdownMenu>
                                        </UncontrolledDropdown>
                                      </div>

                                      <Modal
                                        isOpen={editModalOpen}
                                        toggle={closeModal}
                                        centered
                                      >
                                        <ModalHeader toggle={closeModal}>
                                          {selectedOption}
                                        </ModalHeader>
                                        <ModalBody>
                                          {selectedOption === "Edit SKU" &&
                                            filteredInputs.map(
                                              (input, index) => (
                                                <FormGroup
                                                  key={index}
                                                  style={{
                                                    display: "flex",
                                                    justifyContent:
                                                      "space-between",
                                                    alignItems: "center",
                                                  }}
                                                >
                                                  <Label>{input.title}</Label>
                                                  <Input
                                                    type="text"
                                                    id="edit_sku"
                                                    value={
                                                      input.sku ||
                                                      skuValues[index] ||
                                                      ""
                                                    }
                                                    onChange={(e) =>
                                                      handleSkuChange(e, index)
                                                    }
                                                    style={{ width: "55%" }}
                                                  />
                                                </FormGroup>
                                              )
                                            )}
                                          {selectedOption === "Edit Barcode" &&
                                            filteredInputs.map(
                                              (input, index) => (
                                                <FormGroup
                                                  key={index}
                                                  style={{
                                                    display: "flex",
                                                    justifyContent:
                                                      "space-between",
                                                    alignItems: "center",
                                                  }}
                                                >
                                                  <Label>{input.title}</Label>
                                                  <Input
                                                    type="text"
                                                    id="edit_barcode"
                                                    value={
                                                      input.barcode ||
                                                      barcodeValues[index] ||
                                                      ""
                                                    }
                                                    onChange={(e) =>
                                                      handleBarcodeChange(
                                                        e,
                                                        index
                                                      )
                                                    }
                                                    style={{ width: "55%" }}
                                                  />
                                                </FormGroup>
                                              )
                                            )}
                                        </ModalBody>
                                        <ModalFooter>
                                          <Button
                                            color="primary"
                                            onClick={saveValues}
                                          >
                                            Done
                                          </Button>{" "}
                                          <Button
                                            color="secondary"
                                            onClick={closeModal}
                                          >
                                            Cancel
                                          </Button>
                                        </ModalFooter>
                                      </Modal>
                                    </FormGroup>

                                    {/* <div>
                                                {inputs.map((value, index) => (
                                                    <FormGroup key={index}>
                                                    <hr style={{ marginLeft: -24, marginRight: -24 }} />
                                                    <div
                                                        style={{
                                                        display: 'flex',
                                                        justifyContent: 'space-between',
                                                        alignItems: 'center',
                                                        cursor: 'pointer',
                                                        }}
                                                        onClick={() => toggleModal(index)}
                                                    >
                                                        <Label check>{value.value}</Label>
                                                        <span>0 available at 23 locations</span>
                                                    </div>
                                                    </FormGroup>
                                                ))}
                                                
                                                <Modal isOpen={modalOpen} toggle={toggleModal} centered>
                                                    <ModalHeader toggle={toggleModal}>Edit</ModalHeader>
                                                    <ModalBody>
                                                      {inputs.map((index) =>(
                                                      <FormGroup key={index}>
                                                      <Label for="price">Price</Label>
                                                      <Input
                                                      type="text"
                                                      id="price"
                                                      value={priceValues[index] || ''} 
                                                      onChange={(e) => handleVariantPriceChange(e, index)}
                                                      />
                                                      </FormGroup>  
                                                      ))}
                                                    {inputs.map((index) =>(
                                                      <FormGroup key={index}>
                                                      <Label for="input1">SKU (Stock Keeping Unit)</Label>
                                                      <Input
                                                      type="text"
                                                      id="input1"
                                                      value={skuValues[index] || ''} 
                                                      onChange={(e) => handleVariantSkuChange(e, index)}
                                                      />
                                                      </FormGroup>  
                                                      ))}
                                                     {inputs.map((index) =>(  
                                                    <FormGroup key={index}>
                                                    <Label for="input2">Barcode (ISBN, UPC, GTIN, etc.)</Label>
                                                    <Input
                                                    type="text"
                                                    id="input2"
                                                    value={barcodeValues[index] || ''} 
                                                    onChange={(e) => handleVariantBarcodeChange(e, index)}
                                                    />
                                                    </FormGroup> 
                                                     ))}                                                  
                                                    </ModalBody>
                                                    <ModalFooter>
                                                    <Button
                                                      onClick={saveVariantValues}
                                                    >
                                                      Done
                                                    </Button>
                                                    <Button
                                                      onClick={closeModal}
                                                    >
                                                      Cancel
                                                    </Button>
                                                    </ModalFooter>
                                                </Modal>
                                                </div> */}
                                  </CardBody>
                                </Card>
                                {/* </Collapse> */}
                              </FormGroup>
                            </Col>
                          </Row>
                        </CardText>
                      </CardBody>
                    </Card>

                    <Card color="primary" outline>
                      <CardHeader
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <h3>Metafields</h3>
                      </CardHeader>
                      <CardBody>
                        <CardText>
                          <Row>
                            <Col lg="3">
                              <FormGroup>
                                <Label for="package_type">package_type</Label>
                              </FormGroup>
                            </Col>
                            <Col lg="9">
                              <FormGroup>
                                <Input
                                  style={{ height: 35 }}
                                  name="package_type"
                                  type="text"
                                  value={metafields?.package_type?.value}
                                  onChange={handleMetafieldChange}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <hr
                            style={{
                              marginLeft: -24,
                              marginRight: -24,
                              marginTop: -5,
                              marginBottom: 20,
                            }}
                          />
                          <Row>
                            <Col lg="3">
                              <FormGroup>
                                <Label for="category">category</Label>
                              </FormGroup>
                            </Col>
                            <Col lg="9">
                              <FormGroup>
                                <Input
                                  style={{ height: 35 }}
                                  name="category"
                                  type="text"
                                  value={metafields?.category?.value}
                                  onChange={handleMetafieldChange}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <hr
                            style={{
                              marginLeft: -24,
                              marginRight: -24,
                              marginTop: -5,
                              marginBottom: 20,
                            }}
                          />
                          <Row>
                            <Col lg="3">
                              <FormGroup>
                                <Label for="brand">brand</Label>
                              </FormGroup>
                            </Col>
                            <Col lg="9">
                              <FormGroup>
                                <Input
                                  style={{ height: 35 }}
                                  name="brand"
                                  type="text"
                                  value={metafields?.brand?.value}
                                  onChange={handleMetafieldChange}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <hr
                            style={{
                              marginLeft: -24,
                              marginRight: -24,
                              marginTop: -5,
                              marginBottom: 20,
                            }}
                          />
                          <Row>
                            <Col lg="3">
                              <FormGroup>
                                <Label for="alcoholic">alcoholic</Label>
                              </FormGroup>
                            </Col>
                            <Col lg="9">
                              <FormGroup>
                                <Input
                                  style={{ height: 35 }}
                                  name="alcoholic"
                                  type="text"
                                  value={metafields?.alcoholic?.value}
                                  onChange={handleMetafieldChange}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <hr
                            style={{
                              marginLeft: -24,
                              marginRight: -24,
                              marginTop: -5,
                              marginBottom: 20,
                            }}
                          />
                          <Row>
                            <Col lg="3">
                              <FormGroup>
                                <Label for="alcohol_by_volume">
                                  alcohol_by_volume
                                </Label>
                              </FormGroup>
                            </Col>
                            <Col lg="9">
                              <FormGroup>
                                <Input
                                  style={{ height: 35 }}
                                  name="alcohol_by_volume"
                                  type="number"
                                  value={metafields?.alcohol_by_volume?.value}
                                  onChange={handleMetafieldChange}
                                  min="0"
                                  max="100"
                                  onInput={(e) => {
                                    if (e.target.value > 100) {
                                      e.target.value = 100;
                                      alert("Value cannot be greater than 100");
                                    }

                                    if (e.target.value < 0) {
                                      e.target.value = 0;
                                      alert("Value cannot be less than 0");
                                    }

                                    if (e.target.value) {
                                      let inputVolume =
                                        e.target.value.toString();
                                      let alcholicVolume =
                                        parseInt(inputVolume);

                                      e.target.value = alcholicVolume;
                                    }
                                  }}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <hr
                            style={{
                              marginLeft: -24,
                              marginRight: -24,
                              marginTop: -5,
                              marginBottom: 20,
                            }}
                          />
                          <Row>
                            <Col lg="3">
                              <FormGroup>
                                <Label for="bin_location">bin</Label>
                              </FormGroup>
                            </Col>
                            <Col lg="9">
                              <FormGroup>
                                <Input
                                  style={{ height: 35 }}
                                  name="bin_location"
                                  type="text"
                                  value={metafields?.bin_location?.value}
                                  onChange={handleMetafieldChange}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <hr
                            style={{
                              marginLeft: -24,
                              marginRight: -24,
                              marginTop: -5,
                              marginBottom: 20,
                            }}
                          />
                          <Row>
                            <Col lg="3">
                              <FormGroup>
                                <Label for="keywords">keywords</Label>
                              </FormGroup>
                            </Col>
                            <Col lg="9">
                              <FormGroup>
                                <Input
                                  style={{ height: 60 }}
                                  name="keywords"
                                  type="textarea"
                                  value={metafields?.keywords?.value}
                                  onChange={handleMetafieldChange}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                        </CardText>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                    <Col lg="12">
                      <Card color="primary" outline>
                        <CardBody>
                          <CardText>
                            <FormGroup>
                              <Label check>Status</Label>
                              <Input
                                type="select"
                                value={selectedStatus}
                                onChange={handleStatusChange}
                              >
                                <option value="active">Active</option>
                                <option value="draft">Draft</option>
                                <option value="archived">Archived</option>
                              </Input>
                            </FormGroup>
                          </CardText>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col lg="12">
                      <Card color="default" outline>
                        <CardHeader
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <h3>Product organization</h3>
                        </CardHeader>
                        <CardBody>
                          <CardText>
                            <FormGroup>
                              <Label check>Tags</Label>
                              <ReactTags
                                placeholder=""
                                tags={tags}
                                suggestions={suggestions}
                                handleDelete={onDelete}
                                handleAddition={onAddition}
                                inputFieldPosition="top"
                                allowDragDrop={false}
                                allowDeleteFromEmptyInput={false}
                              />
                            </FormGroup>
                          </CardText>
                        </CardBody>
                      </Card>
                    </Col>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup>
                      <div>
                        {isLoading ? (
                          <div>Saving...</div>
                        ) : (
                          <Button
                            color="primary"
                            type="submit"
                            onClick={createProductTransaction}
                            disabled={isLoading}
                          >
                            Save
                          </Button>
                        )}
                      </div>
                      {/* <Button
                            color="primary"
                            type="submit"
                            // disabled={isSubmitting}
                            onClick={createProductTransaction}
                          >
                            Save
                          </Button> */}
                    </FormGroup>
                  </Col>
                </Row>
                {/* </Form>
                )}
                </Formik> */}
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default CreateProduct;
