import React from "react";
import ReactDOM from "react-dom";
import { GoogleOAuthProvider } from "@react-oauth/google";

import App from "./app";
import * as serviceWorker from "./serviceWorker";

// plugins styles from node_modules
import "react-notification-alert/dist/animate.css";
import "react-perfect-scrollbar/dist/css/styles.css";
// import "@fullcalendar/common/main.min.css";
import "@fullcalendar/daygrid/main.min.css";
// import "sweetalert2/dist/sweetalert2.min.css";
import "select2/dist/css/select2.min.css";
import "quill/dist/quill.core.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
// plugins styles downloaded
import "./assets/vendor/nucleo/css/nucleo.css";
// core styles
import "./assets/css/argon-dashboard-pro-react.css";
const { Amplify, Auth } = require("aws-amplify");
const awsconfig = require("../src/admin/config/awsAmplifyConfig");
Amplify.configure(awsconfig);
Auth.configure(awsconfig);

ReactDOM.render(
  <GoogleOAuthProvider clientId="480518930655-onids1sr6oir6op0e2dmi0hceprf9em8.apps.googleusercontent.com">
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </GoogleOAuthProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
